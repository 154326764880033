import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#fff',
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '6px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            backgroundColor: '#fff',
        },
    },
}))(InputBase);

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#f5f5f5',
        position: 'relative',
        display:'flex',
        flexWrap:'wrap',
        // marginTop:'24px',
        justifyContent:'space-between',
        "& .MuiAppBar-colorPrimary": {
            backgroundColor: "#f5f5f5",
            boxShadow: 'none',
            borderBottom: '1px solid #c2c2c2',
            marginBottom:24,
        },
        "& .MuiTab-root": {
            padding: '10px 24px 0px 24px'
        },
        "& .tools-tab-panel": {
            marginTop: '12px',
            borderRadius: '4px',
            "& .MuiBox-root": {
                padding:8,
                '@media(min-width:767px) and(max-width:802px)': {
                    paddingLeft: '40px',
                },
                "& .MuiTypography-root": {
                    "& .MuiBox-root": {
                        "& .MuiTypography-root": {
                            "& .MuiIconButton-root": {
                                padding: '0px',
                            },
                        }
                    }
                }
            },
            " & .MuiTypography-root":{
                display:'flex',
                flexWrap:'wrap',
                justifyContent:'space-between',
            }
        },
        "& .tabs-container": {
            marginTop: '8px',
        }
    },
    margin: {
        marginBottom: '12px',
    },
    tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: "#bd0912",
            border: '2px solid #bd0912'
        },
        "& .MuiButtonBase-root.MuiTab-root": {
            fontSize: 12,
            color: "#333333",
            "@media(max-width:932px)": {
                minWidth: '0px',
                padding: '8px 12px 0px 12px',
            },
            "& .MuiTab-wrapper": {
                flexDirection: 'row',
                justifyContent: 'center'
            }
        },
    },
    headerContainer:{
        display:'flex',
        justifyContent:'space-between',
    },
    custom: {
        color: "#000",
        fontWeight: "bold",
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    description: {
        fontSize: '12px',
        marginTop:'12px',
    },
    select:{
        minWidth:210,
    },
    menuItem:{
        fontSize:12,
    },
    container: {
        backgroundColor: '#fff',
        boxShadow: '1px 2px 5px 1px #ccc',
        margin: '0px 0px 16px 0px',
        borderRadius: '4px',
        display: 'flex',
        padding: 16,
        width:'48%',
        '@media(min-width:767px) and (max-width:1023px)':{
            width:'100%',
        },
    },
    readMore:{
        fontSize: '12px',
        paddingBottom: '6px',
        color:'#1874CD',
        cursor:'pointer',
        textDecoration:"underline",
        display:'inline-block',
        justifyContent:'flex-end'
    },
    descriptionBlock:{
         width:'78%',
         fontSize:12,
        '@media(min-width:766px) and (max-width:958px)':{
            width:'85%',
        },
    },
    toolsIconBlock:{
        width:'22%',
        paddingRight:16,
        margin:'auto',
        textAlign:'center',
        '@media(min-width:1023px) and (max-width:1049px)':{
            width:'25%',
        },
        " & >img":{
            width:56,
            height:56,
        }
    },
    redirectionLabel:{
        fontSize:10,
        color:'#bd0912',
        cursor:'pointer',
        fontWeight:500,
    },
    redirectAccordion:{
        transform:'rotate(270deg)',
        color:'#bd0912',
        position:'relative',
        bottom:3,
        width:20,
        height:20,
        cursor:'pointer',
    },
    readOrHide: {
        color: '#1874CD',
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    contentGrid: {
        '& .MuiTable-root': {
            borderRadius: '4px'
        }
    },
}));