import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  HvCard,
  HvCardContent,
  HvCardHeader,
  HvActionBar,
  HvGrid,
  HvTypography,
  actionBarClasses,
  theme,
  HvBox,
} from "@hitachivantara/uikit-react-core";
import { css } from "@emotion/css";
import styled from "@emotion/styled";

const TotalLabsCard = memo((props) => {
  const GradientCard = styled(HvCard)(({ theme }) => ({
    background: "linear-gradient(135deg, #f00, #ff0080)", // Red to pink gradient
    backgroundImage: 'url("/assets/images/labs_cleanup.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: theme.spacing(2),
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "400px",
    width: "350px",
    position: "relative",
    "& .HvCard-semanticBar": {
      display: "none",
    },
  }));

  const StatsBox = styled(HvBox)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
  });

  const StatsBox1 = styled(HvBox)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "end",
    paddingLeft: "80px",
    paddingBottom: "70px",
  });

  return (
    <GradientCard>
      {/* Upper Part */}
      <StatsBox>
        <HvTypography variant="display" style={{ color: "white" }}>
          {props?.title}
        </HvTypography>
        {/* <HvTypography variant="display" style={{ color: "white" }}>
          {props?.totalLabsRunCurrYear}
        </HvTypography> */}
      </StatsBox>

      {/* Lower Part */}
      <StatsBox1>
        {/* <HvTypography variant="title3" style={{ color: "white" }}>
          + {yearOnYearGrowth}% Last Year
        </HvTypography> */}
        <HvTypography
          variant="display"
          style={{
            color: "white",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "80%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "4rem",
          }}
        >
          {props?.totalUsage}
        </HvTypography>
      </StatsBox1>
    </GradientCard>
  );
});

TotalLabsCard.propTypes = {};

export default TotalLabsCard;
