//Report 1 - Table Data
export const Method_Get_Session_Reports_List = "lab-type-chart/";
//"lab-report/"

//Report 1 - Bar chart (new API)
export const Method_Get_LabUsage_UserType_BarChart =
  "lab-type-chart/chart-lab-report/";
//"lab-report/user-type-lab-report/"

//Report 1 - donut chart (new API)
export const Method_Get_LabUsage_Sessions_DougnetChart =
  "lab-type-chart/chart-user-report/";
//"lab-report/user-type-chart/"

//Report 2 - Top 10 Labs by Instance Count
export const Method_Top10_Instances = "lab-type-chart/chart-lab-report/";
//"lab-type-chart/top-10-session-count/";

//Report 2 - Top 10 Labs by Run Time
export const Method_Top10_Run_Time = "/report-by-top-ten/";
//"lab-type-chart/top-10-run-time/";

//Report 2 - Top 10 Table
export const Method_Top10_Table = "report-by-top-ten-table/";

//exporting to excel - A complete export for Report 1
export const Method_Download_Reports_Data =
  "lab-type-chart/export-combined-report-excel/";
//"user-type-chart/export-excel/";

//exporting to pdf - A coplete export for Report 1
export const Method_Download_Reports_Data_PDF =
  "lab-type-chart/export-combined-report-pdf/";
//"user-type-chart/export-pdf/";

//exporting to excel - Top 10 Report (Top 10 Labs by Session Count)
export const Method_Download_Top10Session_Reports_Data =
  "/report-by-top-ten-download/";

//exporting to PDF - Top 10 Report (Top 10 Labs by Session Count)
export const Method_Download_Top10Session_Reports_Data_PDF =
  "lab-type-chart/export-data/";
//"lab-type-chart/export-top-session-pdf/";

//exporting to excel - Top 10 Report (Top 10 Labs by Run Time)
export const Method_Download_Top10RunTime_Reports_Data =
  "lab-type-chart/export-data/";
//"lab-type-chart/export-top-run-excel/";

//exporting to PDF - Top 10 Report (Top 10 Labs by Run Time)
export const Method_Download_Top10RunTime_Reports_Data_PDF =
  "lab-type-chart/export-data/";
//"lab-type-chart/export-top-run-pdf/";

//Report 3 - by country bar chart
export const Method_Geography = "report-by-country/";

//Report 3 - by country donut chart
export const Method_Geography_Donut = "lab-type-chart/chart-user-report/";

//Report 3 - by country table
export const Method_Geography_Table = "lab-type-chart/lab-detail/";

//Report 3 - export to excel/pdf
export const Method_Geography_Export = "lab-type-chart/export-data/";

// get all user country
export const Method_Get_All_Country = "/user/all-country/"

// get all country table data
export const Method_Get_Table_Country = "/report-by-country-table/"

// export excel and pdf
export const Method_Get_Geography_export = "/report-by-country-download/"

export const Method_Get_All_Region = "user/all-region/"

export const Method_UserRegion = "report-by-region/"

export const Method_Get_UserRegion_export = "report-by-region-download/"

export const Method_Get_Table_UserRegion = "report-by-region-table/"

