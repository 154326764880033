import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import DataGridSubTable from "./DataGridSubTable";
import { useStyles } from "utils/theme";
const DataGrid = (props) => {
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setSelectedRows([]);
  }, [props.refreshToken])

  /*
    const rowStyle = (rowData) => ({
      backgroundColor: props.enableSubTable
        ? selectedRows.find(
          r =>
            r[props.uniqueRowColumn] ===
            rowData[props.uniqueRowColumn]
        )?.expanded
          ? "#E4F9FA"
          : "#F4F3F3"
        : "#F4F3F3",
    })
    */

  return (
    <div className={classes.masterGrid}>
      <MaterialTable
        key={props.refreshToken || 0}
        actions={props.actions || null}
        components={{
          OverlayLoading: props => (<div></div>)
        }}
        className={classes}
        title={props.tableTitle || ''}
        options={{
          actionsColumnIndex: -1,
          debounceInterval: props.debounceInterval || 1000,
          toolbar: props.showToolBar || false,
          pageSize: props.pageSize || 20,
          pageSizeOptions: props.sizeOptions || [10, 20, 50, 100],
          emptyRowsWhenPaging: false,
          filtering: props.filtering || false,
          paging: (props.paging === false) ? false : true,
          //rowStyle: props.rowStyle || rowStyle,
          maxBodyHeight: props.maxBodyHeight || null,
          headerStyle: props.headerStyle || null,
          selection: props.selection || false,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          selectionProps: (props.selection) ? rowData => ({
            color: 'primary',
          }) : null,
          rowStyle: (rowData) => {
            return {
              background: rowData.is_read ? "#eee" : 'none'
            }
          }
        }
        }
        onSelectionChange={(props.selection) ? props.selectionHandler : null}
        columns={props.columns}
        onRowClick={
          (props.enableSubTable || props.customDetailPanel)
            ? (event, rowData, togglePanel) => {
              const found = selectedRows.find(
                (r) =>
                  r[props.uniqueRowColumn] ===
                  rowData[props.uniqueRowColumn]
              );
              if (found && found[props.uniqueRowColumn] > 0) {
                //means already clicked once
                found.expanded = !found.expanded;
                const updateState = [...selectedRows];
                setSelectedRows(updateState);
              } else {
                const newRecord = {
                  [props.uniqueRowColumn]:
                    rowData[props.uniqueRowColumn],
                  expanded: true,
                };
                const updateState = [...selectedRows, newRecord];
                setSelectedRows(updateState);
              }
              togglePanel();
            }
            : null
        }
        detailPanel={
          props.enableSubTable
            ? [row => (
              {
                disabled: true,
                render:
                  <DataGridSubTable
                    columns={props.subTableColumns}
                    data={row[props.subTableDataCol]}
                  />
              })
            ]
            : props.customDetailPanel ? (rowdata) => {
              const Component = props.customDetailPanel;
              return (<Component refreshHandler={props.refreshHandler} data={rowdata} />)
            } : null
        }
        data={props.dataHandler || props.data}
      />
    </div>
  );
}
export default DataGrid;
