import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/actions/loginActions';

export default function SignOff() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
    })
    return true;
}