import React, { useEffect, useState, useMemo, Fragment } from "react";
import {
  HvGrid,
  HvInput,
  HvTabs,
  HvTab,
  HvDropdown,
  HvTableContainer,
  HvTable,
  HvTableHead,
  HvTableRow,
  HvTableHeader,
  HvTableBody,
  HvTableCell,
  HvPagination,
  HvTooltip,
  useHvPagination,
  useHvData,
  HvEmptyState,
  hvTextColumn,
  HvButton,
  HvBox,
  HvTypography,
  useHvRowExpand,
  theme,
} from "@hitachivantara/uikit-react-core";
import { Ban } from "@hitachivantara/uikit-react-icons";
import Footer from "components/footer/Footer";
import { buildQueryParams } from "utils/common";
import LabService from "services/LabService";
import { useStyles } from "../wizard/style";
import _ from "lodash";
import { toast } from "react-toastify";
import CleanupSubTable from "./CleanupSubTable";
import { useHistory } from "react-router";
const CleanupSteps = (props) => {
  const history = useHistory();
  const redirect = (url) => {
    history.push(url);
  };
  const classes = useStyles();
  const { currentStep } = props;
  const [labId] = useState(props?.labData?.id);
  const [columns, setColumns] = useState([]);
  const [subColumns, setSubColumns] = useState([]);
  const [totalrecords, setTotalRecords] = useState();
  const [disabled] = useState(false);
  const [testId, setTestId] = useState("");
  const [email, setEmail] = useState("");
  const [testName, setTestName] = useState("");
  const [data, setData] = useState();
  const [subdata, setSubData] = useState();

  const i18n = useMemo(
    () => ({
      expandRowButtonAriaLabel: "Click to expand this row",
      collapseRowButtonAriaLabel: "Click to collapse this row",
    }),
    []
  );

  const labServiceObj = useMemo(() => new LabService(), []);
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    rows,
    state: { pageSize, pageIndex },
    getHvPaginationProps,
  } = useHvData(
    {
      columns,
      data,
      labels: i18n,
      manualPagination: true,
      pageCount: totalrecords,
    },
    useHvRowExpand,
    useHvPagination
  );
  useEffect(() => {
    const labParams = {
      lab: props?.labId,
    };
    labServiceObj
      .getLabCleanUpSetup(buildQueryParams(labParams))
      .then((response) => {
        if (response) {
          setTestId(response?.data?.results[0]?.test_id);
          setEmail(response?.data?.results[0]?.email);
          setTestName(response?.data?.results[0]?.test_name);
        }
      });
  }, []);

  useEffect(() => {
    try {
      const labParams = {
        lab: props?.labId,
      };

      labParams["limit"] = pageSize;
      labParams["offset"] = pageSize * (pageIndex + 1 - 1);

      labServiceObj
        .getInvokeLabCleanUp(buildQueryParams(labParams))
        .then((response) => {
          if (response) {
            setData(response?.data?.results);
            setSubData(response?.data?.results?.test_result_response);
            setTotalRecords(Math.ceil(response?.data?.count / pageSize));
          }
        });
    } catch (error) {
      console.log(error);
    }
    setColumns(
      [
        [
          hvTextColumn({
            Header: "Clean Up Start Date",
            Cell: (cellData) => {
              if (!_.isEmpty(cellData)) {
                const date = new Date(cellData.row.original.created_date);
                return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
              }
            },
            accessor: "created_date",
            cellType: "alpha-numeric",
          }),
        ],
      ][0]
    );
    setSubColumns(
      [
        [
          hvTextColumn({
            Header: "Suite",
            accessor: "Test Suite",
            cellType: "alpha-numeric",
          }),
          hvTextColumn({
            Header: "Group",
            accessor: "Test Case",
            cellType: "alpha-numeric",
          }),
          hvTextColumn({
            Header: "Task",
            accessor: "Test",
            cellType: "alpha-numeric",
          }),
          hvTextColumn({
            Header: "Start Time",
            Cell: (cellData) => {
              if (!_.isEmpty(cellData)) {
                return new Date(
                  cellData.row.original["Start Time"]
                ).toUTCString();
              }
            },
            accessor: "Start Time",
            cellType: "alpha-numeric",
          }),
          hvTextColumn({
            Header: "End Time",
            Cell: (cellData) => {
              if (!_.isEmpty(cellData)) {
                return new Date(
                  cellData.row.original["Start Time"]
                ).toUTCString();
              }
            },
            accessor: "End Time",
            cellType: "alpha-numeric",
          }),
          hvTextColumn({
            Header: "Status",
            Cell: (cellData) => {
              if (!_.isEmpty(cellData)) {
                const statusColor =
                  cellData.row.original.Status === "PASS" ? "green" : "red";
                return (
                  <span style={{ color: statusColor }}>
                    {cellData.row.original.Status}
                  </span>
                );
              }
            },
            accessor: "Status",
            cellType: "alpha-numeric",
          }),
        ],
      ][0]
    );
  }, [pageSize, pageIndex]);

  const subTablecolumns = [
    { title: "Suite", field: "Test Suite" },
    { title: "Group", field: "Test Case" },
    { title: "Task", field: "Test" },
    {
      title: "Start Time ",
      field: "Start Time",
      render: (rowData) => new Date(rowData["Start Time"]).toUTCString(),
    },
    {
      title: "End Time",
      field: "End Time",
      render: (rowData) => new Date(rowData["End Time"]).toUTCString(),
    },
    {
      title: "Status",
      field: "Status",
      render: (rowData) => {
        const statusColor = rowData.Status === "PASS" ? "green" : "red";
        return <span style={{ color: statusColor }}>{rowData.Status}</span>;
      },
    },
  ];

  const EmptyRow = () => (
    <HvTableRow>
      <HvTableCell colSpan={100} style={{ height: 50 }}>
        <HvEmptyState
          message="No data to display"
          icon={<Ban role="presentation" />}
        />
      </HvTableCell>
    </HvTableRow>
  );
  const validatePayload = () => {
    if (_.isEmpty(testId)) {
      toast.error("Please enter TestID");
      return false;
    } else {
      if (_.isEmpty(email) || emailRegex.test(email) === false) {
        toast.error("Please enter valid Email ID.");
        return false;
      } else {
        if (_.isEmpty(testName)) {
          toast.error("Please enter valid Test Name.");
          return false;
        }
      }
      return true;
    }
  };
  const invokeLabCleanupSetup = () => {
    const validInput = validatePayload();
    if (validInput) {
      const payload = {
        lab: props?.labId,
        test_id: testId,
        email: email,
        test_name: testName,
      };
      labServiceObj.createLabCleanUpSetup(payload).then((response) => {
        if (response) {
          toast.success("Test Saved Succesfully.");
        }
      });
    }
  };

  const handletagSave = () => {
    props.setNextStep(currentStep + 1);
    redirect("/admin/masters/labs");
  };

  return (
    <div>
      <form
        autoComplete="on"
        onSubmit={(event) => {
          event.preventDefault();
          handletagSave();
        }}
      >
        <HvGrid container spacing={3} style={{ paddingLeft: "20px" }}>
          <HvGrid item xs={2}>
            <HvInput
              fullWidth
              name="TestID"
              label="CleanUp ID"
              variant="outlined"
              disabled={disabled}
              classes={{ root: classes.textField }}
              InputProps={{
                classes: {
                  input: classes.inputField,
                  root: classes.base,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.inputLabel,
              }}
              onChange={(e) => {
                setTestId(e.target.value);
              }}
              value={testId}
            />
          </HvGrid>
          <HvGrid item xs={3}>
            <HvInput
              fullWidth
              name="Email"
              label="Email"
              variant="outlined"
              disabled={disabled}
              classes={{ root: classes.textField }}
              InputProps={{
                classes: {
                  input: classes.inputField,
                  root: classes.base,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.inputLabel,
              }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
          </HvGrid>
          <HvGrid item xs={3}>
            <HvInput
              fullWidth
              name="TestName"
              label="CleanUp Name"
              variant="outlined"
              disabled={disabled}
              classes={{ root: classes.textField }}
              InputProps={{
                classes: {
                  input: classes.inputField,
                  root: classes.base,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.inputLabel,
              }}
              onChange={(e) => {
                setTestName(e.target.value);
              }}
              value={testName}
            />
          </HvGrid>
          <HvGrid item xs={2} style={{ marginTop: "42px" }}>
            <HvButton
              variant="primary"
              component="label"
              onClick={(e) => invokeLabCleanupSetup(e)}
            >
              Save{" "}
            </HvButton>
          </HvGrid>
        </HvGrid>
        <div style={{ maxWidth: "100%", padding: "20px" }}>
          <HvGrid container item xs={12}>
            <HvGrid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <HvTableContainer>
                <HvTable {...getTableProps()}>
                  <HvTableHead>
                    {headerGroups.map((headerGroup) => (
                      <HvTableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((col) => (
                          <HvTableHeader {...col.getHeaderProps()}>
                            {col.render("Header")}
                          </HvTableHeader>
                        ))}
                      </HvTableRow>
                    ))}
                  </HvTableHead>
                  <HvTableBody {...getTableBodyProps()}>
                    {rows.length === 0 ? (
                      <EmptyRow />
                    ) : (
                      rows.map((row) => {
                        prepareRow(row);

                        return (
                          <Fragment key={row.id}>
                            <HvTableRow {...row.getRowProps()}>
                              {row.cells.map((cell) => (
                                <HvTableCell {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </HvTableCell>
                              ))}
                            </HvTableRow>
                            <HvTableRow
                              style={{
                                display: row.isExpanded ? undefined : "none",
                                background:
                                  theme.table.rowExpandBackgroundColor,
                              }}
                            >
                              <HvTableCell
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                  textAlign: "center",
                                  height: 100,
                                }}
                                colSpan={100}
                              >
                                <CleanupSubTable
                                  allData={data.filter(
                                    (eachRow) =>
                                      eachRow.id === row?.original?.id
                                  )}
                                  rowId={row?.original?.id}
                                ></CleanupSubTable>
                              </HvTableCell>
                            </HvTableRow>
                          </Fragment>
                        );
                      })
                    )}
                  </HvTableBody>
                </HvTable>
              </HvTableContainer>
              <HvPagination {...getHvPaginationProps()} />
            </HvGrid>
          </HvGrid>
        </div>

        <Footer
          lastStep={true}
          handleSumbit={(event) => {
            event?.preventDefault();
          }}
        />
      </form>
    </div>
  );
};

CleanupSteps.propTypes = {};

export default CleanupSteps;
