import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Button, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import SupportService from "services/admin/masters/SupportService";
import moment from 'moment';

const supportServiceObj = new SupportService();

const Form = ({ history, ...props }) => {
    const classes = useStyles();
    const { params } = props.match;
    const supportId = params?.supportId;
    const [supportDetails, setSupportDetails] = useState({});

    //loads the initial data when form is loading//
    useEffect(() => {
        supportServiceObj.getDetailBySupportId(supportId).then(response => {
            setSupportDetails(response);
        }).catch(err => {
            console.log(err)
        })
        // eslint-disable-next-line
    }, [])

    const handleMarkAsRead = () => {
        let params = {
            is_read: true,
        }
        supportServiceObj.markSupportAsRead(params, supportId).then(response => {
            if (response) {
                history.push('/admin/masters/feedback')
            }
        })
    }

    return (
        <Grid container spacing={1} className={classes.outerContainer}>
            <Grid item xs={10}>
                <Typography className={classes.inputLabel}>Feedback Details</Typography>
            </Grid>

            <Grid style={{ padding: 0 }} item xs={2}>
                <Box className={classes.masterBtn} display="flex" flexDirection="row-reverse" >
                    <Button component={Link} to={'/admin/masters/feedback'}>
                        Cancel
                    </Button>
                    <Button style={{ marginRight: "8px" }} onClick={handleMarkAsRead} component={Link} to={`/admin/masters/feedback`}>
                        Mark as Read
                    </Button>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <div className={classes.contentRoot}>
                    <Typography className={classes.inputLabel}>Email</Typography>
                    <TextField fullWidth
                        name="email"
                        variant="outlined"
                        aria-readonly
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                            classes: {
                                input: classes.inputField,
                                root: classes.base,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#2a2c2e' },
                        }}
                        value={supportDetails.created_by}
                    />
                    <Typography className={classes.inputLabel}>Created On</Typography>
                    <TextField fullWidth
                        name="created_on"
                        variant="outlined"
                        aria-readonly
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                            classes: {
                                input: classes.inputField,
                                root: classes.base,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#2a2c2e' },
                        }}
                        value={moment(supportDetails.created_date).format('DD-MM-YYYY')}
                    />
                    <Typography className={classes.inputLabel}>Answers</Typography>
                    <div className={classes.supportQuestionBlock}>
                        {supportDetails && supportDetails.questions && supportDetails.questions.length > 0 && supportDetails.questions.map(question => {
                            return (
                                <>
                                    <div className="question">{`- ${question.support_question}`}</div>
                                    <div className="answer">{question.answer ? 'True' : 'False'}</div>
                                </>
                            )
                        })}
                    </div>
                    <Typography className={classes.inputLabel}>Labs</Typography>
                    <TextField fullWidth
                        name="labs"
                        variant="outlined"
                        aria-readonly
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                            classes: {
                                input: classes.inputField,
                                root: classes.base,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#2a2c2e' },
                        }}
                        value={supportDetails.labs || ''}
                    />

                    <Typography className={classes.inputLabel}>Message</Typography>
                    <TextField fullWidth
                        name="message"
                        variant="outlined"
                        aria-readonly
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                            classes: {
                                input: classes.inputField,
                                root: classes.base,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#2a2c2e' },
                        }}
                        value={supportDetails.message || ''}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(Form);