
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#fff',
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '6px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            backgroundColor: '#fff',
        },
    },
}))(InputBase);

export const useStyles = makeStyles((theme) => ({
    container:{
        padding:'24px 24px 0px 24px',
    },
    inputLabel:{
        fontSize:14,
        color:'#000',
        fontWeight:500,
        marginBottom:4
    },
    inputValue:{
        fontSize:14,
        color:'#2a2c2e',
        fontWeight:400,
    },
    select:{
        minWidth:216,
    },
    menuItem: {
        fontSize: 12,
    },
    refreshIcon:{
        width:20,
        height:20,
        color:'#bd0912',
        margin:'auto 12px',
        cursor:'pointer'
    }

}));