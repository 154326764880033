import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  HvButton,
  HvDialog,
  HvDialogTitle,
  HvDialogContent,
  HvDialogActions,
  HvTab,
  HvTabs,
  HvTypography,
  HvStack,
  HvDrawer,
  HvGrid
} from "@hitachivantara/uikit-react-core";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import { classes } from "./style";
import {
  Stop,
  Pause,
  Open,
  Home,
  EditNote,
  DropRight,
  DropLeft
} from "@hitachivantara/uikit-react-icons";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "redux/actions/commonActions";
import _ from "lodash";
import { SAAS_LAB, CO_CREATION_LAB } from 'constants/labs';
import LabService from "services/LabService";
import { buildQueryParams } from "utils/common";
import FeedbackDrawer from "containers/newHome/FeebBackDrawer";
import { useHistory } from 'react-router';
import { showBanner } from "redux/actions/bannerActions";

LabContentInTab.propTypes = {
  instruction: PropTypes.any,
  videoArray: PropTypes.any,
  labName: PropTypes.any
};

function LabContentInTab(props) {
  const [tabIndex, setTabIndex] = useState(0);
  const instruction = sessionStorage.getItem("Instruction");
  const videoArray = JSON.parse(sessionStorage.getItem("Video"));
  const labType = sessionStorage.getItem("labType");
  const provider = sessionStorage.getItem("provider");
  const session_id = sessionStorage.getItem("sessionId");
  const labId = sessionStorage.getItem("labId");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openFeedBackDrawer, setOpenFeedbackDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDisconnect, setOpenDisconnect] = useState(false);
  const history = useHistory();

  const labServiceObj = new LabService();
  const dispatch = useDispatch();

  const tabsName = [
    {
      index: 0,
      name: 'Text Instructions',
    },
    {
      index: 1,
      name: 'Video Instructions',
    }
  ];

  const renderInstruction = (instruction) => (
    <>
      <div
        style={{ overflow: "scroll", width: "100%" }} >
        {
          _.isNull(instruction) || _.isEmpty(instruction) || _.isUndefined(instruction) || instruction === "null" || instruction === "undefined" ?
            <HvTypography variant='selectedNavText'>No Text Instructions.</HvTypography>
            : <object data={instruction} type="text/html" aria-label="Url" width={"100%"} height={window.screen.height} />
        }

      </div>
    </>
  )

  const onFailure = () => console.log("player error");
  const onSuccess = () => console.log("player success");

  const userActivePollingApi = () => {
    let params = {};
    try {
      dispatch(showLoader(false));
      params["session_id"] = session_id;
      labServiceObj
        .checkSessionActive(buildQueryParams(params))
        .then((response) => {
          if (response) {
            console.log(response)
          }
          dispatch(showLoader(false));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (provider === "AWS" || labType === SAAS_LAB) {

      userActivePollingApi();
      const intervalId = setInterval(() => {
        userActivePollingApi();
      }, 60000);

      return () => clearInterval(intervalId);
    }
  }, []);

  const openTerminateDialog = () => {
    setOpen(true);
  };

  const openDisconnectDialog = () => {
    setOpenDisconnect(true);
  };

  const handleFeedbacKState = (value) => {
    setOpenFeedbackDrawer(value);
  };

  const terminateLab = () => {
    const payload = {
      id: session_id
    }
    labServiceObj.terminateLab(payload).then((response) => {
      if (_.isEmpty(response)) {
        history.push("/labs");
        localStorage.clear("stopAllowedExtension")
      }
    }
    ).catch((error) => {
      if (error) {
        history.push("/labs")
        dispatch(showBanner({
          payload: {
            showBanner: true,
            label: { message: "Error Occured while terminating Lab." },
            variant: "error"
          }
        }))
      }

    });
  }
  const disconnectLab = () => {
    const payload = {
      id: session_id
    }
    labServiceObj.disconnectLab(payload, "runstate=pause").then((response) => {
      if (!_.isEmpty(response)) {
        history.push("/labs")
      }
    })
      .catch((error) => {
        if (error) {
          if (error?.detail === 'Not found.') {
            history.push("/labs")
          }
          else {
            history.push("/labs")
            dispatch(showBanner({
              payload: {
                showBanner: true,
                label: { message: "Error Occured while disconnecting Lab." },
                variant: "error"
              }
            }))
          }
        }

      });
  }

  const renderVideo = (videoArray) => {
    if (videoArray?.length > 0) {
      return (
        videoArray?.map((item) => {
          let urlParts = item?.split('/');
          const accountId = urlParts[3];
          const playerId = urlParts[4].split('_')[0];
          const videoId = urlParts[5].split('?videoId=')[1];
          return (
            <>
              <HvGrid item xl={4} lg={6} xs={12} sm={12} style={{ paddingTop: "50px", paddingLeft: "50px" }}>
                <ReactPlayerLoader accountId={accountId} videoId={videoId} playerId={playerId} onFailure={onFailure}
                  onSuccess={onSuccess}
                  attrs={{
                    responsive: true,
                    autoplay: true,
                    muted: true,

                  }}
                />
              </HvGrid>

            </>
          )
        })
      )

    } else {
      return (
        <>
         <HvGrid item xl={4} lg={6} xs={12} sm={12} style={{ paddingTop: "50px", paddingLeft: "50px" }}>  <HvTypography variant="selectedNavText">No Video Instructions.</HvTypography></HvGrid>
        
        </>
      )
    }
  }

  const handleTabChange = (e, newValue) => setTabIndex(newValue);

  const openInNewTab = () => {
    const newTab = window.open("", "_blank");
    const path = "/instructionInTab"; // Adjust the path as needed
    newTab.location.href = path;
    newTab.document.close();
  };
  return (
    <>
      {
        
          <div>
            <HvTypography variant="mTitle" style={{ paddingLeft: "80px", paddingTop: "10px" }}>{props.labName}</HvTypography>
            <HvTabs id="tabs-main" value={tabIndex} className={classes.tabs} style={{ paddingLeft: "80px", paddingTop: "10px" }} onChange={handleTabChange}>
              {
                tabsName.map((tab) => (
                  <HvTab id={tab.index} label={tab.name} value={tab.index} onChange={handleTabChange} />
                ))
              }
            </HvTabs>
            {
              tabIndex === 0 && renderInstruction(instruction)
            }
            <HvGrid container rowGap={2}>
              {
                tabIndex === 1 && renderVideo(videoArray)
              }
            </HvGrid>
            {
              provider === "AWS"   || labType === SAAS_LAB ?
                <>
                  <HvDrawer variant="persistent"
                    PaperProps={{
                      className: classes.awsAndsaasDrawser,
                      component: 'aside'
                    }}
                    open={openDrawer}
                    anchor="right">
                    {
                      labType === CO_CREATION_LAB ?
                        <HvStack divider style={{ paddingTop: "5px" }}>
                          <Home
                            className={classes.iconButton}
                            onClick={() => {
                              disconnectLab();
                              history.push("/labs");
                            }}
                            size={"xs"}
                            title="Home"
                          />
                          <Stop
                            onClick={openTerminateDialog}
                            className={classes.iconButton}
                            size={"xs"}
                            title="Terminate"
                          />
                          <Pause
                            onClick={openDisconnectDialog}
                            className={classes.iconButton}
                            size={"xs"}
                            title="Disconnect" />

                          <Open
                            className={classes.iconButton}
                            onClick={openInNewTab}
                            size={"xs"}
                            title="Open"
                          />
                          <EditNote
                            onClick={() => {
                              setOpenFeedbackDrawer(!openFeedBackDrawer);
                            }}
                            className={classes.iconButton}
                            size={"xs"}
                            title="Feedback"
                          />

                          {/* <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={()=>{()=>{extendLabTime()}}} title="Extend Lab"><Time style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }}/></HvButton> */}
                        </HvStack> : <HvStack divider style={{ paddingTop: "5px" }}>
                          <Home
                            className={classes.iconButton}
                            onClick={() => {
                              disconnectLab();
                              window.close();
                              history.push("/labs");
                            }}
                            size={"xs"}
                            title="Home"
                          />
                          <Stop
                            onClick={openTerminateDialog}
                            className={classes.iconButton}
                            size={"xs"}
                            title="Terminate"
                          />
                          <Open
                            className={classes.iconButton}
                            onClick={openInNewTab}
                            size={"xs"}
                            title="Open"
                          />
                          <EditNote
                            onClick={() => {
                              setOpenFeedbackDrawer(!openFeedBackDrawer);
                            }}
                            className={classes.iconButton}
                            size={"xs"}
                            title="Feedback"
                          />
                          {/* <HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={()=>{()=>{extendLabTime()}}} title="Extend Lab"><Time style={{ color: "var(--uikit-typography-lTitle-color)", backgroundColor: "var(--toast-white)" }}/></HvButton> */}
                        </HvStack>
                    }

                  </HvDrawer>
                  {
                    openDrawer === false ? <DropLeft style={{
                      position: 'fixed',
                      right: '0%',
                      top: '50%',
                      zIndex: 1200
                    }}
                      className={classes.drawerButton} onClick={() => {
                        setOpenDrawer(true)
                      }} size="xs" /> : <DropRight style={{
                        position: 'fixed',
                        right: '70px',
                        top: '40%',
                        zIndex: 1200,
                      }}
                        className={classes.drawerButton}
                        onClick={() => {
                          setOpenDrawer(false)
                        }} size="xs" />
                  }
                </>
                :
                <>
                </>
            }
          </div>
      }
      {
        openFeedBackDrawer ? (
          <div>
            <FeedbackDrawer
              labId={labId}
              openDrawer={openFeedBackDrawer}
              handleDrawerState={handleFeedbacKState}
            />
          </div>
        ) : (
          <></>
        )
      }
      {
        open ? (
          <div>
            <HvDialog
              disableBackdropClick
              open={open}
              onClose={() => setOpen(false)}
              id="terminate"
              classes={{
                closeButton: classes.closeButton,
              }}
            >
              <HvDialogTitle variant="warning">Terminate Lab?</HvDialogTitle>
              <HvDialogContent indentContent>
                If you have just launched the lab then please wait for 5 mins and
                then terminate. Terminate lab will end the lab. You need to run
                the lab again.
              </HvDialogContent>
              <HvDialogActions>
                <HvButton
                  id="apply"
                  onClick={terminateLab}
                  variant="primaryGhost"
                >
                  Yes
                </HvButton>
                <HvButton
                  id="cancel"
                  variant="primaryGhost"
                  onClick={() => setOpen(false)}
                >
                  No
                </HvButton>
              </HvDialogActions>
            </HvDialog>
          </div>
        ) : (
          <></>
        )
      }
      {
        openDisconnect ? (
          <div>
            <HvDialog
              disableBackdropClick={true}
              open={openDisconnect}
              id="disconnect"
              classes={{
                closeButton: classes.closeButton,
              }}
            >
              <HvDialogTitle variant="warning">Disconnect Lab?</HvDialogTitle>
              <HvDialogContent indentContent>
                Disconnect lab will pause the Lab.You can access it from Running
                Labs section.
              </HvDialogContent>
              <HvDialogActions>
                <HvButton
                  id="apply"
                  onClick={disconnectLab}
                  variant="primaryGhost"
                >
                  Yes
                </HvButton>
                <HvButton
                  id="cancel"
                  variant="primaryGhost"
                  onClick={() => setOpenDisconnect(false)}
                >
                  No
                </HvButton>
              </HvDialogActions>
            </HvDialog>
          </div>
        ) : (
          <></>
        )
      }
    </>
  );
}

export default LabContentInTab;