import { Button, Icon, styled, TextField } from "@material-ui/core";
import { checkSupportedFormat, checkUploadedFileSize } from "utils/common";
import { useStyles } from "utils/theme";
import { useStyles as moduleStyle } from "./style";
import FileUploadService from 'services/FileUploadService';
import { toast } from "react-toastify";
import LinearProgressWithLabel from "components/LinearProgressWithLabel";
import { useSelector } from 'react-redux';
const Input = styled('input')({
    display: 'none',
});
const fileUploadServiceObj = new FileUploadService();
export default function SingleFileUpload({ fieldName, fieldLabel, fieldValue, fieldError,
    supportedFileTypes,
    supportedFileMaxSize,
    onSuccessUpload,
    moduleName }) {
    const classes = useStyles();
    const moduleClasses = moduleStyle();
    const progress = useSelector(state => state.commonReducer.progress);
    const uploadFileToServer = async (file) => {

        try {
            const formData = new FormData();
            formData.append('thumbnail', file);
            const result = moduleName !== 'coCreation' && (moduleName === 'labIcons' ? await fileUploadServiceObj.fileUploadLabs(formData) : await fileUploadServiceObj.fileUploadGeneral(formData));
            moduleName === 'coCreation' ? onSuccessUpload(file, file.name) :onSuccessUpload(result);
            
        }
        catch (error) {
        }
    };

    const fileUpload = async (event) => {
        try {

            const imageFile = event.target.files[0];
            const isValidFormat = checkSupportedFormat(imageFile.name, supportedFileTypes);
            if (isValidFormat) {
                const isValidSize = checkUploadedFileSize(imageFile, supportedFileMaxSize)
                if (isValidSize) {
                    await uploadFileToServer(imageFile);
                }
                else toast.error(`${imageFile.name} file size is greater than ${supportedFileMaxSize} MB`);
            }
            else toast.error(`${imageFile.name} file's format not supported.`);


        } catch (error) {
            console.log(error);
        }
    }



    return <>
        {moduleName !== 'coCreation' && <div className={moduleClasses.leftPanel}>
            <TextField fullWidth
                disabled
                name={fieldName}
                label={fieldLabel}
                variant="outlined"
                placeholder={fieldLabel}
                classes={{ root: classes.customTextField }}
                InputProps={{
                    classes: {
                        input: classes.inputField,
                        root: classes.base,
                        focused: classes.focused,
                        notchedOutline: classes.notchedOutline
                    },
                }}
                InputLabelProps={{
                    shrink: true,
                    style: { color: '#2a2c2e' },
                }}
                value={fieldValue || ''}
                error={(fieldError !== '') ? true : false}
                helperText={(fieldError !== '') ? fieldError : null}
            />
        </div>
        }
        <div className={moduleName === 'coCreation' ? moduleClasses.fileUploadButton : moduleClasses.rightPanel} >
            <label htmlFor="contained-button-file" >
                <Input onChange={(e) => fileUpload(e)} accept="image/*" id="contained-button-file" multiple type="file" />
                <Button className={classes.fileUploadIcon} variant="contained" component="span">
                    <Icon>cloud_upload</Icon> Upload
                </Button>
            </label>
        </div>
        {
            progress > 0 && progress < 100 ? (
                <LinearProgressWithLabel value={progress} />
            ) : null
        }
    </>
}