import React, { useState } from 'react';
import { HvDialog, HvDialogContent, HvDialogTitle } from '@hitachivantara/uikit-react-core';
import { LOGGED_IN_USER_INFO } from 'constants/common';
import { useHistory } from 'react-router';


const UserGuide = () => {
    const [open, setOpen] = useState(true);
    const history = useHistory();
    let data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
    let token = data ? JSON.parse(data) : {}
    const UserGuide = token?.access_token ? process.env.REACT_APP_UserGuide : process.env.REACT_APP_EBrochure
    const header = token?.access_token ? "User Guide"  : "Brochure"
    return (
        <div>
            <HvDialog open={open} fullScreen={open} onClose={() => {
                setOpen(!open);
                history.goBack();
            }}>
                <HvDialogTitle variant='info' style={{ flex: "0 0 0%" }}>{header}</HvDialogTitle>
                <HvDialogContent indentContent >
                    <object data={UserGuide} aria-label="Url" type="text/html" width={"100%"} height={window.screen.height} />
                </HvDialogContent>
            </HvDialog>
        </div>
    );
};

export default UserGuide;