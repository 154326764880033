import React, { useState, useEffect } from "react";
import { Tabs, Tab, AppBar, TextField, Button, Box, Icon } from '@material-ui/core';
import { toast } from "react-toastify";
import Unauthorized from "components/Unauthorized";
import _ from "lodash";
import {
  getAdminSettings,
  saveAdminSettings,
} from "redux/actions/adminSettingsActions";
import { SUCCESS_MESSAGE_ADMIN_SETTINGS_FORM, GENERAl_ERROR_ADMIN_SETTINGS_FORM } from "constants/common";
import { useDispatch, useSelector } from "react-redux";
import TabPanel, { a11yProps } from 'components/TabPanel';
import { useStyles } from "utils/theme";
// import { Alert } from "@material-ui/lab";
import EncryptionTool from "components/EncryptionTool";

const AdminSettings = () => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const { settingsData, serverErrors } = useSelector(state => state.adminSettingsReducer);
  const [settingsForm, setSettingsForm] = useState(settingsData);

  const [currentTab, setCurrentTab] = useState(0);
  const screenSize = window.screen.width;

  useEffect(() => {
    if (_.isEmpty(settingsData)) {
      dispatch(getAdminSettings());
    }
  });

  useEffect(() => {
    setSettingsForm(settingsData);
  }, [settingsData]);


  const handleSubmit = async e => {
    e.preventDefault();
    dispatch(saveAdminSettings(settingsData)).then(response => {
      if (response) {
        toast.success(SUCCESS_MESSAGE_ADMIN_SETTINGS_FORM)
      }
    })
  };
  const updateField = (updatedValue, groupIndex, index) => {
    const data = [...settingsForm];
    data[groupIndex].adminsettings[index].value = updatedValue;
    setSettingsForm(data)
  };
  const getErrors = (serverErrors) => {
    const errors = {};
    if (!_.isEmpty(serverErrors)) {
      _.forOwn(serverErrors, function (group) {
        if (!_.isEmpty(group)) {
          (group?.adminsettings || []).map((field) => (
            (!_.isEmpty(field)) ? (
              errors[field.id[0]] = field.value[0]
            ) : null
          ))
        }
      })
    }
    return errors;
  }
  const errors = getErrors(serverErrors);
  return (
    screenSize >= 768 ?
    <div className='main-header'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='main-label'>Settings</div>
        <EncryptionTool />
      </div>
      <div className={classes.contentRoot}>
        <form onSubmit={handleSubmit}>
          <AppBar position="static">
            <Tabs value={currentTab} onChange={(e, v) => setCurrentTab(v)} className={classes.tabs} >

              {settingsForm &&
                settingsForm.map((group, groupIndex) => (
                  <Tab key={groupIndex} label={group.name} {...a11yProps(groupIndex)} />))
              }
            </Tabs>
          </AppBar>
          {settingsForm &&
            settingsForm.map((group, groupIndex) => (
              <TabPanel key={groupIndex} className={'tabs-panel'} value={currentTab} index={groupIndex}>

                {group.adminsettings.map((field, index) => (

                  <TextField fullWidth
                    key={index}
                    name={field.key}
                    label={field.key} variant="outlined"
                    classes={{ root: classes.customTextField }}
                    InputProps={{
                      classes: {
                        input: classes.inputField,
                        root: classes.base,
                        focused: classes.focused,
                        notchedOutline: classes.notchedOutline
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: { color: '#2a2c2e' },
                    }}
                    onChange={(e) =>
                      updateField(
                        e.target.value,
                        groupIndex,
                        index
                      )
                    }
                    value={
                      settingsForm[groupIndex]
                        .adminsettings[index].value
                    }
                    error={(errors[field.id] && errors[field.id] !== undefined) ? true : false}
                    helperText={(errors[field.id] && errors[field.id] !== undefined) ? errors[field.id] : null}
                  />


                ))}


              </TabPanel>


            ))
          }

          {!_.isEmpty(settingsForm) ? (
            <>
              {!_.isEmpty(errors) ? (
                <Box p={1}>
                  {/* <Alert severity="error">
                  {GENERAl_ERROR_ADMIN_SETTINGS_FORM}
                </Alert> */}
                </Box>
              ) : null}
              <Box className={classes.masterBtn} p={1}>
                <Button type="submit" variant="contained" color="primary"
                  startIcon={<Icon>save</Icon>}
                >
                  Save
                </Button>
              </Box>

            </>) : null}

        </form>
      </div>
    </div>
    : <Unauthorized/>
  );

}


export default AdminSettings;
