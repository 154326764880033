import React, { Fragment, useEffect, useState } from "react";
import Pagination from "components/pagination";
import { Box, Typography } from "@material-ui/core";
import ReactReadMoreReadLess from "react-read-more-read-less";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useStyles, BootstrapInput } from "./style.js";
import uuid from "react-uuid";
import Unauthorized from "components/Unauthorized";
import { buildQueryParams, transformDataCustomSelect } from "utils/common.js";
import ToolService from "services/ToolService.js";
import { MAX_CATEGORY_LIMIT } from "constants/common.js";

const toolsServiceObj = new ToolService();

export default function Tools() {
  const classes = useStyles();
  const screenSize = window.screen.width;
  const [toolsData, setToolsData] = useState([]);
  const [toolsCategory, setToolsCategory] = useState([]);
  const [toolType, setToolType] = useState(0);
  const [totalToolsCount, setTotalToolsCount] = useState();
  const [value] = useState(0);
  const [pageSize, setPagesize] = useState(10);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getToolsData();
    // eslint-disable-next-line
  }, [toolType, pageSize, page]);

  useEffect(() => {
    let urlParams = {
      is_deleted: false,
      limit: MAX_CATEGORY_LIMIT
    };
    let toolsCategory = [{ label: "All Tools", value: 0 }];
    toolsServiceObj
      .getToolsCategoryList(buildQueryParams(urlParams))
      .then((response) => {
        if (response) {
          setToolsCategory([
            ...toolsCategory,
            ...transformDataCustomSelect(response.results),
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  const getToolsData = () => {
    let urlParams = {};
    urlParams["ordering"] = "-id"; //default sorting - is used for decending
    urlParams["limit"] = pageSize;
    urlParams["offset"] = pageSize * (page - 1);
    urlParams["is_deleted"] = false;
    urlParams["type"] = 1;
    if (toolType !== 0) {
      urlParams["tools_category"] = toolsCategory.filter(
        (el) => el.value === toolType
      )[0].label;
    }
    toolsServiceObj
      .getToolsList(buildQueryParams(urlParams))
      .then((response) => {
        if (response) {
          setToolsData(response.results);
          setTotalToolsCount(response.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDropdown = (event) => {
    setToolType(event.target.value);
  };

  const handleClick = (url) => {
    // window.open(url, "_blank").focus();
  };

  const handlePageSize = (pageSizeValue) => setPagesize(pageSizeValue);

  const handlePage = (page) => setPage(page);

  const renderToolsAndAssetsData = (records) => {
    return (
      records &&
      records.length > 0 &&
      records.map((data) => {
        return (
          <Fragment key={uuid()}>
            <Box component="div" display-="block" className={classes.container}>
              <Box className={classes.toolsIconBlock}>
                <img src="./assets/images/Tools.svg" alt="tools" />
                <Box
                  display="flex"
                  justifyContent="center"
                  marginTop="6px"
                  onClick={() => handleClick(data.link)}
                >
                  <Typography className={classes.redirectionLabel}>
                    GO TO TOOL
                  </Typography>
                  <ExpandMoreIcon className={classes.redirectAccordion} />
                </Box>
              </Box>

              <Box component="div" className={classes.descriptionBlock}>
                <Typography variant="h6" className={classes.custom}>
                  {data.name}
                </Typography>

                <ReactReadMoreReadLess
                  charLimit={200}
                  readMoreText={"Read more..."}
                  readLessText={"Read less..."}
                  readMoreClassName={classes.readMore}
                  readLessClassName={classes.readMore}
                  className={classes.description}
                >
                  {data.description}
                </ReactReadMoreReadLess>
              </Box>
            </Box>
          </Fragment>
        );
      })
    );
  };

  return (
    <>
      {screenSize >= 768 ? (
        <div className="main-header">
          <Box component="div" className={classes.headerContainer}>
            <span className="main-label">TOOLS</span>
            {value === 0 && (
              <FormControl>
                <Select
                  labelId="demo-customized-select-label"
                  value={toolType}
                  className={classes.select}
                  onChange={handleDropdown}
                  input={<BootstrapInput />}
                >
                  {toolsCategory &&
                    toolsCategory.length > 0 &&
                    toolsCategory.map((option, index) => {
                      return (
                        <MenuItem
                          key={uuid()}
                          className={classes.menuItem}
                          value={option.value}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            )}
          </Box>
          <div className={classes.root}>
            {renderToolsAndAssetsData(toolsData)}

            <Box width="100%">
              <Pagination
                handlePageSize={handlePageSize}
                totalCount={totalToolsCount}
                pageSize={pageSize}
                handlePageVal={handlePage}
                pageNumber={page}
              />
            </Box>
          </div>
        </div>
      ) : (
        <Unauthorized />
      )}
    </>
  );
}
