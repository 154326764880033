import React from "react";
import { Route, Redirect,withRouter } from "react-router-dom";
import { parseQueryParams } from "utils/common";
import { useSelector } from "react-redux";
import { checkPermissions } from "utils/masterHelpers";
import { LOGGED_IN_USER_INFO } from "constants/common";
const PrivateRoute = ({ component: Component, ...rest }) => {

  const sessionUserData = JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO));
  const stateUserData = useSelector(state => state.loginReducer.userInfo);
  const userData = sessionUserData ?  sessionUserData : stateUserData;  
  const userLoggedIn = userData?.id > 0 ? true : false;  
  const userRoles = Object.values(userData?.roles[0]);
  const userGroups = userData?.groups?.map(group => group.id);
  //console.log('userRoles',userRoles,userData) 
  const urlData = parseQueryParams(window.location.search);


  return (
    <Route
      {...rest}
      render={(props) =>
        userLoggedIn ? (
          checkPermissions(rest,userRoles,userGroups) ? (
            //user roles and required roles satisfy
           <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/403",
                state: { from: props.location },
              }}
            />
          )
        ) : (
          (!urlData.t) ?
            (<Redirect
              to={{
                pathname: "/email-login",
                state: { from: props.location },
              }}
            />) : null
        )
      }
    />
  );
};
export default withRouter(PrivateRoute);
