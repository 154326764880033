import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root:{
        display:'flex',
        width:'200px',
        margin: '6px 0px 6px 0px',
        "@media(max-width:920px)": {
            padding:0,
            marginTop:2,
            marginBottom:0,
        }
    },
    custom: {
        fontSize: '12px',
        margin: '0px 6px 0px 6px',
        color: '#2a2c2e',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        width:198,
        overflow:'hidden',
    },
    hyperlink: {
        color: '#1874CD',
        fontSize: '12px',
        margin: '0px 6px 0px 6px',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        width:198,
        overflow:'hidden',
    },
    metaLinks: {
        display: 'flex',
        flexDirection: 'column',
        "@media(max-width:920px)": {
            display: 'block',
        }
    }
}));