import { HvDonutChart } from "@hitachivantara/uikit-react-viz";
import { HvBarChart } from "@hitachivantara/uikit-react-viz";
import {
  HvDatePicker,
  HvGrid,
  HvTypography,
  HvPanel,
  HvDropDownMenu,
  HvEmptyState
} from "@hitachivantara/uikit-react-core";
import { HvContainer, HvSection } from "@hitachivantara/uikit-react-core";
import { Info } from "@hitachivantara/uikit-react-icons";
import { HvRadioGroup } from "@hitachivantara/uikit-react-core";
import { HvRadio } from "@hitachivantara/uikit-react-core";
import ReportService from "services/ReportService.js";
import { useStyles } from "./styles";
import React, { useEffect, useState } from "react";
import { buildQueryParams } from "utils/common.js";
import TableViewTop10 from "./TableViewTop10";
import moment from "moment";
import { useDispatch } from "react-redux";
import { showBanner } from "redux/actions/bannerActions";
import _ from "lodash";

const reportsServiceObj = new ReportService();
function ChartsTop10() {
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 30);
  const dispatch = useDispatch();
  const [displayExport, setDisplayExport] = useState(0);
  const [selectedRadioButton, setSelectedRadioButton] = useState(0);
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(new Date());
  const [barChartData, setBarChartData] = useState([]);
  const [dounetChartData, setDounetChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedOrderBy, setSelectedOrderBy] = useState("session")
  const [measures, SetMeasures] = useState("Sessions")
  const classes = useStyles();
  const labels = {
    applyLabel: "Apply",
    cancelLabel: "Cancel",
  };



  function roundToTwoDecimalsAndAdjust(percentages) {
    let rounded = percentages.map(num => Math.round(num * 100) / 100);
    let total = rounded.reduce((acc, val) => acc + val, 0);
    total = Math.round(total * 100) / 100;
    let diff = Math.round((100 - total) * 100) / 100;

    while (diff !== 0) {
      for (let i = 0; i < rounded.length; i++) {
        if (diff > 0 && rounded[i] < 100) {
          rounded[i] = Math.round((rounded[i] + 0.01) * 100) / 100;
          diff = Math.round((diff - 0.01) * 100) / 100;
        } else if (diff < 0 && rounded[i] > 0) {
          rounded[i] = Math.round((rounded[i] - 0.01) * 100) / 100;
          diff = Math.round((diff + 0.01) * 100) / 100;
        }
        if (diff === 0) break;
      }
    }

    return rounded;
  }
  //default date range
  //API call to get top 10 labs by run time
  const getReportByDefaultDateRangeRunTime = (order_by, startDate, endDate) => {
    let params = {};
    let reportData = [];
    if (!_.isUndefined(startDate) && !_.isUndefined(endDate)) {
      params["start_date"] = moment(startDate).format("YYYY-MM-DD");
      params["end_date"] = moment(endDate).format("YYYY-MM-DD");
      params["report_type"] = order_by;
      reportsServiceObj
        .getTop10ByRunTime(buildQueryParams(params))
        .then((response) => {
          if (response) {

            reportData = [{
              Sessions: response?.total_session ? response?.total_session : [],
              LabNames: response?.lab_name,
              RunTime: response?.total_used_time ? response?.total_used_time : [],
              percentage: response?.percentage?.length !== 0 ? roundToTwoDecimalsAndAdjust(response?.percentage) : []
            }]
            setBarChartData(reportData);
            setDounetChartData(reportData);

          }

        });
      setDisplayExport(1);
      setSelectedRadioButton(2);
    } else {
      setBarChartData([]);
      setDounetChartData([]);
    }

  };

  useEffect(() => {
    getReportByDefaultDateRangeRunTime(selectedOrderBy, startDate, endDate)
  }, [selectedOrderBy, startDate, endDate])
  //export data to excel
  const getExcelLabReport = () => {
    let params = {};
    params["start_date"] = moment(startDate).format("YYYY-MM-DD");
    params["end_date"] = moment(endDate).format("YYYY-MM-DD");
    params["file_format"] = "excel";
    params["report_type"] = selectedOrderBy;
    reportsServiceObj
      .getLabReportTop10Session(buildQueryParams(params))
      .then((response) => {
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(response);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "lab_report.xlsx"; // Set the desired filename

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Excel data:", error);
      });
  };

  //export data to PDF
  const getPDFLabReport = () => {
    let params = {};
    params["start_date"] = moment(startDate).format("YYYY-MM-DD");
    params["end_date"] = moment(endDate).format("YYYY-MM-DD");
    params["file_format"] = "pdf";
    params["report_type"] = selectedOrderBy;
    reportsServiceObj
      .getLabReportTop10Session(buildQueryParams(params))
      .then((response) => {
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(response);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "lab_report.pdf"; // Set the desired filename

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Excel data:", error);
      });
  };




  return (
    <>
      <HvContainer>
        <HvPanel>
          <HvTypography variant="xsTitle">
            Labs Details Report - Top 10 Labs by Session Count and Run Time
          </HvTypography>
        </HvPanel>
        <HvGrid
          container
          spacing={2}
          rowSpacing={5}
          style={{ paddingTop: "50px", paddingLeft: "25px" }}
        >
          <HvGrid
            item
            xs={1}
            sm={1}
            md={2}
            lg={4}
            xl={2}
            style={{ display: "contents", paddingLeft: "15px" }}
          >
            <HvTypography
              variant="highlightText"
              style={{ alignSelf: "center" }}
            >
              Select Filters:
            </HvTypography>
          </HvGrid>
          <HvGrid
            item
            xs={3}
            sm={4}
            md={4}
            lg={3}
            xl={3}
            style={{
              paddingTop: "5px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <HvRadioGroup
              style={{ width: "100%" }}
              description=""
              label=""
              name="favorite"
            >
              <HvRadio
                checked={measures === "Sessions" ? true : false}
                value="1"
                onChange={(evt, value) => {
                  setSelectedOrderBy("session");
                  SetMeasures("Sessions")
                }
                }
                label="Top 10 Labs by Session Count"
              />
              <HvRadio
                value="2"
                onChange={(evt, value) => {
                  setSelectedOrderBy("used_time")
                  SetMeasures("RunTime");
                }
                }
                label="Top 10 Labs by Run Time"
              />
            </HvRadioGroup>
          </HvGrid>
          <HvGrid item tem xs={4} sm={4} md={4} lg={4} xl={4} >
            <HvDatePicker
              id="DatePicker"
              aria-label="Date"
              placeholder="Select a range"
              labels={labels}
              rangeMode
              showClear
              calendarProps={{
                maximumDate: new Date(),
              }}
              className={classes.filterUserGroup}
              startValue={startDate}
              endValue={endDate}
              onChange={(startDate, endDate) => {
                if (moment(startDate).format("YYYY-MM-DD") === moment(endDate).format("YYYY-MM-DD") && !_.isUndefined(startDate) && !_.isUndefined(endDate)) {
                  dispatch(showBanner({
                    payload: {
                      showBanner: true,
                      label: { message: "Start Date and End Date can not be same." },
                      variant: "error"
                    }
                  }))
                } else {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }

              }}
              onClear={() => {
                setBarChartData([]);
                setDounetChartData([]);
                setTableData([]);
              }}
            />
          </HvGrid>
          {displayExport === 1 ? (
            <HvGrid
              item
              xs={1}
              sm={1}
              md={2}
              lg={1}
              xl={2}
              style={{ position: "absolute", top: 125, right: 100 }}
            >
              <>
                <HvDropDownMenu
                  dataList={[
                    {
                      label: "Download Excel Report",
                    },
                    {
                      label: "Download PDF Report",
                    },
                  ]}

                  placement="left"
                  size="md"
                  variant="secondaryGhost"
                  onClick={(e, value) =>
                    value.label === "Download Excel Report"
                      ? getExcelLabReport()
                      : getPDFLabReport()
                  }
                />
              </>
            </HvGrid>
          ) : (
            ""
          )}
        </HvGrid>
        <HvGrid container style={{ paddingTop: "30px" }}>
          {barChartData?.length > 0 ? (
            <>
              <HvGrid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                style={{ paddingTop: "60px" }}
              >
                <HvSection
                  key={"bar-0"}
                  title={
                    <HvTypography variant="label">Top 10 Labs {selectedOrderBy === "used_time" ? "Run Time" : "Sessions Count"}</HvTypography>
                  }
                >
                  <HvBarChart
                    key={"bar-0"}
                    data={barChartData[0]}
                    groupBy="LabNames"
                    measures={measures}
                    stack="total"
                    horizontal
                    title="Top 10 Labs"
                    height={400}
                    yAxis={{
                      type: "categorical",
                      position: "left",
                      label: {
                        formatter: function (value) {
                          return value.split(" ").join("\n");
                        },
                      },
                    }}
                    grid={{ left: 100 }}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "normal",
                    }}
                    onOptionChange={(option) => {
                      if (
                        Array.isArray(option.yAxis) &&
                        option.yAxis.length === 1
                      ) {
                        option.yAxis = [
                          {
                            ...option.yAxis[0],
                            axisLabel: {
                              ...option.yAxis[0].axisLabel,
                              width: 90,
                              overflow: "truncate",
                            },
                          },
                        ];
                      }


                      return option;
                    }}
                  />
                </HvSection>
              </HvGrid>
            </>
          ) : (

            <></>
          )}
          {dounetChartData.length > 0 ? (
            <>
              <HvGrid
                item
                xs={4}
                sm={4}
                md={4}
                lg={6}
                xl={6}
                style={{ paddingTop: "60px" }}
              >
                <HvSection
                  key={"donut-2"}
                  classes={{
                    content: "css-10klw3m",
                  }}
                  title={
                    <HvTypography variant="label">
                      Top 10 Labs {selectedOrderBy === "used_time" ? "Run Time" : "Sessions Count"} (%)
                    </HvTypography>
                  }
                >
                  <HvDonutChart
                    key={"donut-2"}
                    data={{
                      LabNames: barChartData[0]["LabNames"],
                      percentage: barChartData[0]["percentage"],
                    }}
                    groupBy={"LabNames"}
                    measures={"percentage"}
                    type="regular"
                    title="Lab Usage by Countries"
                    height={400}
                    width={700}
                    measure={{
                      field: "percentage",
                    }}
                    onOptionChange={(option) => {
                      option.series = [{
                        type: 'pie',
                        radius: ['50%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                          show: false,
                          position: 'center'
                        },
                        center: ['60%', '50%']
                      }]
                      option.legend = [{
                        orient: 'vertical',
                        type: 'scroll',
                        left: 'left',  // Position the legend to the left
                        top: 'middle',  // Center the legend vertically
                        itemGap: 10,   // Increase the spacing between legend items
                        height: '70%', // Set a fixed height to enable scrolling if needed
                        formatter: function (name) {  // Shorten legend item text if necessary
                          return name.length > 30 ? name.slice(0, 30) + '...' : name;
                        }
                      }]
                      option.tooltip = [{
                        confine: true,
                        formatter: '{b} : {d}%'
                      }]
                      return option;
                    }}
                  />
                </HvSection>
              </HvGrid>
            </>
          ) : (

            <></>
          )}
        </HvGrid>
        <HvGrid container style={{ paddingTop: "50px" }} columnGap={10}>
          {barChartData?.length ? (
            <>
              <HvGrid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableViewTop10 order_by={selectedOrderBy} startDate={startDate} endDate={endDate} />
              </HvGrid>
            </>
          ) : (
            <></>
          )}
        </HvGrid>
        {
          barChartData.length === 0 && dounetChartData.length === 0 ?
            <HvEmptyState
              icon={<Info />}
              message="No data to display."
            /> : <></>
        }

      </HvContainer>
    </>
  );
}

export default ChartsTop10;
