import styled from 'styled-components';

export const CardBlock = styled.div.attrs(props => props)`
    background-image: url(${(props) => `${props.sourceImage}`}); 
    position: relative;
    width: ${(props) => `${props.width}`}; 
    height: ${(props) => `${props.height}`}; 
    margin: ${(props) => `${props.margin}`}; 
    background-position: center; 
    background-repeat:no-repeat;
    background-size: ${(props) => `${props.backgroundSize}`}; 
    object-fit: cover;
    @media (min-width: 320px) and (max-width: 767px){
        height: ${(props) => `${props.rHeight}`}; 
        width:100%;
        background-position: center;
        margin-bottom:1px;
    }`;

export const CardParentBlock = styled.div.attrs(props => props)`
   width: ${(props) => `${props.width}`}; 
    overflow: hidden;
    cursor: pointer;
    &:hover ${CardBlock} {
    transform:scale(1.05);
    left:1%;
    transition: transform .6s;
    }
    @media (min-width: 320px) and (max-width: 767px){
        width: ${(props) => `${props.rWidth}`};
        height: ${(props) => `${props.rHeight}`}; 
    }
`;

export const CardLabel = styled.div`
    position: absolute;
    bottom: 16px;
    left: 16px;
    padding: 8px 0px 8px 0px;
    background-color: #bd0912;
    width: 44%;
    border-radius:4px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    @media (min-width: 320px) and (max-width: 767px){
        padding: 6px 0px 6px 0px;
        >span{
            font-size:12px;
            margin:auto 0;
        }
    } 
    >span{
        color: #fff;
        font-weight: 600;
        padding-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow:hidden;
    }
    .MuiSvgIcon-root{
        padding-right: 4px;
        color: #fff;
        width: 1em;
        @media (min-width: 320px) and (max-width: 767px){
            width: 1.4em;
        }
    }`;

export const PlayIcon = styled.div`
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%,-50%);
    cursor:pointer;
    >img{
        width: 44px;
        height: 44px;
    }
`;