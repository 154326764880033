import { css } from "@emotion/css";

export const  classes = {
    globalAction: css({
        '& .HvGlobalActions-wrapper': {
            justifyContent: "center"
        },
        '& .HvGlobalActions-actions': {
            marginLeft: "100px"
        },
        '& .HvGlobalActions-global': {
            zIndex: 0
        }
    }),
    readOrHide: css({
        color: '#1874CD',
        cursor: 'pointer',
        textDecoration: 'underline',
    })
}

