import { Method_Get_Tools_Category,Method_Get_Tools_List } from "constants/tools";
import NetworkService from "./NetworkService";

class ToolService {
    getToolsList = (urlParams) => {
        return NetworkService.get(`${Method_Get_Tools_List}?${urlParams}`).then((response) => response.data);
    }
    getToolsCategoryList = (urlParams) => {
        return NetworkService
            .get(`${Method_Get_Tools_Category}?${urlParams}`)
            .then((response) => response.data);
    }
}
export default ToolService;