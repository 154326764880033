import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop:8,
        '& .MuiPagination-root': {
            width: '100%',
        },
        "& .MuiPagination-ul": {
            justifyContent: 'center',
            " &  >li": {
                width: '24px',
                background: '#fff',
                border: '1px solid #c2c2c2',
                borderRight: 'none',
                " & .MuiPaginationItem-page.Mui-selected": {
                    background: '#1874CD',
                    color: '#fff',
                    borderRadius: '0px',
                },
                "& .MuiButtonBase-root": {
                    "& .MuiPaginationItem-icon": {
                        color: '#1874CD',
                        margin: '0px -3px',
                    }
                },
                "& .MuiPaginationItem-root": {
                    margin: '-1px',
                    height: '29px',
                    padding: '0px 8px 0px 8px',
                    minWidth: '0px',
                    fontsize: '12px',
                    width: '24px',
                },
            },
            "& >li:last-child": {
                borderRight: '1px solid #c2c2c2',
                borderRadius: '0px 4px 4px 0px',
            },
            "& >li:first-child": {
                borderRight: '1px solid #c2c2c2',
                borderRadius: '4px 0px 0px 4px',
            }
        },
    },
    item: {
        fontSize: '12px',
    },
    pagesizeDropdown:{
        textAlign:'right',
        position:'absolute',
        bottom:1,
        right:0,
    }
}));