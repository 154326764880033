import React from 'react';

const urlEmbed = props => {
    return (
        <div style={{ height: '100%', width: '100%' }} >
            <object data={"https://clearsight.hitachivantara-dev.com"} aria-label="Url" type="text/html" style={{ height: '100%', width: '100%' }} />
        </div>
    );
};

export default urlEmbed;
