import React, { useEffect } from "react";
import { toast } from "react-toastify";
import {
  HvGrid,
  HvBreadCrumb,
  HvTextArea,
  HvButton,
  HvBox,
} from "@hitachivantara/uikit-react-core";
import {
  MSG_ADD_CATEGORY_SUCCESS,
  MSG_UPDATE_CATEGORY_SUCCESS,
} from "constants/admin/masters/faq";
import { showBanner } from "redux/actions/bannerActions";
import { useDispatch } from "react-redux";
import { useForm } from "hooks/useForm";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import FAQService from "services/admin/masters/FAQService";

const faqServiceObj = new FAQService();

const CategoryForm = ({ history, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { params } = props.match;
  const categoryId = params?.categoryId;

  const initialFormData = {
    // used to initialize the data
    name: "",
    sort_order: 0,
  };
  const metaInfo = {
    breadcrumData: [
      {
        ariaLabel: "Home",
        label: "Home",
        path: "/labs/",
        to: "/",
      },
      {
        ariaLabel: "FAQ",
        label: "FAQ",
        path: "/admin/masters/faq",
        to: "/admin/masters/faq",
      },
      {
        ariaLabel: "FAQ Category",
        label: categoryId > 0 ? "Edit FAQ Category" : "Add FAQ Category",
        path: "",
        to: "",
      },
    ],
  };

  const {
    handleSubmit, // handles form submission
    handleChange, // handles input changes
    data, // access to the form data
    errors, // includes the errors to show
    populateForm, //to populate the values in case of edit
  } = useForm({
    // the hook we are going to create
    initialValues: initialFormData, //pass the initializing data
    validations: {
      // all our validation rules go here
      name: {
        required: {
          value: true,
          message: "FAQ category name is required",
        },
      },
    },
    onSubmit: () => {
      //trigger the actual post submit function from here
      submitForm(data);
    },
  });

  //loads the initial data when form is loading//
  useEffect(() => {
    if (categoryId > 0) {
      faqServiceObj
        .getDetailByCategoryId(categoryId)
        .then((response) => {
          initialFormData.name = response?.name || "";
          initialFormData.sort_order = response?.sort_order || 0;
          populateForm(initialFormData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, []);

  const submitForm = async (data) => {
    let response;
    try {


      if (categoryId > 0) {
        response = await faqServiceObj.updateFaqCategory(data, categoryId);
      } else {
        response = await faqServiceObj.createFaqCategory(data);
      }
    } catch (errors) {
      console.error('error', errors)
      return false;
    }
    if (response) {

      response.message || categoryId > 0
        ? dispatch(
          showBanner({
            payload: {
              showBanner: true,
              label: {
                message: response.message || MSG_UPDATE_CATEGORY_SUCCESS,
              },
              variant: "success",
            },
          })
        )
        : dispatch(
          showBanner({
            payload: {
              showBanner: true,
              label: {
                message: response.message || MSG_ADD_CATEGORY_SUCCESS,
              },
              variant: "success",
            },
          })
        );

      history.push(`/admin/masters/faq`);
    }
  };
  return (
    <HvGrid container spacing={1} className={classes.outerContainer}>
      <HvGrid item xs={10}>
        <HvBreadCrumb
          aria-label="Breadcrumb"
          listRoute={metaInfo.breadcrumData}
        />
      </HvGrid>
      <HvGrid item xs={12}>
        <div className={classes.contentRoot}>
          <form onSubmit={handleSubmit} noValidate>
            <HvTextArea
              fullWidth
              name="name"
              label="Name"
              //variant="outlined"
              placeholder="Name"
              classes={{ root: classes.customTextField }}
              InputProps={{
                classes: {
                  input: classes.inputField,
                  root: classes.base,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={data.name || ""}
              onChange={handleChange("name")}
            />

            <HvTextArea
              fullWidth
              name="sort_order"
              label="Order"
              // variant="outlined"
              placeholder="sort_order"
              type="number"
              classes={{ root: classes.customTextField }}
              InputProps={{
                classes: {
                  input: classes.inputField,
                  root: classes.base,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={data.sort_order || 0}
              onChange={handleChange("sort_order")}
            />
            <HvGrid item xs={12}>
              <HvBox
                className={classes.masterBtn}
                display="flex"
                justifyContent="right"
              >
                <HvButton
                  variant="secondary"
                  style={{ marginRight: 12 }}
                  component={Link}
                  to={`/admin/masters/faq`}
                >
                  Back
                </HvButton>
                <HvButton variant="primary" type="submit">
                  {categoryId > 0 ? "Update" : "Add"}
                </HvButton>
              </HvBox>
            </HvGrid>
          </form>
        </div>
      </HvGrid>
    </HvGrid>
  );
};

export default withRouter(CategoryForm);
