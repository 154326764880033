import NetworkService from "services/NetworkService";
import {
    METHOD_LIST,
    METHOD_CREATE,
    METHOD_UPDATE,
    METHOD_DELETE,
    METHOD_DETAIL,
    METHOD_ALL_VIDEO_CATS
} from "constants/admin/masters/videoCatalog";

class VideoCatalogService {
    getAll = (urlParams) => {
        return NetworkService
            .get(`${METHOD_LIST}?${urlParams}`)
            .then((response) => response.data);
    };
    getDetail = (catalogId) => {
        const updatedMethodName = METHOD_DETAIL.replace("{CATALOG_ID}", catalogId);
        return NetworkService
            .get(updatedMethodName)
            .then((response) => response.data);
    };
    create = (data) => {
        return NetworkService
            .post(METHOD_CREATE, data)
            .then((response) => response.data);
    };
    update = (data, catalogId) => {
        const updatedMethodName = METHOD_UPDATE.replace("{CATALOG_ID}", catalogId);
        return NetworkService
            .patch(updatedMethodName, data)
            .then((response) => response.data);
    };
    delete = (catalogId) => {
        const updatedMethodName = METHOD_DELETE.replace("{CATALOG_ID}", catalogId);
        return NetworkService
            .delete(updatedMethodName)
            .then((response) => response.data);
    };
    undoDelete = (catalogId) => {
        const updatedMethodName = METHOD_UPDATE.replace("{CATALOG_ID}", catalogId);
        return NetworkService
            .patch(updatedMethodName, { is_deleted: false })
            .then((response) => response.data);
    }; 
  getAllVideoCategory = () => {
    return NetworkService
      .get(METHOD_ALL_VIDEO_CATS)
      .then((response) => response.data);
  };
}
export default VideoCatalogService;
