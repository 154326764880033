import ROLES from "./roles";
import { Desktop, Doc, OpenBook, WorldGlobe, BarChart, LocationTarget, Settings, Chat, Toolset, Group, User, EditUser, Help, Report, LogOut } from "@hitachivantara/uikit-react-icons";

export const profileMenu = [
  {
    id: "00", label: "Configure Labs", icon: Desktop, requiredRoles: [ROLES.System_User, ROLES.Technical_Editor],
    data: [
      { id: "00-01", label: "Labs", icon: Desktop, url: "/admin/masters/labs", requiredRoles: [ROLES.System_User, ROLES.Technical_Editor] },
      // { id: "00-02", label: "Categories", icon: Group, url: "/admin/masters/lab/category", requiredRoles: [ROLES.System_User] }
    ]
  },
  { id: "01", label: "Users", icon: EditUser, url: "/admin/masters/users", requiredRoles: [ROLES.System_User], },
  {
    id: "02",
    label: "Admin Settings",
    icon: Settings,
    requiredRoles: [ROLES.System_User],
    data: [
      {
        id: "02-01",
        label: "Help",
        icon: Help,
        requiredRoles: [ROLES.System_User],
        data: [
          {
            id: "02-01-01", label: "FAQ", icon: Doc, url: "/admin/masters/faq",
            requiredRoles: [ROLES.System_User],
          },
          {
            id: "02-01-02", label: "Feedback", icon: Chat, url: "/admin/masters/feedback",
            requiredRoles: [ROLES.System_User],
          },
          // { id: "02-01-03", label: "Support", icon: Storage, url: "/admin/masters/support", requiredRoles: [ROLES.System_User], },
          {
            id: "02-01-04", label: "Banner", icon: Toolset
            , url: "/admin/masters/banner", requiredRoles: [ROLES.System_User],
          }
        ],
      },
    ],
  },
  {
    id: "03",
    label: "Reports",
    icon: Report,
    requiredRoles: [ROLES.System_User],
    data: [
      {
        id: "03-01",
        label: "User Groups",
        icon: Group,
        url: "/admin/masters/userGroups",
        requiredRoles: [ROLES.System_User],
      },
      {
        id: "03-02",
        label: "Top 10",
        icon: BarChart,
        url: "/admin/masters/top10",
        requiredRoles: [ROLES.System_User],
      },
      {
        id: "03-03",
        label: "Geography",
        icon: WorldGlobe,
        url: "/admin/masters/geography",
        requiredRoles: [ROLES.System_User],
      },
      {
        id: "03-04",
        label: "Region Reports",
        icon: LocationTarget,
        url: "/admin/masters/apac",
        requiredRoles: [ROLES.System_User],
      },

    ],
  },
  {
    id: "04",
    label: "User Guide",
    icon: OpenBook,
    url: "/UserGuide",
  },
  {
    id: "05",
    label: "FAQs",
    icon: Doc,
    url: "/FAQs",
  },
  {
    id: "06",
    label: "Log Off",
    icon: LogOut,
    url: "/logout",
  },
];
