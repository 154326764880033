import React, { useEffect, useState, useRef } from 'react';
import { HvGrid, HvTagsInput } from '@hitachivantara/uikit-react-core';
import Footer from 'components/footer/Footer';
import { transformDataCustomSelect } from 'utils/common';
import { useDispatch } from 'react-redux';
import { showLoader } from 'redux/actions/commonActions';
import LabMasterService from 'services/admin/masters/LabService';
import LabService from "services/LabService";
import { useStyles } from "../wizard/style";
import _ from "lodash";

const TagSteps = props => {
    const labServiceObject = new LabMasterService();
    const labServiceObj = new LabService();
    const { currentStep } = props
    const [labId] = useState(props?.labData?.id);

    const inputRefs = useRef({});
    const labTags = props?.labData && props?.labData?.labtags;
    const dispatch = useDispatch();
    var [tagsList, setTagList] = useState([]);
    var [allTagsList, setallTagList] = useState([]);
    const [selectedTaglist, setSelectedTaglist] = useState(labTags);
    useEffect(() => {
        if (props.mode === "Edit") {
            labServiceObject.getAllTagsList().then(response => {
                setTagList(transformDataCustomSelect(response))
                setallTagList(transformDataCustomSelect(response))
            }).catch(err => {
                console.log('error in tags data')
            })
        }

    }, []);
    const HvTagSuggestion = () => {
        selectedTaglist.map(selected => {
            tagsList = tagsList.filter(item => item.label !== selected);
        })
        const suggestions = tagsList.map(obj => {

            const { name, value, ...rest } = obj;

            return { value: name, ...rest };
        });
        return suggestions

    }
    const isTagExist = (tag) => {
        return allTagsList.some(item => item?.label === tag)
    }
    const createIdForDynamicTag = async (dynamicTag) => {
        const payload = {
            name: dynamicTag,
            tag_type_id: 1,
        }
        let result = await labServiceObject.createIdForDynamicTag(payload)
        if (result) {
            allTagsList.push({ label: result?.name, value: result?.id })
        }
    }
    const handletagSave = () => {
        var selectedObject = []
        selectedTaglist.map(eachTag => {
            selectedObject.push(allTagsList.filter(item => item?.label == eachTag)[0]?.value)
        })
        const payload = {
            tags: selectedObject

        }
        dispatch(showLoader(true));
        labServiceObj.editLabUrl(labId, payload).then(response => {
            if (response) {
                dispatch(showLoader(false));
                props.setNextStep(currentStep + 1);
            }
        }).catch(err => {
            console.log(err)
        })

    }

    return (
        <div>
            <form autoComplete='on' onSubmit={(event) => {
                event.preventDefault();
                handletagSave()
            }}>
                <HvGrid style={{ paddingBottom: "10%", paddingTop: '1%' }}>
                    <HvTagsInput
                        aria-label="Suggestions"
                        description="Note: Press Enter to create new Tag"
                        //label="Suggestions"
                        onAdd={(e, v) => {
                            setSelectedTaglist(prevState => [...prevState, v?.label]);
                            if (!isTagExist(v?.label)) {
                                createIdForDynamicTag(v?.label)
                            }
                        }}
                        onDelete={(e, v) => {
                            let label = typeof v === Object ? v?.label : v
                            setSelectedTaglist(selectedTaglist.filter(item => item !== label))

                        }}
                        placeholder="Enter value"
                        suggestionListCallback={HvTagSuggestion}
                        suggestionsLoose
                        value={selectedTaglist}

                    />


                </HvGrid>
                < Footer handleSumbit={(event) => { event?.preventDefault() }} />
            </form >
        </div >
    );
};

TagSteps.propTypes = {

};

export default TagSteps;