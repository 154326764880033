
import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    textField: {
        marginTop: '16px !important',
        "& input::placeholder": {
            fontSize: "14px"
        },
        "& .MuiInputBase-root.Mui-disabled": {
            background: '#ccc',
            opacity: .3
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: '#2a2c2e',
        }
    },
    inputField: {
        fontSize: 14,
        padding: '12px !important',
    },
    base: {
        '&$focused $notchedOutline': {
            borderColor: '#2a2c2e',
            border: '1px solid #2a2c2e',
        },
    },
    focused: {
    },
    notchedOutline: {
        border: "1px solid #2a2c2e !important",
    },
    inputLabel: {
        color: '#2a2c2e',
    },
    gridMargin: {
        marginTop: 6,
        "& .MuiFormLabel-root.Mui-focused": {
            color: '#2a2c2e',
        }
    },
    fileButton: {
        background: '#71757b',
        color: '#fff',
        width: 110,
        fontSize: 12,
        marginTop: 16,
        [theme.breakpoints.down("xs")]: {
            fontSize: 10,
            width: 90
        },
        " &:hover": {
            background: '#71757b',
        }
    },
    fileContainer: {
        overflow: 'auto',
        height: '248px',
        marginTop: 6,
        justifyContent:'center',
        display:'flex'
    },
    urlContainer: {
        overflow: 'auto',
        height: '248px',
        marginTop: 6
    },
    fileName: {
        textDecoration: 'underline',
        color: '#1874CD',
        cursor: 'pointer'
    },
    clearIcon: {
        marginLeft: 12,
        width: 20,
        height: 20,
        cursor: 'pointer',
        color: '#bd0912',
        border:'1px solid #bd0912',
        borderRadius:20,
    },
    rightPanel:{ 
        width: '12%', 
        display: 'inline-flex', 
        justifyContent: 'right',
        "@media(min-width:768px) and (max-width:1024px)": {
            width: '20%', 
        }
    },
    fileUploadButton:{
        width: '100%',
        marginTop:'6px',
        " & .material-icons":{
            marginRight:'6px',
        }
    },
    leftPanel:{ 
        width: '88%', 
        display: 'inline-flex', 
        "@media(min-width:768px) and (max-width:1024px)": {
            width: '80%', 
        }
    },
    select: {
        width: '100%',
        height : '100%'
    },
    selectLabel: {
        fontSize: 14,
        color: '#2a2c2e',
        fontWeight: 500,
        minWidth: 50,
        margin: 'auto 0',
        textAlign: 'center',
        alignSelf : "end",
        paddingTop : "20px"

    }
}));