export const API_URL = process.env.REACT_APP_API_URL;
export const GA_TAG_ID = process.env.REACT_APP_GA_TAG_ID;
export const NAVBAR_LOGO = "HALO";
export const APP_NAME = process.env.REACT_APP_TITLE;
//other utility constants for SessionStorage
export const LOGGED_IN_USER_INFO = "HALO-USER-INFO";
export const SHOW_FULLSCREEN = "SHOW_FULLSCREEN";
export const OKTA_TOKEN_INFO = "okta-token-storage";
export const SHOW_BANNER = "SHOW_BANNER";

// Lab Constants
export const RUN_LAB = "Run Lab";
export const SCHEDULE_LAB = "Schedule Lab";
export const VIRTUAL_HARDWARE = "Virtual Hardware";
export const PHYSICAL_HARDWARE = "Physical Hardware";

//API Method Constants
export const METHOD_ADMIN_SETINGS = "adminSettings/";
export const METHOD_ENCRYPT_PASSWORD = "encrypt/";
export const METHOD_BANNER = "banner/";

//reducer and action keys
export const SHOW_LOADER = "SHOW-LOADER";
export const SHOW_PROGRESS = "SHOW-PROGRESS";
export const ADMIN_SETTINGS = "ADMIN_SETTINGS";
export const SERVER_ERRORS = "SERVER_ERRORS";
export const CHANGE_THEME = "CHANGE_THEME";
export const AUTHENTICATE_OKTA_TOKEN = "AUTHENTICATE_OKTA_TOKEN";
export const CURRENT_PAGE = "CURRENT_PAGE";
export const CURRENT_PAGE_SIZE = "CURRENT_PAGE_SIZE";
export const LAB_ENVIRONMENT_ID = "LAB_ENVIRONMENT_ID";

//Date Format
export const DATE_FORMAT = "MM/DD/YYYY HH:mm:ss";

//messages
export const GENERAl_ERROR_ADMIN_SETTINGS_FORM =
  "There are some error(s) in form, please check all the fields.";
export const SUCCESS_MESSAGE_ADMIN_SETTINGS_FORM =
  "Admin Settings updated successfully.";
export const ERROR_MESSAGE_401 =
  "Your session has been expired, please login again.";
export const ERROR_MESSAGE_UNKNOWN =
  "Unknown error occured, please contact with administrator.";
export const NOTE_MAX_FILTER_SUGGESSTION =
  'Note: Please select the filters and click "View Results"';
export const ERROR_MESSAGE_MIN_FILTERS =
  "Please select minimum {COUNT} different filters to view the search result";
export const SUCCESS_MESSAGE_COCREATION_FORM =
  "Form Submitted Successfully.We will contact you within two business days.";
export const SUCCESS_MESSAGE_FEEDBACK_FORM = "Feedback Submitted Successfully.";
export const DOWNLOAD_AGREEMENT_MESSAGE =
  "Thank you for your interest in using the Hardware Labs. Accepting the Lab Access Agreement is mandatory before using the Hardware Labs, please contact us if you have any questions";
export const PREP_AGREEMENT_MESSAGE =
  "Thank you for your interest in using the PREP Labs. Accepting the Lab Access Agreement is mandatory before using the PREP Labs, please contact us if you have any questions";
export const AGREEMENT_MESSAGE =
  "Thank you for your interest in using the HALO. Accepting the Lab Access Agreement is mandatory before using the  Labs, please contact us if you have any questions ";
export const LAB_ERROR_MESSAGE =
  "Please wait while labs are loading. If you face any issue, Please contact  halo.support@hitachivantara.com";
export const HALO_SUPPORT_TEAM = "halo.support@hitachivantara.com";
export const PRODUCTION_MAINTENENCE_MESSAGE =
  "We're conducting scheduled maintenance for the HALO application to introduce a system update. This activity is set to occur on Wednesday, August 28th, 9:30 pm to 12:30 am PST (Thursday, August 29th, 10:00 am to 1:00 pm IST). Please note that the HALO application will be temporarily unavailable during this period.";

export const HOME_PAGE_TITLE1 =
  " Innovate and Elevate your Understanding of Hitachi Solutions";
//   "Practical learning through co-creation experience";
export const HOME_PAGE_TITLE2 = "Hitachi Automated Labs Online";
// "Discover Hitachi Solutions Anytime, Anywhere";
export const HOME_PAGE_TITLE3 =
  "Welcome to HALO, your gateway to hands-on experiences with the Hitachi Vantara product portfolio. Discover the full potential of our platform while working in a secure environment with complete autonomy. Our platform offers a seamless experience, allowing you to access labs from anywhere, at anytime.";
//   "Unlock full potential of our Labs with easy access to product information, virtual labs and physical hardware labs. Whether you're exploring new technologies, training, or sharpening your lab skills, our platform ensures a seamless and empowering experience.";
export const HOME_PAGE_TITLE4 = "Explore Labs-as-a-Service";
//"Evolution in Automation";
export const HOME_PAGE_TITLE5 = "Driving Innovative Solutions";
//  "Charting the journey of Transformation in Our Labs";
export const HOME_PAGE_TITLE6 = "User Growth Insights";
export const HOME_PAGE_TITLE7 = "Lab Instances Launched to Date";
//"Tracking the Expansion of our User Base";
export const HOME_PAGE_TITLE8 = (numLabs) =>
  `Since our relaunch, users have successfully launched over ${numLabs} lab instances. Each lab
is designed to offer practical insights and in-depth knowledge, ensuring that
users can explore and master the full capabilities of the Hitachi Vantara
product portfolio. Our range of labs covers everything from foundational
concepts to advanced applications, catering to a diverse audience with
varying levels of expertise.`;
export const HOME_PAGE_TITLE9 = "Lab Offerings";
//"Lab Categories";
export const HOME_PAGE_TITLE10 =
  "Explore the latest products in a controlled environment. Our labs are organized into three main categories.";
//   "Unlock the full potential of our labs with easy access to product information, virtual labs, and physical hardware labs.";
export const HOME_PAGE_TITLE11 = "Reach Out for More Information";
export const HOME_PAGE_TITLE12 = "Total Hours Spent in Labs to Date";
export const HOME_PAGE_TITLE13 = (numHours) =>
  `Our users have collectively spent over ${numHours} hours immersed in our labs,
reflecting the value and engagement our platform offers. This significant
amount of time underscores the comprehensive and interactive nature of our
labs, where users can delve deep into product functionalities, experiment with
real-world scenarios, and enhance their skills—leveraging our platform to stay
at the forefront of change.`;

//length constants
export const MIN_TEXT_LENGTH = 6;
export const MAX_TEXT_LENGTH = 100;

export const pageSizeOptions = [5, 10, 20, 50, 100];

export const MAX_CATEGORY_LIMIT = 100;

export const IDEL_TIMEOUT_MINUTS = process.env.IDEL_TIMEOUT_MINUTS
  ? process.env.IDEL_TIMEOUT_MINUTS
  : 2;
export const IDEL_GRACE_TIME_SECONDS = process.env.IDEL_GRACE_TIME_SECONDS
  ? process.env.IDEL_GRACE_TIME_SECONDS
  : 60;
