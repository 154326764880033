import { MAX_TEXT_LENGTH, MIN_TEXT_LENGTH } from "constants/common";

export const METHOD_LIST = 'lab-category/allcategory/';
export const METHOD_CREATE = 'lab-category/';
export const METHOD_UPDATE = 'lab-category/{CATEGORY_ID}/';
export const METHOD_DELETE = 'lab-category/{CATEGORY_ID}/';
export const METHOD_DETAIL = 'lab-category/{CATEGORY_ID}/';

//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;
export const EMAIL_MIN_LENGTH=6;

//language constants
export const MSG_WARNING_DELETE = 'Are you sure to delete this category?';
export const MSG_DELETE_SUCCESS = 'Deleted Successfully';
export const MSG_ADD_SUCCESS = 'Created Successfully';
export const MSG_UPDATE_SUCCESS = 'Updated Successfully';
export const MSG_UNDO_RECORD = 'Restored Successfully'; 
export const MSG_WARNING_UNDO_DELETE = 'Are you sure to undo this deleted record?';