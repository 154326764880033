import NetworkService from "services/NetworkService";
import {
    METHOD_LIST,
} from "constants/admin/masters/feedback";

class FeedbackService {
    getAllFeedbackList = (urlParams) => {
        return NetworkService
            .get(`${METHOD_LIST}?${urlParams}`)
            .then((response) => response.data);
    };
    getDetailByFeedbackId = (feedbackId) => {
        return NetworkService
            .get(`${METHOD_LIST}${feedbackId}/`)
            .then((response) => response.data);
    }
    markFeedbackAsRead = (data, feedbackId) => {
        return NetworkService
            .patch(`${METHOD_LIST}${feedbackId}/`, data)
            .then((response) => response.data);
    }
    submitFeedbackDetails = (payload) => {
        return NetworkService
        .post(`${METHOD_LIST}`, payload)
        .then((response) => response.data);
    }

}
export default FeedbackService;
