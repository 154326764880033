import { SHOW_LOADER, SHOW_FULLSCREEN, AUTHENTICATE_OKTA_TOKEN, CURRENT_PAGE, CURRENT_PAGE_SIZE, LAB_ENVIRONMENT_ID } from "constants/common";

export const showLoader = (payload) => {
    return {
        type: SHOW_LOADER,
        payload
    };
};

export const showFullScreen = (payload)=>{
    return{
        type : SHOW_FULLSCREEN,
        payload
    }
}

export const authenticateOktaToken = (payload)=>{
    return{
        type: AUTHENTICATE_OKTA_TOKEN,
        payload
    }
}
export const currentPage = (payload)=>{
    return{
        type :CURRENT_PAGE,
        payload
    }
}
export const currentPageSize = (payload)=>{
    return{
        type : CURRENT_PAGE_SIZE,
        payload
    }
}
export const labEnvironmentId = (payload)=>{
    return{
        type : LAB_ENVIRONMENT_ID,
        payload
    }
}