import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    margin: {
        marginTop: 6,
    },
    label: {
        fontSize: 14,
        color:'#000',
        fontWeight:500,
    },
    radioBtn:{
        display:'block',
        " & .MuiFormControlLabel-root":{
            " & .MuiFormControlLabel-label":{
                fontSize:14,
            },
            " & .MuiButtonBase-root":{
                color:'#bd0912',
                " & .MuiIconButton-label":{
                    " & .MuiSvgIcon-root":{
                        width:20,
                        height:20,
                    }
                }
            }
        }
    }
}));