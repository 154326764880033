import React, { useEffect, useState, useMemo, Fragment } from 'react';
import {
    HvTable, hvTextColumn, HvPagination,
    HvTableBody,
    HvTableCell,
    HvTableContainer,
    HvTableHead,
    HvTableHeader,
    HvTableRow, HvEmptyState, useHvData, useHvPagination, theme
} from "@hitachivantara/uikit-react-core";
import { Ban } from '@hitachivantara/uikit-react-icons';
import LabService from "services/LabService";
import { useStyles } from "../wizard/style";
import _ from "lodash";
const CleanupSubTable = props => {

    const classes = useStyles();
    const [labId] = useState(props?.labData?.id);
    const [rowId] = useState(props?.rowId);
    const [columns, setColumns] = useState([]);
    const [allData, setAllData] = useState(props?.allData[0]?.test_result_response)
    const [totalrecords, setTotalRecords] = useState();
    const [disabled] = useState(false);
    const [testId, setTestId] = useState('');
    const [email, setEmail] = useState('');
    const [testName, setTestName] = useState('');
    const [data, setData] = useState(null);
    const [subdata, setSubData] = useState();

    const labServiceObj = useMemo(() => new LabService(), []);
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const tableKeys = ['Test Suite', 'Test Case', 'Test', 'Start Time', 'End Time', 'Status']

    const { getTableProps, getTableBodyProps, prepareRow, headerGroups, rows, state: { pageSize, pageIndex }, getHvPaginationProps } =
        useHvData(
            {
                columns,
                data,
                manualPagination: true,
                pageCount: totalrecords
            },
            useHvPagination
        );

    useEffect(() => {

        if (props.allData && props.allData[0]?.test_result_response) {

            const startIndex = pageSize * pageIndex;
            const endIndex = startIndex + pageSize;
            // const pageData = data.slice(startIndex, endIndex);
            if (Array.isArray(props.allData[0]?.test_result_response)) {

                setData(props.allData[0].test_result_response.slice(startIndex, endIndex));
                setTotalRecords(Math.ceil(props.allData[0].test_result_response.length / pageSize));
            }
        }
        setColumns([
            [
                hvTextColumn({
                    Header: "Suite",
                    accessor: "Test Suite",
                    cellType: "alpha-numeric",
                }),
                hvTextColumn({
                    Header: "Group",
                    accessor: "Test Case",
                    cellType: "alpha-numeric",
                }),
                hvTextColumn({
                    Header: "Task",
                    accessor: "Test",
                    cellType: "alpha-numeric",
                }),
                hvTextColumn({
                    Header: "Start Time",
                    Cell: (cellData) => {
                        if (!_.isEmpty(cellData)) {
                            return new Date(cellData.row.original['Start Time']).toUTCString()
                        }
                    },
                    accessor: "Start Time",
                    cellType: "alpha-numeric",
                }),
                hvTextColumn({
                    Header: "End Time",
                    Cell: (cellData) => {
                        if (!_.isEmpty(cellData)) {
                            return new Date(cellData.row.original['Start Time']).toUTCString()
                        }
                    },
                    accessor: "End Time",
                    cellType: "alpha-numeric",
                }),
                hvTextColumn({
                    Header: "Status",
                    Cell: (cellData) => {
                        if (!_.isEmpty(cellData)) {
                            const statusColor = cellData.row.original.Status === 'PASS' ? 'green' : 'red';
                            return (
                                <span style={{ color: statusColor }}>{cellData.row.original.Status}</span>
                            );
                        }
                    },
                    accessor: "Status",
                    cellType: "alpha-numeric",
                }),
            ]
        ][0]);
    }, [props?.allData, pageIndex, pageSize])


    const EmptyRow = () => (
        <HvTableRow>
            <HvTableCell colSpan={100} style={{ height: 50 }}>
                <HvEmptyState message="No data to display" icon={<Ban role="presentation" />} />
            </HvTableCell>
        </HvTableRow>
    );

    return (
        <div>
            <HvTableContainer>
                <HvTable {...getTableProps()}>
                    <HvTableHead>
                        {headerGroups.map((headerGroup) => (
                            <HvTableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((col) => (
                                    <HvTableHeader {...col.getHeaderProps()}>
                                        {col.render("Header")}
                                    </HvTableHeader>
                                ))}
                            </HvTableRow>
                        ))}
                    </HvTableHead>
                    <HvTableBody {...getTableBodyProps()}>
                        {
                            rows.length === 0 ? <EmptyRow /> :
                                rows.map((row) => {

                                    prepareRow(row);

                                    return (
                                        <Fragment key={row.id}>
                                            <HvTableRow {...row.getRowProps()}>
                                                {row.cells.map((cell) => (
                                                    <HvTableCell {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </HvTableCell>
                                                ))}
                                            </HvTableRow>

                                        </Fragment>
                                    );
                                })}
                    </HvTableBody>
                </HvTable>
            </HvTableContainer>
            <HvPagination
                {...getHvPaginationProps()} />
        </div >

    );
};

CleanupSubTable.propTypes = {

};

export default CleanupSubTable;