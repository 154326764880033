import { 
    LAB_INFO,
  } from "constants/login";
  
  const initialState = {
    labInfo:{}
  };
  const labReducer = (state = initialState, action) => {
    switch (action.type) {
      case LAB_INFO:
        return {
          ...state,
          labInfo: action.payload,
        };
      default:
        return state;
    }
  };
  export default labReducer;
  