import _ from "lodash";
import { useState } from "react";

export const useForm = (options) => {
    const [data, setData] = useState((options?.initialValues || {}));
    const [errors, setErrors] = useState({});



    const handleChange = (key) => (e) => {
        const targetValue = e.target.value;
        setData({
            ...data,
            [key]: targetValue,
        });
        const response = validateField(key, targetValue);
        setErrors({
            ...errors,
            [key]: response.error
        });

    };

    const handleRichText = (key) => (e) => {
        const targetValue = e.target.innerHTML;
        setData({
            ...data,
            [key]: targetValue,
        });
        const response = validateField(key, targetValue);
        setErrors({
            ...errors,
            [key]: response.error
        });
    }

    const handleChangeCustom = (key, targetValue) => {
        setData({
            ...data,
            [key]: targetValue,
        });
        const response = validateField(key, targetValue);
        setErrors({
            ...errors,
            [key]: response.error
        });

    };
    const handleMultipleChange = (payload) => {
        const dataCopy=JSON.parse(JSON.stringify(data)) //deep cloned
        const errorsCopy=JSON.parse(JSON.stringify(errors)) //deep cloned
        payload.forEach(({ key, targetValue }) => {
            dataCopy[key] = targetValue;
            const response = validateField(key, targetValue);
            errorsCopy[key] = response.error;
        })
        setData(dataCopy);
        setErrors(errorsCopy);
    };

    const validateField = (key, targetValue = null) => {
        let response = { valid: true, error: '' };
        const validations = options?.validations;
        const value = targetValue === null ? data[key] : targetValue;
        const validation = validations && validations[key];
        if (validation?.required?.value && !value) {
            response.valid = false;
            response.error = validation?.required?.message;
        }

        if (response.valid === true) {
            //in case of required field is array, then blank array[] is always truthy value
            if (validation?.required?.value && _.isArray(value) && _.isEmpty(value)) {
                response.valid = false;
                response.error = validation?.required?.message;
            }
        }

        if (response.valid === true) {
            const pattern = validation?.pattern;
            if (pattern?.value && !RegExp(pattern.value).test(value)) {
                response.valid = false;
                response.error = pattern.message;
            }
        }

        if (response.valid === true) {
            const custom = validation?.custom;
            if (custom?.isValid && !custom.isValid(value)) {
                response.valid = false;
                response.error = custom.message;
            }
            if (custom?.isConfirmed && !custom.isConfirmed(value)) {
                response.valid = false;
                response.error = custom.message;
            }
        }

        return response;
    }

    const handleSubmit = (e) => {
        e?.preventDefault();
        const validations = options?.validations;
        if (validations) {
            let errorsCount = 0;
            const newErrors = {};
            for (const key in validations) {
                const response = validateField(key);
                if (!response.valid) {
                    errorsCount++;
                }
                newErrors[key] = response.error;
            }
            if (errorsCount > 0) {
                setErrors(newErrors);
                return;
            }
        }

        setErrors({});

        if (options?.onSubmit) {
            options.onSubmit();
        }
    }

    const resetForm = () => {
        setData(options?.initialValues);
    };
    const populateForm = (newData) => {
        setData({
            ...newData
        });
    };

    return {
        data,
        handleChange,
        handleRichText,
        handleChangeCustom,
        handleMultipleChange,
        populateForm,
        resetForm,
        errors,
        handleSubmit
    };
};