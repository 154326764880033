import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#fff',
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '6px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            backgroundColor: '#fff',
        },
    },
}))(InputBase);

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        marginTop:'24px',
        "& .MuiAppBar-colorPrimary": {
            backgroundColor: "#e1e1e3",
            boxShadow: 'none',
            borderBottom: '1px solid #c2c2c2',
        },
        "& .MuiTab-root": {
            padding: '10px 24px 0px 24px'
        },
    },
    headerContainer:{
        display:'flex',
        justifyContent:'space-between',
    },
    custom: {
        color: "#000",
        fontWeight: "bold",
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    description: {
        fontSize: '12px',
        marginTop:'12px',
    },
    select:{
        minWidth:210,
    },
    collapseContainer:{
        display:'flex',
        padding:'0px !important',
        height:'26px',
        overflow:'hidden',
        borderTop:'1px solid #2a2c2e',
        "@media(max-width:920px)":{
            height:'auto',
            overflow:'auto',
        }
    },
    menuItem:{
        fontSize:12,
    },
    expandContainer:{
        display:'flex',
        padding:'0px !important',
        borderTop:'1px solid #2a2c2e',
    },
    linkcontainer: {
        display: 'flex',
        flexDirection:'column',
    },
    container: {
        backgroundColor: '#fff',
        boxShadow: '1px 2px 5px 1px #ccc',
        margin: '0px 0px 16px 0px',
        borderRadius: '4px',
        display: 'flex',
        minHeight:96,
    },
    readMore:{
        fontSize: '12px',
        paddingBottom: '6px',
        color:'#1874CD',
        cursor:'pointer',
        textDecoration:"underline",
        display:'inline-block',
        justifyContent:'flex-end'
    },
    descriptionBlock:{
         width:'65%',
         padding:'8px 12px 4px 8px',
         fontSize:12,
        '@media(min-width:766px) and (max-width:958px)':{
            width:'60%',
        },
    },
    readOrHide:{
        color:'#1874CD',
        cursor:'pointer',
        textDecoration:'underline',
        marginLeft:4,
    }
}));

