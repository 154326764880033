import React, { useEffect } from "react";
import { toast } from "react-toastify";
import VideoCategoryService from "services/admin/masters/VideoCategoryService";
import Breadcrumb from "components/Breadcrumb";
import { Box, Grid, TextField, Button, Typography } from "@material-ui/core";
import { getMasterBaseUrl } from "utils/masterHelpers";
import { useForm } from "hooks/useForm";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import { MSG_ADD_SUCCESS, MSG_UPDATE_SUCCESS } from "constants/admin/masters/tools";
import Radio from 'components/radioButton';

const groupItems = [
    {
        name: "true",
        label: "Yes",
        disabled: false
    },
    {
        name: "false",
        label: "No",
        disabled: false
    },
];

const checkedDefault = 'false';

const videoCategoryServiceObj = new VideoCategoryService();
const Form = ({ history, ...props }) => {
    const classes = useStyles();
    const { url, params } = props.match;
    const categoryId = params?.categoryId;
    const masterUrl = getMasterBaseUrl(url, params);
    const initialFormData = { // used to initialize the data
        name: '',
        video_category: [],
        is_deleted: 'false'
    };
    const metaInfo = {
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "Video Category List", link: `${masterUrl}` },
            { title: (categoryId > 0) ? "Edit Video Category" : "Add Video Category" },
        ]
    };

    const handleRadioChange = (value) => {
        handleChangeCustom('is_deleted', value);
    }
    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        handleChangeCustom,
        data, // access to the form data
        errors, // includes the errors to show
        populateForm, //to populate the values in case of edit
    } = useForm({ // the hook we are going to create
        initialValues: initialFormData,//pass the initializing data 
        validations: { // all our validation rules go here
            name: {
                required: {
                    value: true,
                    message: 'Video Category Name is required',
                }
            },
        },
        onSubmit: () => {
            //trigger the actual post submit function from here 
            submitForm(data)
        }
    });

    //loads the initial data when form is loading//
    useEffect(() => {
        if (categoryId > 0) {
            videoCategoryServiceObj.getDetail(categoryId).then(response => {
                initialFormData.name = response?.name || '';
                initialFormData.is_deleted = response?.is_deleted || 'false';
                populateForm(initialFormData);
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line
    }, [])

    const submitForm = async (data) => {
        let response;
        if (categoryId > 0) {
            response = await videoCategoryServiceObj.update(data, categoryId);
        } else {
            response = await videoCategoryServiceObj.create(data);
        }
        if (response) {
            toast.success(response.message || (categoryId > 0) ? MSG_UPDATE_SUCCESS : MSG_ADD_SUCCESS);
            history.push(`${masterUrl}`);
        }

    }
    return (
        <Grid container spacing={1} className={classes.outerContainer}>
            <Grid item xs={10}>
                <Breadcrumb data={metaInfo.breadcrumData} />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.contentRoot}>
                    <form onSubmit={handleSubmit} noValidate>
                        <Typography className={classes.inputLabel}>Video Category</Typography>
                        <TextField fullWidth
                            name="name"
                            variant="outlined"
                            placeholder="Video Category"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}

                            value={data.name || ''}
                            onChange={handleChange('name')}
                            error={(errors['name'] && errors['name'] !== undefined) ? true : false}
                            helperText={(errors['name'] && errors['name'] !== undefined) ? errors['name'] : null}
                        />

                        <Radio
                            groupItems={groupItems}
                            checkedDefault={checkedDefault}
                            group='Is Deleted'
                            onChange={handleRadioChange}
                        />

                        <Grid item xs={12}>
                            <Box className={classes.masterBtn} display="flex" justifyContent='right'>
                                <Button className='back-btn' component={Link} to={`${masterUrl}`} >
                                    Back
                                </Button>
                                <Button className='add-btn' type="submit" >
                                    {(categoryId > 0) ? 'Update' : 'Add'}
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(Form);