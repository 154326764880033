import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { useStyles } from 'utils/theme';
export default function Breadcrumb({ data }) {
    const classes = useStyles();
    return (
        <Breadcrumbs aria-label="breadcrumb">
                {
                    data && data.map((item, index) =>
                        (item?.link) ? (
                            <Link key={index} color="inherit" to={item.link} className={classes.brandLink}>
                                {item?.title}
                            </Link>
                        ) : (
                            <Typography key={index} color="textPrimary" className={classes.link}>
                                {item?.title}
                            </Typography>
                        )
                    )
                }
        </Breadcrumbs>
    );
}