import { css } from "@emotion/css";


export const classes = {
  skytapScreen: css({
    '& .HvContainer-root': {
      padding: 0
    }
  }),
  closeButton: css({
    display: "none"
  }),
  dragger: css({
    width: "15px",
    backgroundColor: "#f4f7f9",
    cursor: "ew-resize",
    padding: "4px 0 0",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 1000
  }),
  drawerContent: css({
    // maximize space for content
    flex: 1,
    overflow: "auto",
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#CCCED0 0% 0% no-repeat padding-box',
      borderRadius: '2px',
    }
  }),
  tabs: css({
    borderBottom: '2px solid #E8E8E8',
    margin: '10px 20px 0px 20px',
    '& .HvTab-root::after': {
      backgroundColor: 'unset'
    }
  }),
  drawerStyle: css({
    '& .HvDrawer-closeButton': {
      display: "none"
    },
    '& .HvDrawer-paper': {
      zIndex: '4',
      background: 'rgba(69,69,69,0.9)',
      borderBottom: '1px solid rgba(255,255,255,0.1)',
      borderBottomLeftRadius: '0.5em',
      borderBottomRightRadius: '0.5em',
      color: '#fff',
      height: '36px',
      left: '50%',
      marginLeft: '-21.75rem',
      position: 'absolute',
      top: '0',
      width: '696px',
      overflow: "hidden"
    },
    '&.MuiPaper-root': {
      height: "auto",
      background: "rgba(69,69,69,0.9) !important",
      position: 'fixed',
      right: '0',
      top: '30%',
      width: '70px',
      border: '1px solid rgba(255,255,255,0.1)',
      borderRadius: '0.5em',
      borderRadius: '0.5em',
      // transform: translateY(-50%)
    },
    '& .MuiDivider-root': {
      borderColor: 'rgba(255,255,255,0.1)'
    }
  }),

  stackSplitView: css({
    '&.HvStack-root': {
      width: 'auto',
      alignItems: 'center',
      paddingTop: '5px',
      zIndex: '1200',
      position: 'absolute',
      top: '30%',
      background: 'rgba(69,69,69,0.9) no-repeat !important',
      left: '92%',
      border: '1px solid rgba(255,255,255,0.1)',
      borderRadius: '0.5em',
      borderRadius: '0.5em',
      zIndex: 1200
    }
  }),
  splitViewDrawer: css({
    '& .HvDrawer-closeButton': {
      display: "none"
    },
    '& .HvDrawer-paper': {
      zIndex: '4',
      background: 'rgba(69,69,69,0.9)',
      borderBottom: '1px solid rgba(255,255,255,0.1)',
      borderBottomLeftRadius: '0.5em',
      borderBottomRightRadius: '0.5em',
      color: '#fff',
      height: '36px',
      left: '50%',
      marginLeft: '-21.75rem',
      position: 'absolute',
      top: '0',
      width: '696px',
      overflow: "hidden"
    },
    '&.MuiPaper-root': {
      height: "auto",
      background: "rgba(69,69,69,0.9) !important",
      position: 'absolute',
      right: '0',
      top: '30%',
      width: '70px',
      border: '1px solid rgba(255,255,255,0.1)',
      borderRadius: '0.5em',
      borderRadius: '0.5em',
    },
    '& .MuiDivider-root': {
      borderColor: 'rgba(255,255,255,0.1)'
    }
  }),
  iconButton: css({
    color: 'var(--uikit-colors-atmo1)',
    cursor: 'pointer',
    WebkitAlignSelf: 'center',
    MsFlexItemAlign: 'center',
    alignSelf: 'center',
    fontSize: '25px !important',
    '--size': '15px !important'
  }),
  drawerButton: css({
    color: 'var(--uikit-colors-atmo1)',
    border: '1px solid',
    borderColor: 'rgba(255,255,255,0.1)',
    cursor: 'pointer',
    backgroundColor: 'rgba(69,69,69,0.9)',
    alignSelf: "center"
  }),
  pane2DrawerButton: css({
    color: 'var(--uikit-colors-atmo1)',
    border: '1px solid',
    borderColor: 'rgba(255,255,255,0.1)',
    cursor: 'pointer',
    backgroundColor: 'rgba(69,69,69,0.9)',
    position: "absolute",
    left: "34%"
  }),
  pane2Drawer: css({
    '& .HvDrawer-closeButton': {
      display: "none"
    },
    '& .HvDrawer-paper': {
      zIndex: '4',
      background: 'rgba(69,69,69,0.9)',
      borderBottom: '1px solid rgba(255,255,255,0.1)',
      borderBottomLeftRadius: '0.5em',
      borderBottomRightRadius: '0.5em',
      color: '#fff',
      height: '36px',
      left: '50%',
      marginLeft: '-21.75rem',
      position: 'absolute',
      top: '0',
      width: 'fit-content',
      overflow: "hidden"
    },
    '&.MuiPaper-root': {
      height: "auto",
      background: "rgba(69,69,69,0.9) !important",
      position: 'absolute',
      right: '0',
      top: '0',
      left: "22%",
      width: 'fit-content',
      border: '1px solid rgba(255,255,255,0.1)',
      borderRadius: '0.5em',
      borderRadius: '0.5em',
    },
    '& .MuiDivider-root': {
      borderColor: 'rgba(255,255,255,0.1)'
    }
  }),
  pane2iconButton: css({
    color: 'var(--uikit-colors-atmo1)',
    cursor: 'pointer',
    WebkitAlignSelf: 'center',
    MsFlexItemAlign: 'center',
    alignSelf: 'center',
    fontSize: '15px !important',
    '--size': '15px !important'
  }),
  awsAndsaasDrawser: css({
    height: '184px',
    background: 'rgba(69,69,69,0.9)!important',
    position: 'fixed',
    right: '0',
    top: '30%',
    width: '70px',
    border: '1px solid rgba(255,255,255,0.1)',
    borderRadius: '0.5em',
    '& .HvDrawer-closeButton': {
      display: "none"
    },
  })

}


