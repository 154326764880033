import { MAX_TEXT_LENGTH, MIN_TEXT_LENGTH } from "constants/common";

export const METHOD_LIST = 'group/';
export const METHOD_CREATE = 'group/';
export const METHOD_UPDATE = 'group/{GROUP_ID}/';
export const METHOD_DELETE = 'group/{GROUP_ID}/';
export const METHOD_DETAIL = 'group/{GROUP_ID}/';
export const METHOD_ALL_USERS = 'user/drop/';


//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = 'Are you sure to delete this group?';
export const MSG_DELETE_SUCCESS = 'Deleted Successfully';
export const MSG_ADD_SUCCESS = 'Created Successfully';
export const MSG_UPDATE_SUCCESS = 'Updated Successfully';
export const MSG_UNDO_RECORD = 'Group Restored Successfully'; 
export const MSG_WARNING_UNDO_DELETE = 'Are you sure to undo this deleted group?';