import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import _ from "lodash";
import { OktaAuth } from "@okta/okta-auth-js";
import { config } from "config";

const oktaAuth = new OktaAuth(config.oidc);
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '50vh',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  title: {
    marginTop: 10,
    color: '#bd0912',
    fontSize: '18px',
    fontWeight: '600'
  },
  mt: {
    marginTop: 10,
  }
})
const PageNotFound = ({ history, ...props }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>

      <Typography className={classes.mt}>
        <span className={classes.title} >
          {props?.title || "Error 404"}
        </span> | {props?.description ||
          "Page not found error"}
      </Typography>

      <Button
        variant="contained"
        color="default"
        size="large"
        className={classes.mt}
        startIcon={<Icon>home</Icon>}
        onClick={() => {
          if (oktaAuth.authStateManager.getAuthState()?.isAuthenticated) {
            history.push("/")
          } else {
            history.push('/labs')
          }

        }}
      >
        Return to Home Page
      </Button>

    </Box>
  );
}
export default withRouter(PageNotFound);
