import { HvButton, HvTypography } from "@hitachivantara/uikit-react-core";
import { Box, Container } from "@material-ui/core";
import { useForm } from "hooks/useForm";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import LoginService from "services/LoginService";
import { classes } from "./style";
import { showBanner } from "redux/actions/bannerActions";

const loginServiceObj = new LoginService();

const ResetPassword = () => {
  // const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const token = location && location.search && location.search.substring(7);
  
  const {
    handleSubmit, // handles form submission
    handleChange, // handles input changes
    data, // access to the form data
    errors, // includes the errors to show
  } = useForm({
    // the hook we are going to create
    initialValues: {
      // used to initialize the data
      password: '',
      confirm_password: ''
    },
    validations: {
      // all our validation rules go here
      password: {
        required: {
          value: true,
          message: "Password is required",
        },
        pattern:{
          value : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{}[\]:;"'<>,.?/]).{8,15}$/,
          message: "Password length should be  8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",
        }
      },
      confirm_password: {
        required: {
          value: true,
          message: "Password is required",
        },
        custom: {
          isConfirmed: (value) => value === data.password,
          message: "Password did not match.",
        },
      },
    },
    onSubmit: () => {
      handleResetPassword();
    },
  });

  const handleResetPassword = () => {
    const payload = {
      password: data.password,
      confirm_password: data.confirm_password,
      token: token
    };
    loginServiceObj
      .resetPassword(payload)
      .then((response) => {
        if (response) {
          dispatch(showBanner( {
            showBanner: true,
            label: { message: response.message },
            variant: "success",
          }));
         
        }
      })
      .catch(() => {});
  };

  const redirectToLogin = () => history.push("/email-login");

  return (
    <div style={{ display: "flex" }}>
      <Container component="main" maxWidth="xs" style={{ display: "contents" }}>
        <img
          src="/assets/images/login.png"
          alt="Login"
          className={classes.responsive}
        />
        <div className={classes.formContainer}>
          <HvTypography variant="mTitle" className={classes.title}>
            Reset Password
          </HvTypography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <Box>
              <label htmlFor="password" className={classes.label}>
                Password
              </label>
              <input
                type="password"
                className={classes.input}
                
                onChange={handleChange("password")}
                placeholder = "Enter Text"
              />
              {errors?.password ? (
                <HvTypography
                  variant="selectedNavText"
                  className={classes.errormsg}
                >
                  {errors?.password}
                </HvTypography>
              ) : null}
            </Box>
            <Box>
              <label htmlFor="confirmpassword" className={classes.label}>
                Confirm password
              </label>
              <input
                type="password"
                className={classes.input}
               
                onChange={handleChange("confirm_password")}
                placeholder = "Enter Text"
              />
              {errors?.confirm_password ? (
                <HvTypography
                  variant="selectedNavText"
                  className={classes.errormsg}
                >
                  {errors?.confirm_password}
                </HvTypography>
              ) : null}
            </Box>
            <Box className={classes.loginButtonContainer}>
              <HvTypography
                variant="highlightText"
                className={classes.or}
                onClick={redirectToLogin}
              >
                Back to Login
              </HvTypography>
              <HvButton
                type="submit"
                category="primary"
                className={classes.loginButton}
                //   className={clsx(classes.submit, classes.sentenceCase)}
              >
                Submit
              </HvButton>
            </Box>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
