import NetworkService from "services/NetworkService";
import {
    METHOD_LIST,
    METHOD_CREATE,
    METHOD_UPDATE,
    METHOD_DELETE,
    METHOD_DETAIL,
} from "constants/admin/masters/videoCategory";

class VideoCategoryService {
    getAll = (urlParams) => {
        return NetworkService
            .get(`${METHOD_LIST}?${urlParams}`)
            .then((response) => response.data);
    };
    getDetail = (categoryId) => {
        const updatedMethodName = METHOD_DETAIL.replace("{CATEGORY_ID}", categoryId);
        return NetworkService
            .get(updatedMethodName)
            .then((response) => response.data);
    };
    create = (data) => {
        return NetworkService
            .post(METHOD_CREATE, data)
            .then((response) => response.data);
    };
    update = (data, categoryId) => {
        const updatedMethodName = METHOD_UPDATE.replace("{CATEGORY_ID}", categoryId);
        return NetworkService
            .patch(updatedMethodName, data)
            .then((response) => response.data);
    };
    delete = (categoryId) => {
        const updatedMethodName = METHOD_DELETE.replace("{CATEGORY_ID}", categoryId);
        return NetworkService
            .delete(updatedMethodName)
            .then((response) => response.data);
    };
    undoDelete = (categoryId) => {
        const updatedMethodName = METHOD_UPDATE.replace("{CATEGORY_ID}", categoryId);
        return NetworkService
            .patch(updatedMethodName, { is_deleted: false })
            .then((response) => response.data);
    };
}
export default VideoCategoryService;
