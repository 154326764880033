import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb";
import { Box, Button, Grid } from "@material-ui/core";
import { useStyles } from "utils/theme";
import SupportService from "services/admin/masters/SupportService";
import { MSG_DELETE_QUESTION_SUCCESS,MSG_WARNING_DELETE_QUESTION } from "constants/admin/masters/support";
import { toast } from "react-toastify";
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Delete';
import { buildQueryParams } from "utils/common";


const supportServiceObj = new SupportService();

const List = ({ history, ...props }) => {
    const classes = useStyles();
    // eslint-disable-next-line
    const [supportData, setSupportData] = useState([]);
    const { url: masterUrl } = props.match;
    const metaInfo = {
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "Support", },
        ]
    };

    useEffect(() => {
         getAllSupportList();
    }, []);

    const getAllSupportList = () => {
        let urlParams = { is_deleted: false }
        supportServiceObj.getAllSupportList(buildQueryParams(urlParams)).then(response => {
            if (response) {
                setSupportData(response.results);
            }
        }).catch(error => {
            console.log(error);
        })
    }

    const deleteSupportQuestion = (questionId) => {
        if (window.confirm(MSG_WARNING_DELETE_QUESTION)) {
        supportServiceObj.deleteSupportQuestion(questionId).then(response => {
            getAllSupportList();
            toast.success(response.message || MSG_DELETE_QUESTION_SUCCESS);
        }).catch(error => {
            console.log(error);
        })
     }
    }

    const editSupportQuestion = (questionId) => {
        history.push(`${masterUrl}/edit/question/${questionId}`);
    }

    return (
        <>
            <Grid container spacing={1} className={classes.outerContainer}>
                <Grid item xs={10}>
                    <Breadcrumb data={metaInfo.breadcrumData} />
                </Grid>
                <Grid style={{ padding: 0 }} item xs={2}>
                    <Box className={classes.masterBtn} flexDirection="row" textAlign={'right'} >
                        <Button style={{ marginRight: 12 }} component={Link} to={`${masterUrl}/add/question`}>
                            Add Question
                        </Button>
                    </Box>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 12 }}>
                    <div className={classes.contentGrid}>
                        {supportData.map(item => {
                            return (
                                <Box className={classes.supportQuestion}>
                                    <div className='question-block'>{item.question}</div>
                                    <EditIcon className={classes.cursor} onClick={() => editSupportQuestion(item.id) }/>
                                    <RemoveIcon className={classes.cursor} onClick={() => deleteSupportQuestion(item.id)}/>
                                </Box>
                            )
                        })}
                    </div>
                </Grid>
            </Grid>

        </>
    );
}

export default List;