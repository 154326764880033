import NetworkService from "services/NetworkService";
import { METHOD_TAGS_LIST, METHOD_GROUP_WITH_USERS_LIST, LAB_USER_PERMISSION, LAB_GROUP_PERMISSION, RESOURCE_USER_PERMISSION, RESOURCE_GROUP_PERMISSION } from "constants/admin/masters/labs";
import { UPLOAD_RESOURCE_URL } from "constants/fileUpload";

class LabMasterService {
  getAllTagsList = async () => {
    const response = await NetworkService
      .get(METHOD_TAGS_LIST);
    return response.data;
  };
  getAllGroupsWithUsers = async () => {
    const response = await NetworkService
      .get(METHOD_GROUP_WITH_USERS_LIST);
    return response.data;
  };
  createIdForDynamicTag = async (payload) => {
    const response = await NetworkService
      .post(METHOD_TAGS_LIST, payload);
    return response.data;
  }
  createLabUserPermission = async (payload) => {
    const response = await NetworkService
      .post(LAB_USER_PERMISSION, payload);
    return response.data;
  }
  
  updateLabUserPermission = async (payload, permissionId) => {
      const response = await NetworkService
      .patch(`${LAB_USER_PERMISSION}${permissionId}/`, payload);
    return response.data;
  }

  deleteLabUserPermission = async (permissionId) =>{
    const response = await NetworkService
      .delete(`${LAB_USER_PERMISSION}${permissionId}/`);
    return response.data;
  }

  createResourceUserPermission = async (payload)=>{
    const response = await NetworkService.post(RESOURCE_USER_PERMISSION, payload);
    return response;
  }
  updateResourceUserPermission = async(payload, permissionId)=>{
    const response = await NetworkService.patch(`${RESOURCE_USER_PERMISSION}${permissionId}/`, payload);
    return response.data;
  }
   
  deleteResourceUserPermission = async(permissionId)=>{
    const response = await NetworkService.delete(`${RESOURCE_USER_PERMISSION}${permissionId}/`);
    return response.data;
  }

  createResourceGroupPermission = async (payload)=>{
    const response = await NetworkService.post(RESOURCE_GROUP_PERMISSION, payload);
    return response;
  }
  updateResourceGroupPermission = async(payload, permissionId)=>{
    const response = await NetworkService.patch(`${RESOURCE_GROUP_PERMISSION}${permissionId}/`, payload);
    return response.data;
  }
   
  deleteResourceGroupPermission = async(permissionId)=>{
    const response = await NetworkService.delete(`${RESOURCE_GROUP_PERMISSION}${permissionId}/`);
    return response.data;
  }

  createLabGroupPermission = async (payload) => {
    const response = await NetworkService
      .post(LAB_GROUP_PERMISSION, payload);
    return response.data;
  }
  updateLabGroupPermission = async (payload, permissionId) => {
      const response = await NetworkService
      .patch(`${LAB_GROUP_PERMISSION}${permissionId}/`, payload);
    return response.data;
  }
  deleteLabGroupPermission = async (permissionId) =>{
    const response = await NetworkService
      .delete(`${LAB_GROUP_PERMISSION}${permissionId}/`);
    return response.data;
  }

 
 
  getPermissionUserConfiguration = async (configId) => {
    const response = await NetworkService
      .get(`${LAB_USER_PERMISSION}${configId}/`);
    return response.data;
  }
  getPermissionGroupConfiguration = async (configId) => {
    const response = await NetworkService
      .get(`${LAB_GROUP_PERMISSION}${configId}/`);
    return response.data;
  }

  getResourcePermissionByResourceId = async(params)=>{
    return NetworkService.get(`${UPLOAD_RESOURCE_URL}?${params}`).then((response) => response.data);
  }
}
export default LabMasterService;