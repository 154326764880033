import React, { useState } from "react";
import DataGrid from "components/datagrid/DataGrid";
import _ from "lodash";
import Breadcrumb from "components/Breadcrumb";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "utils/theme";
import { buildQueryParams } from "utils/common";
import FeedbackService from "services/admin/masters/FeedbackService";
import Moment from "react-moment";
import SupportService from "services/admin/masters/SupportService";

const feedbackServiceObj = new FeedbackService();
const supportServiceObj = new SupportService();

const List = (props) => {
    const classes = useStyles();
    const { url: masterUrl } = props.match;
    // eslint-disable-next-line
    const [refreshToken, setRefreshToken] = useState(Date.now());
    const [pageSize, setPageSize] = useState(20);
    // eslint-disable-next-line
    const [customFilter, setFilters] = useState({ "is_deleted": false });
    const metaInfo = {
        filtering: true,
        columns: [
            {
                title: "Created On", field: "created_date", filtering: false, render: row => (
                    <Moment format="DD/MM/YYYY HH:mm A">
                        {row.created_date}
                    </Moment>),
                cellStyle: {
                    width: 5,
                    maxWidth: 5
                },
                headerStyle: {
                    width: 5,
                    maxWidth: 5
                }
            },
            {
                title: "Email", field: "created_by", width: '20px',
                cellStyle: {
                    width: 140,
                    maxWidth: 140
                },
                headerStyle: {
                    width: 100,
                    maxWidth: 100
                }
            },
            {
                title: "Message", field: "message",
                cellStyle: {
                    width: 230,
                    maxWidth: 230
                },
                headerStyle: {
                    width: 230,
                    maxWidth: 230
                }
            },
        ],
        supportActions: [(row) => decideAction(row, 'edit', 'support')],
        feedbackActions: [(row) => decideAction(row, 'edit', 'feedback')],
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "Feedback" },
        ]
    };

    const decideAction = (rowData, actionType, type) => {
        if (actionType === 'edit') {
            return (rowData.is_deleted === false) ? (
                {
                    icon: 'info',
                    tooltip: "Info",
                    iconProps: { style: { fontSize: "20px", color: "#2a2c2e" } },
                    onClick: (event, rowData) => {
                        type === 'feedback' ?
                            props.history.push(`${masterUrl}/${rowData.id}`) :
                            props.history.push(`${masterUrl}/support/${rowData.id}`)
                    },
                }
            ) : null;
        }
    }


    const handlePagingDataForFeedback = (query) => {
        return new Promise((resolve, reject) => {
            let params = { ordering: "-id" }; //default sorting - is used for decending
            if (!_.isEmpty(query.orderBy)) {
                params.ordering = (query.orderDirection === 'desc' ? '-' : '') + query.orderBy.field;
            }
            setPageSize(query.pageSize);
            if (!_.isEmpty(query.filters)) {
                query.filters.map(filter => params[filter.column.field] = filter.value);
            }
            if (customFilter) {
                params = { ...params, ...customFilter };
            }
            params['limit'] = query.pageSize;
            params['offset'] = query.pageSize * query.page;
            feedbackServiceObj.getAllFeedbackList(buildQueryParams(params)).then((response) => {
                resolve({
                    data: response.results,
                    page: query.page,
                    totalCount: response.count,
                });
            }).catch(error => {
                console.log(error)
            });
        });
    }

    const handlePagingDataForSupport = (query) => {
        return new Promise((resolve, reject) => {
            let params = { ordering: "-id" }; //default sorting - is used for decending
            if (!_.isEmpty(query.orderBy)) {
                params.ordering = (query.orderDirection === 'desc' ? '-' : '') + query.orderBy.field;
            }
            setPageSize(query.pageSize);
            if (!_.isEmpty(query.filters)) {
                query.filters.map(filter => params[filter.column.field] = filter.value);
            }
            if (customFilter) {
                params = { ...params, ...customFilter };
            }
            params['limit'] = query.pageSize;
            params['offset'] = query.pageSize * query.page;
            supportServiceObj.getAllSupportMessages(buildQueryParams(params)).then((response) => {
                resolve({
                    data: response.results,
                    page: query.page,
                    totalCount: response.count,
                });
            }).catch(error => {
                console.log(error)
            });
        });
    }

    return (
        <>

            <Grid container spacing={1} className={classes.outerContainer}>
                <Grid item xs={10}>
                    <Breadcrumb data={metaInfo.breadcrumData} />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.contentGrid}>
                        <Typography className={classes.tableHeading}>Feedback</Typography>
                        <DataGrid
                            actions={metaInfo.feedbackActions}
                            columns={metaInfo.columns}
                            dataHandler={handlePagingDataForFeedback}
                            filtering={metaInfo.filtering}
                            pageSize={pageSize}
                            refreshToken={refreshToken}
                        ></DataGrid>
                    </div>
                    <div className={classes.contentGrid}>
                        <Typography className={classes.tableHeading}>Support</Typography>
                        <DataGrid
                            actions={metaInfo.supportActions}
                            columns={metaInfo.columns}
                            dataHandler={handlePagingDataForSupport}
                            filtering={metaInfo.filtering}
                            pageSize={pageSize}
                            refreshToken={refreshToken}
                        ></DataGrid>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
export default List;