import React from 'react';
import { withRouter } from 'react-router-dom';
import { HvContainer } from '@hitachivantara/uikit-react-core';
import NewHeader from 'components/NewHeader.jsx';
import { classes } from './style.js';


function AppLayout({ location, children }) {

  const currentPath = window.location.pathname;
  const isScPortal = currentPath.indexOf('/scportal') === -1;
  let isLoginPage = location?.pathname === '/email-login' || location.pathname === "/" || location.pathname === "/instructionInTab"
  return (
    <div className={classes.layoutContainer}>
      {isScPortal && <NewHeader />}
      
        <HvContainer maxWidth={false} className={isScPortal ? 
           isLoginPage ?  classes.loginContainer : classes.mainContainer : classes.scPortalContainer}>
          {children}
        </HvContainer>
      
    </div>
  );
}

export default withRouter(AppLayout);