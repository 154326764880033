import React, { memo, useState, useMemo, useEffect } from 'react';
import {
    HvGlobalActions, HvGrid, HvInput, HvTabs, HvTab, HvDropdown,
    HvTableContainer, HvTable,
    HvTableHead, HvTableRow,
    HvTableHeader, HvTableBody,
    HvTableCell, HvPagination, useHvPagination, useHvData, HvEmptyState, hvTextColumn, useHvSortBy, HvButton, HvBox, HvTypography, HvTooltip, HvDialog,
    HvDialogTitle, HvDialogContent, HvDialogActions
} from '@hitachivantara/uikit-react-core';
import { Add, Ban, Edit, Delete, Undo } from '@hitachivantara/uikit-react-icons';
import {classes } from "./LabListStyle";
import { buildQueryParams } from "utils/common";
import _ from "lodash";
import LabService from "services/LabService";
import { showBanner } from 'redux/actions/bannerActions';
import { useDispatch } from 'react-redux';
import { MSG_UNDO_RECORD, MSG_WARNING_UNDO_DELETE } from 'constants/admin/masters/labCategory';
import { LOGGED_IN_USER_INFO } from 'constants/common';
import ROLES from "constants/roles";
const userInfo = JSON.parse(sessionStorage.getItem(LOGGED_IN_USER_INFO))
var roles = userInfo?.roles?.map(item => Object.values(item));
roles = roles?.length > 0 ? roles[0] : roles

const labServiceObj = new LabService();

const LabListComp = memo((props) => {
    const [tabValue, setTabValue] = useState(0);
    const [labStatusValue, setLabStatusValue] = useState("Active");
    const [totalrecords, setTotalRecords] = useState();
    const [searchBarValue, setSearchBarValue] = useState();
    const [confirmLabDelete, setConfirmLabDelete] = useState(false);
    const [confirmUndoDelete, setConfirmLabUndoDelete] = useState(false);
    const [currentLabId, setCurrentLabId] = useState(false);
    const { url: masterUrl } = props.match;
    const handleChange = (e, newValue) => setTabValue(newValue);
    const [data, setData] = useState();
    
    const [dropdownMenuItems] = useState([
        { label: "Active", value: "Active", selected: true },
        { label: "In Development", value: "In Developement" },
        { label: "Retired", value: "Retired" },
        { label: "All", value: "All" },
    ])
    let urlParams = {};
    let dispatch = useDispatch();

    const ReadMore = ({ children }) => {
        // Keep this constant and function in this button in order for the button to work properly
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };

        const text = children;
        const outputString = isReadMore ? text.slice(0, 350) : text
        return (
            <div className="text">
                <span className='description' dangerouslySetInnerHTML={{ __html: outputString || '' }} />
                <HvTypography variant='link' onClick={toggleReadMore}>  {isReadMore ? "...Read more" : " Read less"}</HvTypography>
            </div>
        );
    };

    const renderHTML = (row) => {
        const text = row;
        return (
            <p>
                {text.length > 50 ? <ReadMore>
                    {text}
                </ReadMore> :
                    <div className='description' dangerouslySetInnerHTML={{ __html: row.description || '' }} />
                }
            </p>
        );
    }

    const getColumns = () => [
        hvTextColumn({
            Header: "Name",
            accessor: "name",
            cellType: "alpha-numeric",
        }),

        hvTextColumn({
            Header: "Description",
            accessor: "description",
            cellType: "alpha-numeric",
            Cell: (cellData) => {
                if (!_.isEmpty(cellData)) {
                    return (
                        <div style={{ display: "flex" }}>
                            {cellData?.value?.length > 350 ? renderHTML(cellData?.value) : <> <div className='description' dangerouslySetInnerHTML={{ __html: cellData?.value || '' }} /></>}
                        </div>

                    );
                } else {
                    return (<></>)
                }
            },
        }),

        hvTextColumn({
            Header: "Actions",
            Cell: (cellData) => {
                if (!_.isEmpty(cellData) && (cellData.row.original.is_deleted === false)) {
                    return (
                        <>
                            <div style={({ display: "flex", cursor: "pointer" })}>
                                <HvTooltip title="Edit Lab"><HvButton variant='primaryGhost' onClick={() => redirectTo(`${masterUrl}/edit/${cellData.row.original.id}`)}><Edit /></HvButton></HvTooltip>
                                {roles?.includes(ROLES.Technical_Editor) ? <></> : <HvTooltip title="Delete Lab"><HvButton variant='primaryGhost' onClick={() => {
                                    setConfirmLabDelete(!confirmLabDelete);
                                    setCurrentLabId(cellData.row.original.id)
                                }}><Delete /></HvButton></HvTooltip>
                                }

                            </div>

                        </>

                    )
                }
                if (!_.isEmpty(cellData) && (cellData.row.original.is_deleted === true)) {
                    return (<>
                        <div style={({ display: "flex", cursor: "pointer" })}>
                            <HvTooltip title="Undo Deleted Lab">
                                <HvButton variant='primaryGhost' onClick={() => {
                                    setConfirmLabUndoDelete(!confirmUndoDelete);
                                    setCurrentLabId(cellData.row.original.id);
                                }
                                }><Undo /></HvButton></HvTooltip>
                        </div>
                    </>)
                }
            }

        })

    ];

    const deleteLab = (labId) => {
        if (labId) {
            setConfirmLabDelete(!confirmLabDelete);
            labServiceObj.deleteLab(labId).then((response) => {
                if (_.isEmpty(response)) {
                    dispatch(showBanner({
                        payload: {
                            showBanner: true,
                            label: { message: "Lab Deleted." },
                            variant: "success"
                        }
                    }))
                }
                getAllLabs();
            }).catch(error => {
                console.log(error);
            })
        }

    }

    const undoDeleteLab = (labId) => {
        if (labId) {
            setConfirmLabUndoDelete(!confirmUndoDelete);
            labServiceObj.undoDelete(labId).then((response) => {
                if (!_.isEmpty(response)) {
                    dispatch(showBanner({
                        payload: {
                            showBanner: true,
                            label: { message: MSG_UNDO_RECORD },
                            variant: "success"
                        }
                    }))
                    getAllLabs();
                }
            }).catch(error => {
                console.log(error);
            })
        }
    }
    const columns = useMemo(() => {
        return getColumns();
    }, []);

    const { getTableProps, getTableBodyProps, prepareRow, headers, page, gotoPage, state: { pageSize, pageIndex, sortBy }, getHvPaginationProps } =
        useHvData(
            {
                columns,
                data,
                defaultColumn: {
                    Cell: ({ value }) => value ?? "—",
                },
                manualPagination: true,
                manualSortBy: true,
                disableCreateExpandButton: true,
                pageCount: totalrecords
            },
            useHvSortBy,
            useHvPagination
        );

    useEffect(() => {
        gotoPage(0);
    }, [sortBy, gotoPage]);

    const getAllLabs = () => {
        let params = {
            tabIndex: 1
        }
        var sortByValue = "-id";

        if (sortBy?.length) {
            sortByValue = sortBy[0].desc ? "-" + sortBy[0].id : sortBy[0].id;
        }


        urlParams = { ordering: sortByValue };
        urlParams["limit"] = pageSize;
        urlParams["offset"] = pageSize * ((pageIndex + 1) - 1);
        urlParams["status"] = labStatusValue
        if (!_.isEmpty(searchBarValue)) {
            urlParams["name"] = searchBarValue
        }
        labStatusValue === "Retired" ? delete urlParams["is_deleted"] : urlParams["is_deleted"] = false;
        if (labStatusValue === "All") {
            delete urlParams["is_deleted"];
            delete urlParams["status"];
        }
        labServiceObj.runningLabUrl(params, buildQueryParams(urlParams)).then((response) => {
            if (response?.results) {
                setData(response?.results);
                setTotalRecords(Math.ceil(response?.count / pageSize));
            }

        }).catch(error => {
            console.log(error)
        });
    }
    useEffect(() => {
        getAllLabs()
    }, [pageSize, pageIndex, sortBy, labStatusValue, searchBarValue])

    const EmptyRow = () => (
        <HvTableRow>
            <HvTableCell colSpan={100} style={{ height: 50 }}>
                <HvEmptyState message="No data to display" icon={<Ban role="presentation" />} />
            </HvTableCell>
        </HvTableRow>
    );

    const rowRenderer = (pages) => {
        return pages.map((row, index) => {
            prepareRow(row);

            return (
                <React.Fragment key={row.id}>
                    <HvTableRow
                        key={row.Header}
                        {...row.getRowProps({
                            "aria-rowindex": index,
                        })}
                    >
                        {row.cells.map((cell) => (
                            <HvTableCell key={cell.Header} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                            </HvTableCell>
                        ))}
                    </HvTableRow>
                </React.Fragment>
            );
        });
    };

    const redirectTo = (url) => {
        props.history.push(url);
    }

    return (
        <HvGrid container>
            <HvGrid item xs={12}>
                <HvGlobalActions
                    className={classes.globalAction}
                    title={<HvTabs onChange={handleChange} value={tabValue}>
                        <HvTab  label="Overall Labs" />
                        <HvTab  label="Labs Categories" />
                    </HvTabs>} />
            </HvGrid>
            {
                tabValue === 0 && <>
                    <HvGrid item sm={6} md={4}>
                        <HvInput type='search' placeholder='Search Lab Name' onChange={((e) => { setSearchBarValue(e?.target.value) })} />
                    </HvGrid>
                    <HvGrid item sm={4} md={2}>
                        <HvDropdown id="labStatus" values={dropdownMenuItems} onChange={(e) => { setLabStatusValue(e?.label) }} />
                    </HvGrid>
                    {
                        roles?.includes(ROLES.Technical_Editor) ? <></> :
                            <HvGrid item sm={4} md={6} xs={2} lg={6} xl={6}>
                                <HvBox component='div' style={{ display: "flex", flexDirection: "row-reverse", paddingBottom: "32px" }}>
                                    <HvButton id="AddLab" variant="primary" onClick={() => redirectTo(`${masterUrl}/add`)}><Add />Add Lab</HvButton>
                                </HvBox>
                            </HvGrid>

                    }


                    <HvGrid container item xs={12}> <HvGrid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <HvTableContainer>
                            <HvTable
                                {...getTableProps({
                                    "aria-rowcount": data?.length,
                                    caption: "Table Caption",
                                })}
                                style={{
                                    width: "100%",
                                    position: "relative"
                                }}
                            >
                                <HvTableHead>
                                    <HvTableRow>
                                        {headers.map((col) => (
                                            <HvTableHeader key={col.Header} {...col.getHeaderProps()}>
                                                {col.render("Header")}
                                            </HvTableHeader>
                                        ))}
                                    </HvTableRow>
                                </HvTableHead>
                                <HvTableBody {...getTableBodyProps()}>
                                    {page.length === 0 ? <EmptyRow /> : rowRenderer(page)}
                                </HvTableBody>
                            </HvTable>
                        </HvTableContainer>
                        <HvPagination
                            {...getHvPaginationProps()} />
                    </HvGrid>
                    </HvGrid>
                </>


            }

            {
                confirmLabDelete ? <>
                    <HvDialog
                        disableBackdropClick
                        open={confirmLabDelete}
                        onClose={() => setConfirmLabDelete(false)}
                        id="delete"
                    >
                        <HvDialogTitle variant="warning">Delete Lab?</HvDialogTitle>
                        <HvDialogContent indentContent>
                            Do yo really want to delete lab
                        </HvDialogContent>
                        <HvDialogActions>
                            <HvButton id="apply" onClick={() => { deleteLab(currentLabId) }} variant="primaryGhost">
                                Yes
                            </HvButton>
                            <HvButton id="cancel" variant="primaryGhost" onClick={() => setConfirmLabDelete(false)}>
                                No
                            </HvButton>
                        </HvDialogActions>
                    </HvDialog>
                </> : <></>
            }
            {
                confirmUndoDelete ? <>
                    <HvDialog
                        disableBackdropClick
                        open={confirmUndoDelete}
                        onClose={() => setConfirmLabUndoDelete(false)}
                        id="delete"
                    >
                        <HvDialogTitle variant="warning">Undo Lab?</HvDialogTitle>
                        <HvDialogContent indentContent>
                            {MSG_WARNING_UNDO_DELETE}
                        </HvDialogContent>
                        <HvDialogActions>
                            <HvButton id="apply" onClick={() => { undoDeleteLab(currentLabId) }} variant="primaryGhost">
                                Yes
                            </HvButton>
                            <HvButton id="cancel" variant="primaryGhost" onClick={() => setConfirmLabUndoDelete(false)}>
                                No
                            </HvButton>
                        </HvDialogActions>
                    </HvDialog>
                </> : <></>
            }
        </HvGrid>
    );
});

LabListComp.propTypes = {

};

export default LabListComp;