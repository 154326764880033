import React, { Fragment, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography } from "@material-ui/core";
import { useStyles } from "./style";

export default function RadioButton(props) {
    const classes = useStyles();
    const { group, groupItems, checkedDefault, onChange } = props;
    const [value, setValue] = React.useState(checkedDefault);

    useEffect(() => {
        setValue(checkedDefault.toString());
    }, [checkedDefault]);

    const handleChange = event => {
        onChange(event.target.value);
        setValue(event.target.value)
    };

    return (
        <div className={classes.margin}>
            <Typography className={classes.label}>{group}</Typography>
            {groupItems.length && (
                <RadioGroup
                    aria-label={group}
                    name={group}
                    value={value}
                    onChange={handleChange}
                    className={classes.radioBtn}
                >
                    {groupItems.map((item, index) => (
                        <Fragment key={index}>
                            <FormControlLabel
                                value={item.name}
                                control={
                                    <Radio
                                    />
                                }
                                label={item.label}
                                disabled={item.disabled && "disabled"}
                            />
                        </Fragment>
                    ))}
                </RadioGroup>
            )}
        </div>
    );
}
