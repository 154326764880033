import React, { useEffect, useRef, useState } from "react";
import { Collapse, Grid, List, ListItem, ListItemText, IconButton, Box } from "@material-ui/core";
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import SearchBar from "components/searchBar";
import { toast } from "react-toastify";
import { useStyles } from "./style";
import _ from "lodash";

export default function GroupUserPermissions({ data, changeHandler,selectedData }) {
    const classes = useStyles();
    const ref = useRef(null);
    const flatUserList = [];
    data.forEach(group => {
        group.users.forEach(user => {
            const checkExist = flatUserList.findIndex(item => item.id === user.id);
            if (checkExist === -1) {
                flatUserList.push(user)
            }
        })
    })
    const [rightSearchText, setRightSearchText] = useState('');
    const [leftSearchText, setLeftSearchText] = useState('');
    const [isExpanded, setExpand] = useState([]);

    const toggleExpandCollapse = (e, Id) => {
        e.stopPropagation();
        if (isExpanded.includes(Id)) {
            const newData = isExpanded.filter(item => item !== Id);
            setExpand(newData)
        } else {
            const newData = [...isExpanded];
            newData.push(Id)
            setExpand(newData)
        }
    }

    const [isSelectedLeftGroup, setSelectionLeftGroup] = useState([]);

    const toggleSelectionLeftGroup = (e, Id) => {
        e.stopPropagation();
        if (isSelectedLeftGroup.includes(Id)) {
            const newData = isSelectedLeftGroup.filter(item => item !== Id);
            setSelectionLeftGroup(newData)
        } else {
            const newData = [...isSelectedLeftGroup];
            newData.push(Id)
            setSelectionLeftGroup(newData)
        }
    }

    const [isSelectedLeftUser, setSelectionLeftUser] = useState([]);

    const toggleSelectionLeftUser = (e, Id) => {
        e.stopPropagation();
        if (isSelectedLeftUser.includes(Id)) {
            const newData = isSelectedLeftUser.filter(item => item !== Id);
            setSelectionLeftUser(newData)
        } else {
            const newData = [...isSelectedLeftUser];
            newData.push(Id)
            setSelectionLeftUser(newData)
        }
    }
    const [isSelectedRightGroup, setSelectionRightGroup] = useState([]);

    const toggleSelectionRightGroup = (e, Id) => {
        e.stopPropagation();
        if (isSelectedRightGroup.includes(Id)) {
            const newData = isSelectedRightGroup.filter(item => item !== Id);
            setSelectionRightGroup(newData)
        } else {
            const newData = [...isSelectedRightGroup];
            newData.push(Id)
            setSelectionRightGroup(newData)
        }
    }
    const [isSelectedRightUser, setSelectionRightUser] = useState([]);

    const toggleSelectionRightUser = (e, Id) => {
        e.stopPropagation();
        if (isSelectedRightUser.includes(Id)) {
            const newData = isSelectedRightUser.filter(item => item !== Id);
            setSelectionRightUser(newData)
        } else {
            const newData = [...isSelectedRightUser];
            newData.push(Id)
            setSelectionRightUser(newData)
        }
    }
    const [rightSideGroups, setRightSideGroups] = useState([]);
    const [rightSideUsers, setRightSideUsers] = useState([]);

    useEffect(()=>{
        setRightSideGroups(selectedData?.groups);
        setRightSideUsers(selectedData?.users)
    },[selectedData])

    useEffect(() => {
        if (ref && ref?.current) {
            const data = [{
                key: 'groups', targetValue: rightSideGroups
            }, {
                key: 'users', targetValue: rightSideUsers
            }]
            changeHandler(data);//invoke the parent
        } else {
            ref.current = true;
        }
        // eslint-disable-next-line
    }, [rightSideGroups, rightSideUsers])

    const moveItems = direction => {
        if (direction === 'right') {
            if (isSelectedLeftGroup.length > 0 || isSelectedLeftUser.length > 0) {
                const existingRightSideGroups = [...rightSideGroups, ...isSelectedLeftGroup];
                setRightSideGroups(existingRightSideGroups)

                setSelectionRightGroup([...isSelectedLeftGroup])
                setSelectionLeftGroup([]);

                const existingRightSideUsers = [...rightSideUsers, ...isSelectedLeftUser];
                setRightSideUsers(existingRightSideUsers)

                setSelectionRightUser([...isSelectedLeftUser])
                setSelectionLeftUser([])

            } else {
                toast.error("No Group or User Selected to Move Right Side")
            }
        } else {

            if (isSelectedRightGroup.length > 0 || isSelectedRightUser.length > 0) {
                const newRightSideGroups = rightSideGroups.filter(groupId => !isSelectedRightGroup.includes(groupId));
                setRightSideGroups(newRightSideGroups);
                setSelectionLeftGroup([...isSelectedRightGroup])
                setSelectionRightGroup([])

                const newRightSideUsers = rightSideUsers.filter(UserId => !isSelectedRightUser.includes(UserId));
                setRightSideUsers(newRightSideUsers);
                setSelectionLeftUser([...isSelectedRightUser])
                setSelectionRightUser([])

            } else {
                toast.error("No Group or User Selected to Move Left Side")
            }
        }
    }

    const handleSearch = e => {
        const searchText =  !_.isUndefined(e.target?.value?.toLowerCase()) ? e.target?.value?.toLowerCase() : '';
        if (e.target.name === 'rightSearchBox') {
            setRightSearchText(searchText)
        } else if (e.target.name === 'leftSearchBox') {
            setLeftSearchText(searchText)
        }else{
            setRightSearchText('')
            setLeftSearchText('')
        }
       
    }

    const getFilteredRecords = (data, searchText) => {
        return data.filter(User => User?.email?.toLowerCase().includes(searchText) || User?.full_name?.toLowerCase().includes(searchText))
    }

    const filteredUserFlatList = getFilteredRecords(flatUserList.filter(item => rightSideUsers.includes(item.id)), rightSearchText);

    return (
        <React.Fragment>
            <Box display='flex'>
              <Grid item xs={6} >
                    <Box className={classes.header}>All groups / users</Box>
                    <Box className={classes.searchContainer}>
                        <SearchBar name="leftSearchBox" changeHandler={handleSearch} />
                    </Box>

                    <Box className={classes.listContainer}>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="div"
                        > {data.map((data, index) => (
                            <React.Fragment key={'mainKey-' + index} >
                                <ListItem selected={isSelectedLeftGroup.includes(data.id)} onClick={e => toggleSelectionLeftGroup(e, data.id)} button >
                                    <ListItemText primary={`${data.name} (${getFilteredRecords(data.users, leftSearchText).length} Users) `} />
                                    <IconButton onClick={e => toggleExpandCollapse(e, data.id)}>
                                        {isExpanded.includes(data.id) ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>
                                </ListItem>
                                <Collapse in={isExpanded.includes(data.id)} timeout="auto" unmountOnExit >
                                    <List component="div" disablePadding>
                                        {getFilteredRecords(data?.users, leftSearchText)?.map((user, index) => (
                                            <ListItem selected={isSelectedLeftUser.includes(user.id)} onClick={e => toggleSelectionLeftUser(e, user.id)} key={'userKey-' + index} button className={classes.nested}>
                                                <ListItemText primary={user.full_name} secondary={user.email} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </React.Fragment>
                        )
                        )}
                        </List>
                    </Box>
                </Grid>

                <Grid className={classes.arrowContainer}>
                    <Box className={classes.leftRightArrow}>
                        <ExpandMore onClick={e => moveItems('right')} className={classes.moveToRight} />
                        <ExpandMore onClick={e => moveItems('left')} className={classes.moveToLeft} />
                    </Box>

                </Grid>

                <Grid item xs={5}>
                    <Box className={classes.header} >Allowed group / users</Box>
                    <Box className={classes.searchContainer}>
                        <SearchBar name="rightSearchBox" changeHandler={handleSearch} />
                    </Box>

                    <Box className={classes.listContainer}>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="div"
                        > {rightSideGroups && data.filter(item => rightSideGroups.includes(item.id)).map((data, index) => (
                            <ListItem selected={isSelectedRightGroup.includes(data.id)} onClick={e => toggleSelectionRightGroup(e, data.id)} key={'mainKey-' + index} button >
                                <ListItemText primary={`${data.name} (${data.users.length} Users) `} />
                            </ListItem>
                        )
                        )}
                            <hr />
                            {rightSideUsers && filteredUserFlatList?.map((user, uIndex) => (
                                <ListItem selected={isSelectedRightUser.includes(user.id)} onClick={e => toggleSelectionRightUser(e, user.id)} key={'userKey-' + uIndex} button >
                                    <ListItemText primary={user.full_name} secondary={user.email} />
                                </ListItem>
                            ))
                            }

                        </List>
                    </Box>
                </Grid>
            </Box>
        </React.Fragment>
    )
}