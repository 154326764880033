import NetworkService from "services/NetworkService";
import {
    METHOD_LIST,
    METHOD_LIST_SUPPORT
} from "constants/admin/masters/support";

class SupportService {
    getAllSupportList = (urlParams) => {
        return NetworkService
            .get(`${METHOD_LIST}?${urlParams}`)
            .then((response) => response.data);
    };
    deleteSupportQuestion = (questionId) => {
        return NetworkService
            .delete(`${METHOD_LIST}${questionId}/`)
            .then((response) => response.data);
    }
    getDetailByquestionId = (questionId) => {
        return NetworkService
            .get(`${METHOD_LIST}${questionId}/`)
            .then((response) => response.data);
    }
    createSupportQuestion = (data) => {
        return NetworkService
            .post(METHOD_LIST, data)
            .then((response) => response.data);
    }
    updateSupportQuestion = (data, categoryId) => {
        return NetworkService
            .patch(`${METHOD_LIST}${categoryId}/`, data)
            .then((response) => response.data);
    }
    getAllSupportMessages = (urlParams) => {
        return NetworkService
            .get(`${METHOD_LIST_SUPPORT}?${urlParams}`)
            .then((response) => response.data);
    };
    getDetailBySupportId = (supportId) => {
        return NetworkService
            .get(`${METHOD_LIST_SUPPORT}${supportId}/`)
            .then((response) => response.data);
    }
    markSupportAsRead = (data, supportId) => {
        return NetworkService
            .patch(`${METHOD_LIST_SUPPORT}${supportId}/`, data)
            .then((response) => response.data);
    }

}
export default SupportService;
