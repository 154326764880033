import { LOGGED_IN_USER_INFO } from "constants/common";

class StorageService {

    setUserData = (data) => {
        sessionStorage.setItem(LOGGED_IN_USER_INFO, JSON.stringify(data));
        return true;
    }
    getUserData = () => {
        const data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
        return data?JSON.parse(data):{};
    }
    clearAllData = () => {
        sessionStorage.clear();
        localStorage.clear();
    }
    setData = (key, data) => {
        sessionStorage.setItem(key, JSON.stringify(data));
        return true;
    }
    geData = (key) => {
        const data = sessionStorage.getItem(key) || {};
        return JSON.parse(data)
    }

}
export default StorageService;