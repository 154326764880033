import React, { useState, useRef, useEffect } from "react";
import { HvGrid, HvListItem, HvBox, HvListContainer, HvButton } from "@hitachivantara/uikit-react-core";
import { DropDownXS, DropLeft, DropRight, DropRightXS } from '@hitachivantara/uikit-react-icons';
import SearchBar from "components/searchBar";
import { toast } from "react-toastify";
import { useStyles } from "./style";

export default function UserPermissions({ allUsers, changeHandler, selectedUsers = [] }) {
    const classes = useStyles();
    const ref = useRef(null);
    const [rightSideUsers, setRightSideUsers] = useState(selectedUsers);
    const [leftSearchText, setLeftSearchText] = useState('');
    const [rightSearchText, setRightSearchText] = useState('');


    const [selectedState, setSelectedState] = useState([]);
    const toggleSelection = (e, Id) => {
        e.stopPropagation();
        if (selectedState.includes(Id)) {
            const newData = selectedState.filter(sid => sid !== Id);
            setSelectedState(newData)
        } else {
            const newData = [...selectedState];
            newData.push(Id)
            setSelectedState(newData)
        }
    }

    useEffect(() => {
        setRightSideUsers(selectedUsers)
    }, [selectedUsers])

    useEffect(() => {
        if (ref && ref?.current) {
            changeHandler('users', rightSideUsers);//invoke the parent
        } else {
            ref.current = true;
        }
        // eslint-disable-next-line
    }, [rightSideUsers])


    const handleSearch = e => {

        const searchText = e.target?.value?.toLowerCase();
        if (e.target.name === 'rightSearchBox') {
            setRightSearchText(searchText)
        } else if (e.target.name === 'leftSearchBox') {
            setLeftSearchText(searchText)
        }
    }

    const getFilteredRecords = (data, searchText) => {
        return data.filter(user => user?.full_name && user.full_name.toLowerCase().includes(searchText))
    }


    const moveItems = direction => {
        if (selectedState.length === 0) {
            toast.error("No User Selected to Move!");
        } else {
            if (direction === 'right') {
                const newSetUsers = Array.from(new Set([...rightSideUsers, ...selectedState]));
                setRightSideUsers(newSetUsers);
            } else {
                const afterMovetoLeft = [...rightSideUsers].filter(id => !selectedState.includes(id));
                setRightSideUsers(afterMovetoLeft);

            }
        }

    }

    const leftUsers = getFilteredRecords(allUsers.filter(item => !rightSideUsers.includes(item.id)), leftSearchText);
    const rightUsers = getFilteredRecords(allUsers.filter(item => rightSideUsers.includes(item.id)), rightSearchText);

    return (
        <React.Fragment>
            <HvGrid container style={{ paddingBottom: "20px" }}>

                <HvGrid item xs={6} md={6} xl={6} sm={6}>
                    <HvBox className={classes.header} >All Users</HvBox>
                    <HvBox className={classes.searchContainer}>
                        <SearchBar name="leftSearchBox" changeHandler={handleSearch} />
                    </HvBox>

                    <HvBox className={classes.listContainer}>
                        <HvListContainer
                        >
                            {leftUsers && leftUsers?.map((item, uIndex) => (
                                <HvListItem selected={selectedState.includes(item.id)} key={'lKey-' + uIndex} onClick={e => toggleSelection(e, item.id)} button >
                                    <HvListItem style={{ cursor: "pointer" }} >{item.full_name} ({item.email})</HvListItem>
                                </HvListItem>
                            ))
                            }

                        </HvListContainer>
                    </HvBox>
                </HvGrid>
                <HvGrid item xs={1} md={1} xl={1} sm={1} alignContent={"center"}>
                    <HvBox>
                        <HvButton variant='primaryGhost' onClick={e => moveItems('right')}  ><DropRight /></HvButton>
                        <HvButton variant='primaryGhost' onClick={e => moveItems('left')}><DropLeft /></HvButton>
                    </HvBox>

                </HvGrid>

                <HvGrid item xs={5} md={5} xl={5} sm={5} >
                    <HvBox className={classes.header} >Selected Users</HvBox>
                    <HvBox className={classes.searchContainer}>
                        <SearchBar name="rightSearchBox" changeHandler={handleSearch} />
                    </HvBox>
                    <HvBox className={classes.listContainer}>
                        <HvListContainer
                        >
                            {rightUsers && rightUsers?.map((item, uIndex) => (
                                <HvListItem selected={selectedState.includes(item.id)} key={'rKey-' + uIndex} onClick={e => toggleSelection(e, item.id)} button >
                                    <HvListItem style={{ cursor: "pointer" }}>{item.full_name} ({item.email})</HvListItem>
                                </HvListItem>
                            ))
                            }

                        </HvListContainer>
                    </HvBox>

                </HvGrid>
            </HvGrid>
        </React.Fragment>
    )
}