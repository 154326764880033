import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Button, Typography } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import FeedbackService from "services/admin/masters/FeedbackService";
import moment from 'moment';

const feedbackServiceObj = new FeedbackService();

const Form = ({ history, ...props }) => {
    const classes = useStyles();
    const { params } = props.match;
    const feedbackId = params?.feedbackId;
    const [feedbackDetails, setFeedbackDetails] = useState({});

    //loads the initial data when form is loading//
    useEffect(() => {
        feedbackServiceObj.getDetailByFeedbackId(feedbackId).then(response => {
            setFeedbackDetails(response);
        }).catch(err => {
            console.log(err)
        })
        // eslint-disable-next-line
    }, [])

    // const handleMarkAsRead = () => {
    //     let params = {
    //         is_read: true,
    //     }
    //     feedbackServiceObj.markFeedbackAsRead(params, feedbackId).then(response => {
    //         if (response) {
    //             history.push('/admin/masters/feedback')
    //         }
    //     })
    // }

    return (
        <Grid container spacing={1} className={classes.outerContainer}>
            <Grid item xs={10}>
                <Typography className={classes.inputLabel}>Feedback Details</Typography>
            </Grid>

            <Grid style={{ padding: 0 }} item xs={2}>
                <Box className={classes.masterBtn} display="flex" flexDirection="row-reverse" >
                    <Button component={Link} to={'/admin/masters/feedback'}>
                        Back
                    </Button>
                </Box>
            </Grid>

            <Grid item xs={12}>
                <div className={classes.contentRoot}>
                    <Typography className={classes.inputLabel}>Email</Typography>
                    <TextField fullWidth
                        name="email"
                        variant="outlined"
                        disabled
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                            classes: {
                                input: classes.inputField,
                                root: classes.base,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#2a2c2e' },
                        }}
                        value={feedbackDetails.created_by}
                    />

                    <Typography className={classes.inputLabel}>Created On</Typography>
                    <TextField fullWidth
                        name="created_on"

                        variant="outlined"
                        disabled
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                            classes: {
                                input: classes.inputField,
                                root: classes.base,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#2a2c2e' },
                        }}
                        value={moment(feedbackDetails.created_date).format('DD-MM-YYYY')}
                    />

                    <Typography className={classes.inputLabel}>Labs</Typography>
                    <TextField fullWidth
                        name="labs"
                        variant="outlined"
                        disabled
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                            classes: {
                                input: classes.inputField,
                                root: classes.base,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#2a2c2e' },
                        }}
                        value={(feedbackDetails.lab && feedbackDetails.lab['name']) || ''}
                    />
                    <Typography className={classes.inputLabel}>Message</Typography>
                    <TextField fullWidth
                        name="message"
                        variant="outlined"
                        disabled
                        classes={{ root: classes.customTextField }}
                        InputProps={{
                            classes: {
                                input: classes.inputField,
                                root: classes.base,
                                focused: classes.focused,
                                notchedOutline: classes.notchedOutline
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: { color: '#2a2c2e' },
                        }}
                        value={feedbackDetails.message || ''}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(Form);