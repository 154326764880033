import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import VideoCatalogService from "services/admin/masters/VideoCatalogService";
import Breadcrumb from "components/Breadcrumb";
import { Box, Grid, TextField, Button, Typography, Icon } from "@material-ui/core";
import { getMasterBaseUrl } from "utils/masterHelpers";
import { useForm } from "hooks/useForm";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import { MSG_ADD_SUCCESS, MSG_UPDATE_SUCCESS } from "constants/admin/masters/videoCatalog";
import { transformDataCustomSelect } from "utils/common";
import CustomSelect from "react-select";
import Radio from 'components/radioButton';
import SingleFileUpload from 'components/fileUpload/SingleFileUpload';
import VideoCategoryService from "services/admin/masters/VideoCategoryService";
import { buildQueryParams } from "utils/common";

const groupItems = [
    {
        name: "1",
        label: "Yes",
        disabled: false
    },
    {
        name: "0",
        label: "No",
        disabled: false
    },
];
const videoCatalogServiceObj = new VideoCatalogService();
const videoCategoryServiceObj = new VideoCategoryService();

const Form = ({ history, ...props }) => {
    const classes = useStyles();
    const { url, params } = props.match;
    const catalogId = params?.catalogId;
    const masterUrl = getMasterBaseUrl(url, params);

    const [categoryList, setCategoryList] = useState([]);

    const initialFormData = { // used to initialize the data
        name: '',
        description: '',
        is_deleted: '0',
        duration: 0,
        categories: [],
        thumbnail: '',
        thumbnail_id: 0,
        video_url: '',
    };
    const metaInfo = {
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "Video Catalog List", link: `${masterUrl}` },
            { title: (catalogId > 0) ? "Edit Video Catalog" : "Add Video Catalog" },
        ]
    };

    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        handleChangeCustom,
        handleMultipleChange,
        data, // access to the form data
        errors, // includes the errors to show
        populateForm, //to populate the values in case of edit
    } = useForm({ // the hook we are going to create
        initialValues: initialFormData,//pass the initializing data 
        validations: { // all our validation rules go here
            name: {
                required: {
                    value: true,
                    message: 'Catalog Name is required',
                }
            },
            description: {
                required: {
                    value: true,
                    message: 'Description is required',
                }
            },
            duration: {
                required: {
                    value: true,
                    message: 'Duration is required',
                }
            },
            categories: {
                required: {
                    value: true,
                    message: 'Atleast 1 Category is required',
                }
            },
            thumbnail: {
                required: {
                    value: true,
                    message: 'Thumbnail is required',
                },
            },
            video_url: {
                required: {
                    value: true,
                    message: 'Video is required',
                },
                pattern: {
                    value: /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                    message: 'Video url is not valid',
                },
            },
        },
        onSubmit: () => {
            //trigger the actual post submit function from here 
            submitForm(data)
        }
    });

    //loads the initial data when form is loading//
    useEffect(() => {
        const params = {
            is_deleted: false,
        }
        videoCategoryServiceObj.getAll(buildQueryParams(params)).then(response => {
            if (response) {
                setCategoryList(transformDataCustomSelect(response.results))
            }
        }).catch(err => {
            console.log('error in category data')
        })


        if (catalogId > 0) {
            videoCatalogServiceObj.getDetail(catalogId).then(response => {
                initialFormData.name = response?.name || '';
                initialFormData.description = response?.description || '';
                initialFormData.is_deleted = response?.is_deleted || 0;
                initialFormData.duration = response?.duration || 0;
                initialFormData.categories = (response?.categories?.map(item => item.id)) || [];
                initialFormData.thumbnail = response?.thumbnail?.thumbnail || '';
                initialFormData.video_url = response?.video_url || '';
                initialFormData.thumbnail_id = (response && response.thumbnail?.id) || '';
                populateForm(initialFormData);
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line
    }, [])

    const submitForm = async (data) => {
        let response;
        if (catalogId > 0) {
            response = await videoCatalogServiceObj.update(data, catalogId);
        } else {
            response = await videoCatalogServiceObj.create(data);
        }
        if (response) {
            toast.success(response.message || (catalogId > 0) ? MSG_UPDATE_SUCCESS : MSG_ADD_SUCCESS);
            history.push(`${masterUrl}`);
        }

    }

    return (
        <Grid container spacing={1} className={classes.outerContainer}>
            <Grid item xs={10}>
                <Breadcrumb data={metaInfo.breadcrumData} />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.contentRoot}>
                    <form onSubmit={handleSubmit} noValidate>
                        <TextField fullWidth
                            name="name"
                            label="Catalog Name"
                            variant="outlined"
                            placeholder="Catalog Name"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}

                            value={data.name || ''}
                            onChange={handleChange('name')}
                            error={(errors['name'] && errors['name'] !== undefined) ? true : false}
                            helperText={(errors['name'] && errors['name'] !== undefined) ? errors['name'] : null}
                        />

                        <TextField fullWidth
                            name="description"
                            label="Description"
                            variant="outlined"
                            placeholder="Description"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.description || ''}
                            onChange={handleChange('description')}
                            error={(errors['description'] && errors['description'] !== undefined) ? true : false}
                            helperText={(errors['description'] && errors['description'] !== undefined) ? errors['description'] : null}
                        />


                        <Radio
                            groupItems={groupItems}
                            checkedDefault={data.is_deleted}
                            group='Is Deleted'
                            onChange={(selectedValue) => {
                                handleChangeCustom('is_deleted', selectedValue)
                            }}
                        />

                        <TextField fullWidth
                            type="number"
                            name="duration"
                            label="Duration"
                            variant="outlined"
                            placeholder="Duration"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            inputProps={{ min: 0 }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.duration || ''}
                            onChange={handleChange('duration')}
                            error={(errors['duration'] && errors['duration'] !== undefined) ? true : false}
                            helperText={(errors['duration'] && errors['duration'] !== undefined) ? errors['duration'] : null}
                        />

                        <Grid item xs={12}>
                            <div className={classes.externalFormControl} >
                                <Typography color="textPrimary" className='typo-header'>Categories</Typography>
                                <CustomSelect
                                    menuPortalTarget={document.body}
                                    isMulti
                                    placeholder={`Search Category Here`}
                                    options={categoryList}
                                    value={
                                        categoryList.filter(
                                            item => data?.categories?.includes(item?.value)
                                        ) || []
                                    }
                                    onChange={e => {
                                        const selectedItems = e?.map(i => i.value);
                                        handleChangeCustom('categories', selectedItems)
                                    }}
                                />
                                {(errors['categories'] && errors['categories'] !== undefined) ? <p className={classes.errorLabel}>{errors['categories']}</p> : null}
                            </div>
                        </Grid>


                        <Grid item style={{ paddingTop: '8px' }} xs={12}>
                            <SingleFileUpload
                                fieldValue={data?.thumbnail || ''}
                                fieldError={errors?.thumbnail || ''}
                                fieldName="thumbnail"
                                fieldLabel="Thumbnail"
                                supportedFileTypes={['jpg', 'png', 'gif']}
                                supportedFileMaxSize={50}
                                onSuccessUpload={response => {
                                    const data = [{
                                        key: 'thumbnail', targetValue: response.thumbnail
                                    }, {
                                        key: 'thumbnail_id', targetValue: response.id
                                    }]
                                    handleMultipleChange(data);

                                }}
                            />
                        </Grid>



                        <div className={classes.videoLeftPanel}>
                            <TextField fullWidth
                                name="video_url"
                                label="Video Url"
                                variant="outlined"
                                placeholder="Video Url"
                                classes={{ root: classes.customTextField }}
                                InputProps={{
                                    classes: {
                                        input: classes.inputField,
                                        root: classes.base,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { color: '#2a2c2e' },
                                }}
                                value={data.video_url || ''}
                                onChange={handleChange('video_url')}
                                error={(errors['video_url'] && errors['video_url'] !== undefined) ? true : false}
                                helperText={(errors['video_url'] && errors['video_url'] !== undefined) ? errors['video_url'] : null}
                            />
                        </div>
                        <div className={classes.videoRightPanel} >
                            <Icon>visibility</Icon>
                        </div>



                        <Grid item xs={12}>
                            <Box className={classes.masterBtn} display="flex" justifyContent='right'>
                                <Button className='back-btn' component={Link} to={`${masterUrl}`} >
                                    Back
                                </Button>
                                <Button className='add-btn' type="submit" >
                                    {(catalogId > 0) ? 'Update' : 'Add'}
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(Form);