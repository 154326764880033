import React, { useState, useMemo, useEffect } from "react";
// import { HvTable } from '@hitachivantara/uikit-react-compat';
import {
  HvTypography,
  HvTableContainer,
  HvTable,
  HvTableHead,
  HvTableRow,
  HvTableHeader,
  HvTableBody,
  HvTableCell,
  HvPagination,
  useHvPagination,
  useHvData,
  HvEmptyState,
  hvTextColumn,
  useHvSortBy,
  hvNumberColumn,
  hvDateColumn,
} from "@hitachivantara/uikit-react-core";
import { Ban } from "@hitachivantara/uikit-react-icons";
import _ from "lodash";
import PropTypes from "prop-types";
import ReportService from "services/ReportService.js";
import { buildQueryParams } from "utils/common.js";
import moment from "moment";

const TableViewTop10 = (props) => {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [startDate] = useState(props?.startDate);
  const [endDate] = useState(props.endDate);

  const reportsServiceObj = new ReportService();
  const getColumns = () => [
    hvTextColumn({
      Header: "Lab Name",
      accessor: "lab__name",
      cellType: "alpha-numeric",
    }),
    hvNumberColumn({
      Header: "Used Time",
      accessor: "total_used_time",
      cellType: "numeric",
    }),
    hvNumberColumn({
      Header: "Total Session",
      accessor: "total_sessions",
      cellType: "alpha-numeric",
    })
  ];

 
  const filterColumns = (columns, data) => {
    return columns.filter((column) => {
      return data.some((row) => row[column.accessor] !== null && row[column.accessor] !== undefined);
    });
  };

  const columns = useMemo(() => {
    const initialColumns = getColumns();
    return filterColumns(initialColumns, data);
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headers,
    page,
    gotoPage,
    state: { pageSize, pageIndex, sortBy },
    getHvPaginationProps,
  } = useHvData(
    {
      columns,
      data,
      defaultColumn: {
        Cell: ({ value }) => value ?? "—",
      },
      manualPagination: true,
      manualSortBy: true,
      disableCreateExpandButton: true,
      pageCount: totalRecords,
    },
    useHvSortBy,
    useHvPagination
  );

  useEffect(() => {
    gotoPage(0);
  }, [sortBy, gotoPage]);

  useEffect(() => {
    let tableParams = {};
    tableParams["start_date"] = moment(props?.startDate).format("YYYY-MM-DD");;
    tableParams["end_date"] = moment(props?.endDate).format("YYYY-MM-DD");;
    tableParams["limit"] = pageSize;
    tableParams["offset"] = pageSize * (pageIndex + 1 - 1);
    tableParams["report_type"] = props.order_by;
    reportsServiceObj
      .getTop10Table(buildQueryParams(tableParams))
      .then((response) => {
        if (props.order_by === "session") {
          const tableData = response.results?.map((item) => {
            let output = {};
            if (!_.isNull(item)) {
              output.lab__name = item.lab__name;
              output.total_sessions = item.total_sessions;
              return output;
            }
          });
          setData(tableData);
        }else{
          if(props?.order_by === "used_time"){
              const tableData = response.results?.map((item) => {
                let output = {};
                if (!_.isNull(item)) {
                  output.lab__name = item.lab__name;
                  output.total_used_time = item.total_used_time
                  return output;
                }
              });
              setData(tableData);
          }
        }
        setTotalRecords(Math.ceil(response?.count / pageSize));
      });
  }, [pageSize, pageIndex, props?.startDate, props?.endDate, props?.order_by]);

  const EmptyRow = () => (
    <HvTableRow>
      <HvTableCell colSpan={100} style={{ height: 50 }}>
        <HvEmptyState
          message="No data to display"
          icon={<Ban role="presentation" />}
        />
      </HvTableCell>
    </HvTableRow>
  );

  const rowRenderer = (pages) => {
    return pages.map((row, index) => {
      prepareRow(row);

      return (
        <React.Fragment key={row.id}>
          <HvTableRow
            key={row.Header}
            {...row.getRowProps({
              "aria-rowindex": index,
            })}
          >
            {row.cells.map((cell) => (
              <HvTableCell key={cell.Header} {...cell.getCellProps()}>
                {cell.render("Cell")}
              </HvTableCell>
            ))}
          </HvTableRow>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <HvTableContainer>
        <HvTable
          {...getTableProps({
            "aria-rowcount": data.length,
            caption: "Table Caption",
          })}
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <HvTableHead>
            <HvTableRow>
              {headers.map((col) => (
                <HvTableHeader key={col.Header} {...col.getHeaderProps()}>
                  {col.render("Header")}
                </HvTableHeader>
              ))}
            </HvTableRow>
          </HvTableHead>
          <HvTableBody {...getTableBodyProps()}>
            {page.length === 0 ? <EmptyRow /> : rowRenderer(page)}
          </HvTableBody>
        </HvTable>
      </HvTableContainer>
      <HvPagination {...getHvPaginationProps()} />
    </>
  );
};

TableViewTop10.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  order_by: PropTypes.any
};

export default TableViewTop10;
