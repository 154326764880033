import { theme } from "@hitachivantara/uikit-react-core";
import { css } from "@emotion/css";
const showFullScreen = false;
const themeSelector = false;
const drawerWidth = "50%";
const drawerHeight = "60%";
export const classes = {
    root: css({
        position: 'fixed',
        height: '70px !important',
        borderTop: 'none',
        boxShadow: '0px 2px 12px #4141411f',
        opacity: 1,
        top:0,
        right :0,
        left:0,
        display: showFullScreen ? "none" : "flex",
        '  & .HvHeaderMenuBar-menubar': {
          height: 'auto',
          alignItems: 'center',
          ["@media (max-width:1163px)"]: { paddingLeft: '140px' },
        },
        ' & .HvHeaderMenuItem-selectedItem': {
          borderTop: 'none',
          borderBottom: `4px solid ${themeSelector === "dawn" ? "#313131" : '#fbfcfc'}`
  
        },
        ' & .HvHeaderMenuItem-root:hover': {
          backgroundColor: 'unset'
        },
        ' & .HvHeaderMenuItem-root:hover:focus-within > [role=button]': {
          backgroundColor: 'unset'
        },
        ' & .HvHeaderMenuItem-button:focus': {
          backgroundColor: 'unset'
        },
        ' & .HvHeaderMenuItem-root:hover > [role=button]': {
          backgroundColor: 'unset'
        },
        ' & .HvTypography-normalText': {
          textAlign: 'left',
          font: 'normal normal normal 14px/24px Open Sans',
          letterSpacing: '0px',
  
          opacity: '1'
        },
        ' & .HvTypography-selectedNavText': {
          textAlign: 'left',
          font: 'normal normal normal 600 14px/24px Open Sans',
          letterSpacing: '0px',
          color: `${themeSelector === "dawn" ? ' #313131 ' : '#fbfcfc'}`,
          opacity: '1'
        },
        ' & .HvHeaderMenuItem-button': {
          padding: '16px 20px'
        },
        '& .HvHeaderMenuBar-list': {
          paddingLeft: "395px",
          ["@media (max-width:1163px)"]: { paddingLeft: '40px' },
          ["@media (max-width:1920px)"]: { paddingLeft: '1000px' },
          ["@media (max-width:1280px)"]: { paddingLeft: '400px' },
        },
        "& .HvDrawer-background": {
          background: "rgba(204, 206, 208, 0) "
        },
        
      }),
      logo: css({
        width: '8rem',
        cursor: 'pointer',
        paddingLeft: '10px',
        height: '30px'
      }),
      brand: css({
        "& .HvTypography-highlightText": {
          fontWeight: 'unset'
        }
        
      }),
      avatar: css({
        cursor: 'pointer'
      }),
      dropdownMenuProperties: css({
        top: "3px",
        zIndex: "1",
        width: "100%",
        background: "#FBFCFC00 0% 0% no-repeat padding-box",
        opacity: "9",
        "& .HvBaseDropdown-header": {
          border: "1px solid #2064B4 !important",
  
        },
        "& .HvBaseDropdown-arrow": {
          border: "1px solid #2064B4 !important",
          background: "#414141 0 % 0 % no - repeat padding - box",
          opacity: "1"
        },
  
        "& .HvTypography-body": {
          textAlign: "left",
          font: "normal normal 600 14px/20px Open Sans",
          letterSpacing: "0px",
          color: "#2064B4",
          opacity: "1",
          padding: "5px"
        },
        "& .HvDropdownList-listContainer": {
          background: "#FBFCFC 0% 0% no-repeat padding-box",
          boxShadow: "0px 2px 12px #4141411F",
          borderRadius: "0px 0px 2px 2px",
          opacity: "1",
          width: "199px",
          left: "-22px",
          top: "32px",
          position: "absolute",
          height: "90px",
          overflow:"visible"
        },
        "& .HvListContainer-root":{
          overflow:"visible"
        },
        "& .HvTypography-normalText": {
          textAlign: "left",
          font: "normal normal 600 14px/20px Open Sans",
          letterSpacing: "0px",
          color: "#2064B4",
          opacity: "1",
          padding: "5px"
        }
  
      }),
      dropdownBox: css({
        position: "fixed",
        width: "290px",
        top: "13px",
        left: "880px"
      }),
      drawerPaper: css({
        width: `calc(100% - ${drawerWidth})`,
        height: `calc(100% - ${drawerHeight})`,
        overflow: "unset",
        position: "relative",
        "& .HvIconBase-s": {
          display: "none"
        },
        transitionTimingFunction: "cubic-bezier(0, 0.7, 1.0, 0.1) !important",
        transition: "width 3s !important",
        display: "block",
        top: "64px"
  
      }),
  
      drawerContent: css({
        flex: 1,
        overflow: "auto",
  
      }),
  
      mobileMenuFonts: css({
        '& .HvTypography-normalText': {
          color: "#414141",
          fontSize: "15px",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.02em"
        },
        "& .HvTypography-placeholderText": {
          color: "#1d1d1d",
          fontSize: "15px",
          fontWeight: "400",
          lineHeight: "16px",
          letterSpacing: "0.02em"
        }
  
      })
}