import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { HvDialog, HvDialogTitle, HvDialogContent, HvDialogActions, HvButton, HvTypography } from "@hitachivantara/uikit-react-core"
import { LOGGED_IN_USER_INFO, DOWNLOAD_AGREEMENT_MESSAGE, HALO_SUPPORT_TEAM, SHOW_BANNER } from "constants/common";
import { LANGUAGE_GENERAL_SSO_ERROR } from 'constants/login';
import LabService from "services/LabService";
import { useDispatch } from "react-redux";
import { showLoader } from "redux/actions/commonActions";
import { showBanner } from "redux/actions/bannerActions";
import { useHistory, withRouter } from "react-router";
import _ from 'lodash';
import { buildQueryParams } from 'utils/common';
import { useAuth0 } from "@auth0/auth0-react"
import LoginService from "../../services/LoginService";
import { classes } from "./style";

const AgreementDialog = props => {
    const [openAgreementDialog, setOpenAgreementDialog] = useState(true);
    const [acceptedAgreement, setAcceptedAgreement] = useState(false);
    const [agreementId, setAgreementId] = useState('');
    const [agreementPDF, setAgreementPDF] = useState('');
    const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
    const [isButtonEnabled, setButtonEnabled] = useState(false);
    const { isAuthenticated, logout } = useAuth0();
    const dispatch = useDispatch();
    const labServiceObj = new LabService();
    const loginServiceObj = new LoginService();
    const history = useHistory();

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: process.env.REACT_APP_returnTo,
                clientId: process.env.REACT_APP_OKTA_CLIENT_ID
            }
        });

    const logoutFromApplication = () => {
            localStorage.clear("LabUrl");
            if (isAuthenticated) {
                let logouturl = "";
                try {
                    loginServiceObj.getLogoutUrl().then(async result => {
                        if (result?.logouturl) {
                            logouturl = result?.logouturl;
                            sessionStorage.clear(LOGGED_IN_USER_INFO);
                            logoutWithRedirect().then(() => {
                                dispatch(showBanner({
                                    payload: {
                                        showBanner: true,
                                        label: { message: "You have been successfully logged out." },
                                        variant: "success"
                                    }
                                }))
                            })
                        }
                    }).catch(() => {
                        dispatch({
                            type: SHOW_BANNER,
                            payload: {
                                showBanner: true,
                                label: { message: LANGUAGE_GENERAL_SSO_ERROR },
                                variant: "error"
                            }
                        })
                    });

                } catch (err) {
                    console.log(err)
                }
            } else {
                loginServiceObj.getLogoutUrl().then(async result => {
                    if (result?.logouturl) {
                        window.location.href = result.logouturl;
                        sessionStorage.clear(LOGGED_IN_USER_INFO);
                        dispatch({
                            type: SHOW_BANNER,
                            payload: {
                                showBanner: true,
                                label: { message: "You have been logged out." },
                                variant: "success"
                            }
                        })

                    }

                }).catch(() => {
                    dispatch({
                        type: SHOW_BANNER,
                        payload: {
                            showBanner: true,
                            label: { message: LANGUAGE_GENERAL_SSO_ERROR },
                            variant: "error"
                        }
                    })
                });

            
        } 

    }

    useEffect(() => {
        let data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
        let token = data ? JSON.parse(data) : {}
        const hasCustomer = token?.groups?.map(item => item.name === "Customers");
        const isCustomer = hasCustomer?.includes(true);
        if (isCustomer && acceptedAgreement === false) {
            dispatch(showLoader(true))
            labServiceObj.userAgreement()
                .then((response) => {
                    if (_.isEmpty(response.data.results)) {
                        // If user agreement is not accepted, fetch the user agreement
                        let params = {
                            name: "Customer"
                        }
                        return labServiceObj.getUserAgreement(buildQueryParams(params));
                    } else {
                        // If user agreement is already accepted, set the state accordingly
                        setAcceptedAgreement(true);
                        props.launchOrScheduleLab(true);
                        return Promise.reject("User agreement already accepted");
                    }
                })
                .then(agreementResponse => {
                    // Set the agreement PDF and ID
                    setAgreementPDF(agreementResponse.data.results[0]?.content_data);
                    setAgreementId(agreementResponse.data.results[0]?.id);
                })
                .catch(error => {
                    // Handle errors from both userAgreement and getUserAgreement
                    console.log(error);
                });


        }
    }, [])

    const sendAgreementData = () => {
        const payload = {
            "agreement": agreementId,
            "user_agree": true
        }
        setButtonEnabled(true);
        setOpenAgreementDialog(false);
        labServiceObj.acceptUserAgreement(payload).then(response => {
            dispatch(showLoader(true));
            if (response) {
                dispatch(showLoader(false));
                setAcceptedAgreement(true);
                dispatch(showBanner({
                    payload: {
                        showBanner: true,
                        label: { message: "Thank you for accepting agreement." },
                        variant: "success"
                    }
                }))
                props.launchOrScheduleLab(true);
            }
        })
    }

    const handleScroll = (e) => {
        const pdfContainer = e.target;
        // Check if the user has scrolled to the bottom of the PDF
        const threshold = 10;
        if (pdfContainer.scrollHeight - (pdfContainer.scrollTop + pdfContainer.clientHeight) <= threshold) {
            setButtonEnabled(true);
        } else {
            setButtonEnabled(false)
        }
    };
    return (
        <div>
            {
                !_.isEmpty(agreementPDF) ? <HvDialog
                    disableBackdropClick
                    fullScreen={false}
                    style={{ height: "100%" }}
                    open={openAgreementDialog}
                    aria-labelledby="hv-dialog-title"
                    aria-describedby="hv-dialog-description"
                    id="test"
                    autoFocus="true"
                    classes={{
                        closeButton: classes.closeButton
                    }}
                >
                    <HvDialogTitle id="hv-dialog-title" variant="warning">
                        Terms and Conditions
                    </HvDialogTitle>
                    <HvDialogContent indentContent onScroll={(e) => { handleScroll(e) }}>
                        <div id="pdf-container" style={{ width: '100%', height: '100%' }} dangerouslySetInnerHTML={{ __html: agreementPDF }} />
                    </HvDialogContent>

                    <HvDialogActions>
                        <HvButton id="apply" category="ghost" onClick={sendAgreementData} disabled={!isButtonEnabled}>
                            I Accept
                        </HvButton>
                        <HvButton id="apply" category="ghost" onClick={() => { setOpenDeclineDialog(true) }} disabled={!isButtonEnabled}>
                            Decline
                        </HvButton>
                    </HvDialogActions>
                </HvDialog> : <></>
            }

            {
                openDeclineDialog ?
                    <HvDialog
                        disableBackdropClick
                        fullScreen={false}
                        open={openDeclineDialog}
                        onClose={() => {
                            setAgreementPDF('');
                            setOpenDeclineDialog(false);
                            props.openAgreementDialog(false);
                            logoutFromApplication()
                        }}
                        aria-labelledby="hv-dialog-title"
                        aria-describedby="hv-dialog-description"
                        id="test"
                        autoFocus={true}
                    >
                        <HvDialogContent indentContent >
                            <HvTypography variant='normalText' style={{ padding: "10px" }}> {DOWNLOAD_AGREEMENT_MESSAGE} <b>{HALO_SUPPORT_TEAM}</b></HvTypography>
                        </HvDialogContent>
                    </HvDialog> : <></>
            }
        </div>
    );
};

AgreementDialog.propTypes = {
    launchOrScheduleLab: PropTypes.any,
    openAgreementDialog: PropTypes.any,
};

export default withRouter(AgreementDialog);