import React, { useState, useEffect } from 'react';
// import { ToggleButton as OriginalToggleButton } from '@material-ui/lab';
import { Box } from '@material-ui/core';
import { useStyles } from "utils/theme";

export default function Toggle(props) {
    const classes = useStyles();
    const [selectedOption, setOption] = useState(props.default || null);
    useEffect(() => {
        setOption(props.default)
    }, [props.default])
    return (
        <Box className={classes.toggleBtnContainer}>
            {props.options && props.options.map((option, idx) => (
                // <OriginalToggleButton
                //     key={idx}
                //     type="radio"
                //     className={classes.toggleBtn}
                //     name={props.controlName || 'myToggle'}
                //     value={option.value}
                //     style={(selectedOption === option.value) ? {borderBottom:'3px solid #bd0912'}: {}}
                //     onChange={(e) => {
                //         setOption(e.currentTarget.value);
                //         props.triggerHandler(e.currentTarget.value)
                //     }}
                // >
                //     {option.name}
                // </OriginalToggleButton>
                <></>
            ))
            }
        </Box>
    );
};

