import { toast } from "react-toastify";
import Breadcrumb from "components/Breadcrumb";
import { Box, Grid, TextField, Button, Typography } from "@material-ui/core";
import { useForm } from "hooks/useForm";
import { withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import { SUCCESS_MESSAGE_FEEDBACK_FORM } from "constants/common";
import FeedbackService from "services/admin/masters/FeedbackService";

const feedbackServiceObj = new FeedbackService();

const Form = ({ history, ...props }) => {
    const classes = useStyles();
    const { params } = props.match;
    const labId = params?.labId;

    const initialFormData = { // used to initialize the data
        message: ''
    };
    const metaInfo = {
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "Feedback" }
        ]
    };


    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
    } = useForm({ // the hook we are going to create
        initialValues: initialFormData,//pass the initializing data 
        validations: { // all our validation rules go here
            message: {
                required: {
                    value: true,
                    message: 'Message is required',
                }
            },
        },
        onSubmit: () => {
            //trigger the actual post submit function from here 
            submitForm(data)
        }
    });

    const submitForm = async (data) => {
        let response;
        if (labId > 0) {
            data['lab_id'] = labId
            response = await feedbackServiceObj.submitFeedbackDetails(data);
        }
        if (response) {
            toast.success(SUCCESS_MESSAGE_FEEDBACK_FORM);
            history.push(`/labs`);
        }

    }
    return (
        <Grid container spacing={1} className={classes.outerContainer}>
            <Grid item xs={10}>
                <Breadcrumb data={metaInfo.breadcrumData} />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.contentRoot}>
                    <form onSubmit={handleSubmit} noValidate>
                    <Typography className={classes.inputLabel}>Message</Typography>
                        <TextField fullWidth
                            name="message"
                            variant="outlined"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.message || ''}
                            onChange={handleChange('message')}
                            error={(errors['message'] && errors['message'] !== undefined) ? true : false}
                            helperText={(errors['message'] && errors['message'] !== undefined) ? errors['message'] : null}
                        />

                        
                        <Grid item xs={12}>
                            <Box className={classes.masterBtn} display="flex" justifyContent='right'>
                                <Button className='add-btn' type="submit" >
                                    Submit
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(Form);