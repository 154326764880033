import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    HvGrid, HvBreadCrumb, HvButton, HvTypography, HvBox, HvContainer, HvSection, HvPagination, HvDialog,
    HvDialogTitle, HvDialogContent, HvDialogActions
} from "@hitachivantara/uikit-react-core";
import { Edit, Delete } from '@hitachivantara/uikit-react-icons';
import { useStyles } from "utils/theme";
import FAQService from "services/admin/masters/FAQService";
import { MSG_WARNING_DELETE_CATEGORY, MSG_WARNING_DELETE_QUESTION, MSG_DELETE_CATEGORY_SUCCESS, MSG_DELETE_QUESTION_SUCCESS } from "constants/admin/masters/faq";
import { buildQueryParams } from "utils/common";
import { showBanner } from "redux/actions/bannerActions";
import { useDispatch } from 'react-redux';

const faqServiceObj = new FAQService();

const List = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [faqData, setFaqData] = useState([]);
    const { url: masterUrl } = props.match;
    const userRole = masterUrl !== '/faq' ? true : false
    const pageSizeOptions = [5, 10, 20, 25, 50, 100];
    const [page, setPage] = useState(0);
    const [confirmCategoryDelete, setConfirCategoryDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [deleteFlag, setDeleteFlag] = useState('');
    const [pageSize, setPageSize] = useState(pageSizeOptions[0]);
    const metaInfo = {
        breadcrumData:
            [
                {
                    ariaLabel: 'Home',
                    label: 'Home',
                    path: '/labs/',
                    to: '/'
                },
                {
                    ariaLabel: 'FAQ',
                    label: 'FAQ',
                    path: '',
                    to: ''
                }
            ],
    };

    useEffect(() => {
        getAllFaqList();
    }, [pageSize]);

    const getAllFaqList = () => {
        let urlParams = { is_deleted: false }
        urlParams["limit"] = pageSize * ((pageSize + 1) - 1);
        urlParams["offset"] = 0;
        faqServiceObj.getAllFaqList(buildQueryParams(urlParams)).then(response => {
            if (response) {
                setFaqData(response.results);
            }
        }).catch(error => {
            console.log(error);
        })
    }
    const deleteItem = () => {
        deleteFlag == 'Category' ? deleteFaqCategory() : deleteFaqQuestion()
    }
    const deleteFaqCategory = () => {
        faqServiceObj.deleteFaqCategory(deleteId).then(response => {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: response.message || MSG_DELETE_CATEGORY_SUCCESS },
                    variant: "success"
                }
            }))
            setConfirCategoryDelete(false)
            getAllFaqList();
        }).catch(error => {
            console.log(error);
        })
    }

    const deleteFaqQuestion = () => {
        faqServiceObj.deleteFaqQuestion(deleteId).then(response => {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: response.message || MSG_DELETE_QUESTION_SUCCESS },
                    variant: "success"
                }
            }))
            setConfirCategoryDelete(false)
            getAllFaqList();
        }).catch(error => {
            console.log(error);
        })
    }

    const handleDeleteCategory = (event, item) => {
        event.stopPropagation();
        setDeleteId(item.id)
        setDeleteFlag('Category')
        setConfirCategoryDelete(!confirmCategoryDelete)

        // if (window.confirm(MSG_WARNING_DELETE_CATEGORY)) {
        //     deleteFaqCategory(item.id);
        // }
    }

    const handleDeleteQuestion = (event, item) => {
        event.stopPropagation();
        setDeleteFlag('Question')
        setDeleteId(item.id)
        setConfirCategoryDelete(!confirmCategoryDelete)
        // if (window.confirm(MSG_WARNING_DELETE_QUESTION)) {
        //     // make api call to delete the category and refresh the data
        //     deleteFaqQuestion(item.id);
        // }
    }

    const handleEditCategory = (event, item) => {
        event.stopPropagation();
        props.history.push(`${masterUrl}/edit/category/${item.id}`);
    }

    const handleEditQuestion = (event, item) => {
        event.stopPropagation();
        props.history.push(`${masterUrl}/edit/question/${item.id}`);
    }

    return (
        <>
            <HvGrid container spacing={1} className={classes.outerContainer}>
                <HvGrid item xs={10}>
                    <HvBreadCrumb aria-label="Breadcrumb"
                        listRoute={metaInfo.breadcrumData} />
                </HvGrid>
                <HvGrid style={{ padding: 0 }} item xs={2}>
                    {userRole && <HvTypography style={({ display: "flex", cursor: "pointer" })}>
                        <HvButton variant='primary' style={{ marginRight: 12 }} component={Link} to={`${masterUrl}/add/category`}>
                            Add Category
                        </HvButton>
                        <HvButton variant='primary' component={Link} to={`${masterUrl}/add/question`}>
                            Add Q&A
                        </HvButton>
                    </HvTypography>}
                </HvGrid>

                <HvGrid item xs={12} style={{ marginTop: 12 }}>
                    <HvBox className={classes.contentHvGrid}>
                        <HvGrid>{faqData.slice(pageSize * page, pageSize * (page + 1)).map((category) => (
                            <HvContainer key={category} style={{ paddingLeft: "10px", height: "100%" }} >
                                <HvGrid container spacing={1}>
                                    <HvGrid item xs={11}>
                                        <HvTypography variant="label"  >{category.name}</HvTypography>
                                    </HvGrid>
                                    <HvGrid style={{ padding: 1 }} item xs={1}>
                                        <HvTypography style={({ display: "flex", justifySelf: "end", cursor: "pointer" })}>
                                            {userRole && <Edit onClick={(event) => handleEditCategory(event, category)} />} {userRole && <Delete onClick={(event) => handleDeleteCategory(event, category)} />}

                                        </HvTypography>
                                    </HvGrid>
                                </HvGrid>
                                {
                                    category.questions.map((question) => (
                                        <HvBox>
                                            <HvSection
                                                expandable
                                                defaultExpanded={false}
                                                id={question.question}
                                                onToggle={function _a() { }}
                                                title={<HvGrid container spacing={1}>
                                                    <HvGrid item xs={11}>
                                                        <HvTypography  >{question.question}</HvTypography>
                                                    </HvGrid>
                                                    <HvGrid style={{ padding: 1 }} item xs={1}>
                                                        <HvTypography style={({ display: "flex", justifySelf: "end", cursor: "pointer" })}>
                                                            {userRole && <Edit style={{ marginLeft: '10px' }} onClick={(event) => handleEditQuestion(event, question)} />} {userRole && <Delete onClick={(event) => handleDeleteQuestion(event, question)} />}

                                                        </HvTypography>
                                                    </HvGrid>
                                                </HvGrid>}
                                            >


                                                <HvTypography>
                                                    <div className="content" style={{ width: "100%", height: "100%", padding: "10px" }}
                                                        dangerouslySetInnerHTML={{ __html: question.answer }}
                                                    />
                                                </HvTypography>
                                            </HvSection>
                                            <HvBox style={{ height: "5px" }}></HvBox>
                                        </HvBox>
                                    ))
                                }
                            </HvContainer>


                        ))}
                        </HvGrid>
                        <HvPagination
                            id="pagination"
                            pages={Math.ceil(faqData.length / pageSize)}
                            page={page}
                            canPrevious={page > 0}
                            canNext={page < Math.ceil(faqData.length / pageSize) - 1}
                            pageSize={pageSize}
                            pageSizeOptions={pageSizeOptions}
                            onPageChange={(value) => setPage(value)}
                            onPageSizeChange={(value) => setPageSize(value)}
                            labels={{ pageSizeEntryName: "items" }}

                        />



                    </HvBox>

                </HvGrid >
            </HvGrid >
            {
                confirmCategoryDelete ? <>
                    <HvDialog
                        disableBackdropClick
                        open={confirmCategoryDelete}
                        onClose={() => setConfirCategoryDelete(false)}
                        id="delete"
                    >
                        <HvDialogTitle variant="warning">Delete {deleteFlag} ?</HvDialogTitle>
                        <HvDialogContent indentContent>
                            {deleteFlag === 'Category' ? MSG_WARNING_DELETE_CATEGORY : MSG_WARNING_DELETE_QUESTION}
                        </HvDialogContent>
                        <HvDialogActions>
                            <HvButton id="apply" onClick={() => { deleteItem() }} variant="primaryGhost">
                                Yes
                            </HvButton>
                            <HvButton id="cancel" variant="primaryGhost" onClick={() => setConfirCategoryDelete(false)}>
                                No
                            </HvButton>
                        </HvDialogActions>
                    </HvDialog>
                </> : <></>
            }

        </>
    );
}

export default List;