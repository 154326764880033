import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#fff',
        border: '1px solid #ced4da',
        fontSize: 12,
        padding: '6px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            backgroundColor: '#fff',
        },
    },
}))(InputBase);

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#f5f5f5',
        position: 'relative',
        "& .MuiAppBar-colorPrimary": {
            backgroundColor: "#f5f5f5",
            boxShadow: 'none',
            borderBottom: '1px solid #c2c2c2',
            marginBottom: 24,
        },
        "& .MuiTab-root": {
            padding: '10px 24px 0px 24px'
        },
        "& .tabs-panel": {
            marginTop: '12px',
            borderRadius: '4px',
            "& .MuiBox-root": {
                padding: 0,
                fontSize: '12px',
                '@media(min-width:767px) and(max-width:802px)': {
                    paddingLeft: '40px',
                },
                "& .MuiTypography-root": {
                    "& .MuiBox-root": {
                        "& .MuiTypography-root": {
                            "& .MuiIconButton-root": {
                                padding: '0px',
                            },
                        }
                    }
                }
            }
        },
        "& .tabs-container": {
            marginTop: '8px',
        }
    },
    margin: {
        marginBottom: '12px',
    },
    tabs: {
        "& .MuiTabs-indicator": {
            backgroundColor: "#bd0912",
            border: '2px solid #bd0912'
        },
        "& .MuiButtonBase-root.MuiTab-root": {
            fontSize: 12,
            color: "#333333",
            "@media(max-width:932px)": {
                minWidth: '0px',
                padding: '8px 12px 0px 12px',
            },
            "& .MuiTab-wrapper": {
                flexDirection: 'row',
                justifyContent: 'center'
            }
        },
    },
    custom: {
        color: "#000",
        fontWeight: "bold",
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 12,
    },
    collapseContainer: {
        display: 'flex',
        padding: '0px !important',
        height: '30px',
        overflow: 'hidden',
        margin: '10px 0px 0px 0px',
        " & .MuiBox-root": {
            " & .MuiBox-root": {
                " & .MuiLink-root": {
                    paddingBottom: 32,
                }
            }
        },
        "@media(max-width:920px)": {
            height: 'auto',
            overflow: 'auto',
        }
    },
    labContainer: {
        borderBottom: '1px solid #707070',
        " & .MuiIconButton-root": {
            position: 'relative',
            left: '10px'
        }
    },
    menuItem: {
        fontSize: 12,
    },
    expandContainer: {
        display: 'flex',
        padding: '0px !important',
        marginTop: 10,
        marginBottom: 10,
    },
    linkcontainer: {
        padding: '0px',
        display: 'flex',
        justifyContent: 'space-between',
        width: '88%',
        '@media(max-width:920px)': {
            display: 'block'
        },
    },
    container: {
        backgroundColor: '#fff',
        boxShadow: '1px 2px 5px 1px #ccc',
        margin: '0px 0px 16px 0px',
        borderRadius: '4px',
        display: 'flex',
        " & .MuiIconButton-root": {
            padding: '4px 0px 0px 12px'
        },
        "& .MuiIconButton-root:hover": {
            background: 'none',
        },
    },
    readMore: {
        fontSize: '12px',
        paddingBottom: '6px',
        color: '#1874CD',
        cursor: 'pointer',
        textDecoration: "underline",
        display: 'inline-block',
        justifyContent: 'flex-end'
    },
    collapseAccordion: {
        color: '#bd0912',
        position: 'relative',
        bottom: '4px',
        cursor: 'pointer',
        "@media(max-width:920px)": {
            display: 'none',
        }
    },
    expandAccordion: {
        transform: 'rotate(180deg)',
        color: '#bd0912',
        position: 'relative',
        top: '8px',
        cursor: 'pointer',
        "@media(max-width:920px)": {
            display: 'none',
        }
    },
    descriptionBlock: {
        width: '88%',
        padding: '8px 12px 8px 0px',
        fontSize: 12,
        '@media(min-width:766px) and (max-width:958px)': {
            width: '85%',
        },
        " & .description":{
            " & >p":{
                margin:0,
            }
        }
    },
    durationIcon: {
        color: '#bd0912',
        width: 20,
        height: 20,
    },
    duration: {
        fontSize: 12,
        color: '#2a2c2e',
        margin: 'auto 4px'
    },
    runLabBtn: {
        background: '#bd0912',
        color: '#fff',
        borderRadius: 4,
        width: 104,
        padding: '2px 0px 2px 0px',
        cursor: 'pointer',
        marginBottom:2,
    },
    btnLabel: {
        fontSize: 12,
        textAlign: 'center',
        padding: '3px 0px 3px 0px',
    },
    playBtn: {
        width: '0',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: '12px solid #fff',
        transform: 'rotate(90deg)',
        position: 'absolute',
        marginLeft: 10,
        marginTop: 6
    },
    accordian: {
        width: '12%',
        textAlign: 'right',
    },
    select: {
        minWidth: 225,
    },
    noRecords: {
        textAlign: 'center',
        fontSize: 16,
        color: '#71757b',
    },
    readOrHide:{
        color:'#1874CD',
        cursor:'pointer',
        textDecoration:'underline',
        marginLeft:4,
    },
    menuPaper:{
        maxHeight: '60% !important',
    }
}));

