import React, { memo, useState, useEffect, useId } from 'react';
import {
    HvGlobalActions, HvGrid, HvInput, HvTabs, HvTab, HvDropdown,
    HvTableContainer, HvTable,
    HvTableHead, HvTableRow,
    HvTableHeader, HvTableBody,
    HvTableCell, HvPagination, HvTooltip, useHvPagination, useHvData, HvEmptyState, hvTextColumn, useHvSortBy, HvButton, HvBox,
    HvDialog,
    HvDialogActions,
    HvDialogContent,
    HvDialogTitle,
    HvTextArea,
    HvTypography,
} from '@hitachivantara/uikit-react-core';
import { MSG_WARNING_DELETE, MSG_DELETE_SUCCESS, MSG_WARNING_UNDO_DELETE, MSG_UNDO_RECORD } from "constants/admin/masters/groups";
import { Add, Edit, Delete, Ban, Undo } from '@hitachivantara/uikit-react-icons';
import {classes} from "./UserListStyle";
import { buildQueryParams } from "utils/common";
import _ from "lodash";
import { toast } from "react-toastify";
import UserService from 'services/admin/masters/UserService';
import GroupService from 'services/admin/masters/GroupService';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showBanner } from 'redux/actions/bannerActions';
import { SHOW_BANNER } from 'constants/common';

const userServiceObj = new UserService();
const groupServiceObj = new GroupService();

const UserListComp = memo((props) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    var initTabValue = searchParams.get('tabValue') ? searchParams.get('tabValue') : 0
    const [tabValue, setTabValue] = useState(parseInt(initTabValue));
    const [userStatusValue, setUserStatusValue] = useState("Active");
    const [groupStatusValue, setGroupStatusValue] = useState("Active");
    const [totalrecords, setTotalRecords] = useState();
    const [userSearchBarValue, setUserSearchBarValue] = useState('');
    const [groupSearchBarValue, setGroupSearchBarValue] = useState('');
    const [columns, setColumns] = useState([]);
    const { url: masterUrl } = props.match;
    const masterUrlGroups = masterUrl.replace("users", "groups")
    const descId = useId();
    const [open, setOpen] = useState(false);
    const [rerenderFlag, setRerenderFlag] = useState(false);
    const [data, setData] = useState();
    // const classes = useStyles();
    const [dropdownMenuItems, setDropdownMenuItems] = useState([
        { label: "Active", value: "Active", selected: true },
        { label: "Deleted", value: "Deleted" },
        { label: "All", value: "All" },
    ])
    const dispatch = useDispatch();
    const handleUserSearch = (e) => {
        setUserSearchBarValue(e?.target.value)

    }
    const handleGroupSearch = (e) => {
        setGroupSearchBarValue(e?.target.value)
    }

    const handleChange = (e, newValue) => {

        setTabValue(newValue);
        setUserSearchBarValue('');
        setGroupSearchBarValue('');
        setUserStatusValue("Active");
        setGroupStatusValue("Active");
        setDropdownMenuItems(
            [
                { label: "Active", value: "Active", selected: true },
                { label: "Deleted", value: "Deleted" },
                { label: "All", value: "All" },
            ]
        )

    }

    const handleRefresh = (e) => {
        setRerenderFlag(prevState => !prevState);
    };
    let urlParams = {};

    const { getTableProps, getTableBodyProps, prepareRow, headers, page, gotoPage, state: { pageSize, pageIndex, sortBy }, getHvPaginationProps } =
        useHvData(
            {
                columns,
                data,
                defaultColumn: {
                    Cell: ({ value }) => (value ?? "—"),
                },
                manualPagination: true,
                manualSortBy: true,
                disableCreateExpandButton: true,
                pageCount: totalrecords
            },
            useHvSortBy,
            useHvPagination
        );

    useEffect(() => {
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        let params = {
            tabIndex: 1
        }
        var sortByValue = "-id";

        if (sortBy?.length) {
            sortByValue = sortBy[0].desc ? "-" + sortBy[0].id : sortBy[0].id;
        }


        urlParams = { ordering: sortByValue };
        urlParams["limit"] = pageSize;
        urlParams["offset"] = pageSize * ((pageIndex + 1) - 1);
        if (tabValue === 0) {
            var statusFilter = userStatusValue
            var searchBarValue = userSearchBarValue
        }
        else {
            var statusFilter = groupStatusValue
            var searchBarValue = groupSearchBarValue
        }
        if (!_.isEmpty(searchBarValue)) {

            urlParams[columns[0]?.accessor] = searchBarValue
        }

        if (statusFilter != "All") {
            urlParams["is_deleted"] = statusFilter == "Deleted" ? true : false;
        }

        if (tabValue === 0) {
            userServiceObj.getAllUsers(buildQueryParams(urlParams)).then((response) => {
                if (response?.results) {
                    setData(response?.results);
                    setTotalRecords(Math.ceil(response?.count / pageSize));
                }

            }).catch(error => {
                console.log(error)
            });
        } else if (tabValue === 1) {
            groupServiceObj.getAll(buildQueryParams(urlParams)).then((response) => {
                if (response?.results) {
                    setData(response?.results);
                    setTotalRecords(Math.ceil(response?.count / pageSize));
                }

            }).catch(error => {
                console.log(error)
            });

        }
        setColumns([

            tabValue === 0 ?
                [
                    hvTextColumn({
                        Header: "Full Name",
                        accessor: "full_name",
                        cellType: "alpha-numeric",
                    }),

                    hvTextColumn({
                        Header: "Email",
                        accessor: "email",
                        cellType: "alpha-numeric",

                    })]
                :
                [
                    hvTextColumn({
                        Header: "Name",
                        accessor: "name",
                        cellType: "alpha-numeric",
                    }),

                    hvTextColumn({
                        Header: "Description",
                        accessor: "description",
                        cellType: "alpha-numeric",

                    }),

                    hvTextColumn({
                        Header: "Actions",
                        Cell: (cellData) => {
                            if (!_.isEmpty(cellData) && cellData.row.original.is_deleted === false) {

                                return (
                                    <div style={({ display: "flex", cursor: "pointer" })}>
                                        <HvTooltip title="Edit Group"><HvButton variant='primaryGhost' onClick={() => redirectTo(`${masterUrlGroups}/edit/${cellData.row.original.id}`)}><Edit /></HvButton></HvTooltip>
                                        <HvTooltip title="Delete Group"><HvButton variant='primaryGhost' onClick={(e, rowData) => {
                                            if (window.confirm(MSG_WARNING_DELETE.replace("ITEM_NAME", cellData.row.original.id))) {
                                                groupServiceObj.delete(cellData.row.original.id).then((response) => {
                                                    toast.success(response.message || MSG_DELETE_SUCCESS);
                                                    handleRefresh();
                                                });
                                            }
                                        }
                                        }> <Delete /></HvButton></HvTooltip>
                                    </div >
                                )
                            }
                            else {
                                return (
                                    <div style={({ display: "flex", cursor: "pointer" })}>
                                        <HvTooltip title="Undo Deleted Record"><HvButton variant='primaryGhost' onClick={(e, rowData) => {
                                            if (window.confirm(MSG_WARNING_UNDO_DELETE.replace("ITEM_NAME", cellData.row.original.id))) {
                                                groupServiceObj.undoDelete(cellData.row.original.id).then((response) => {
                                                    toast.success(response.message || MSG_UNDO_RECORD);
                                                    handleRefresh();
                                                });
                                            }
                                        }
                                        }> <Undo /></HvButton></HvTooltip>
                                    </div >
                                )
                            }
                        }

                    })

                ]


        ][0]);

    }, [pageSize, pageIndex, sortBy, userStatusValue, groupStatusValue, userSearchBarValue, groupSearchBarValue, tabValue, rerenderFlag])

    const EmptyRow = () => (
        <HvTableRow>
            <HvTableCell colSpan={100} style={{ height: 50 }}>
                <HvEmptyState message="No data to display" icon={<Ban role="presentation" />} />
            </HvTableCell>
        </HvTableRow>
    );

    const rowRenderer = (pages) => {

        return pages.map((row, index) => {
            prepareRow(row);
            return (
                <React.Fragment key={row.id}>
                    <HvTableRow
                        key={row.Header}
                        {...row.getRowProps({
                            "aria-rowindex": index,
                        })}
                    >
                        {row.cells.map((cell) => (
                            < HvTableCell key={cell.Header} {...cell.getCellProps()} >
                                {cell.render("Cell")}

                            </HvTableCell>
                        ))}
                    </HvTableRow>
                </React.Fragment >
            );
        });
    };

    const redirectTo = (url) => {
        props.history.push(url);
    }

    const DialogForm = () => (
        <HvGrid container rowSpacing="xs">
            <HvGrid item xs={12}>
                <HvInput  name="full_name" label="name" defaultValue="" placeholder='Enter Name' />
            </HvGrid>
            <HvGrid item xs={12}>
                <HvInput type='email' required name="email" label="Enter Email" autoFocus />
            </HvGrid>
        </HvGrid>
    );

    function validateEmail(email) {
        // Regular expression pattern for more comprehensive email validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        
        return emailPattern.test(email);
    }
    const createUser = (userData)=>{
        const validEmail = validateEmail(userData?.email)
        if(validEmail){
            const updatedFormData = {
                ...userData,
                ["password"]: "pravin12345",
                ["groups"] : [1]
                
            }
            userServiceObj.createUser(updatedFormData).then((response)=>{
                if(response){
                    dispatch(showBanner({
                        payload: {
                          showBanner: true,
                          label: { message: "User Created Successfully." },
                          variant: "success"
                        }
                      }))
                      setOpen(false);
                }
            }).catch((err)=>{
                console.log(err)
            })
        }else{
            dispatch(showBanner({
                payload: {
                  showBanner: true,
                  label: { message: "Please Enter Valid Email." },
                  variant: "error"
                }
              }))
        }
    }
    return (
        <HvGrid container>
            <HvGrid item xs={12}>
                <HvGlobalActions
                    className={classes.globalAction}
                    title={<HvTabs onChange={handleChange} value={tabValue}>
                        <HvTab className="css-1lcbxgm" label="User List" />
                        <HvTab className="css-1lcbxgm" label="Group List" />
                    </HvTabs>} />
            </HvGrid>
            {
                open ? <>
                    <HvDialog
                        disableBackdropClick
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-describedby={descId}
                    >
                        <HvDialogTitle>Create User</HvDialogTitle>
                        <HvDialogContent>
                            <div id={descId} style={{ marginBottom: 10 }}>
                                Fill the following form to create a new User.
                            </div>
                            <form
                                id="create-User"
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    const formData = new FormData(event.currentTarget);
                                    createUser(Object.fromEntries(formData.entries()))
                                }}
                            >
                                <DialogForm />
                            </form>
                        </HvDialogContent>
                        <HvDialogActions>
                            <HvButton type="submit" form="create-User">
                                Create
                            </HvButton>
                            <HvButton variant="secondaryGhost" onClick={() => setOpen(false)}>
                                Cancel
                            </HvButton>
                        </HvDialogActions>
                    </HvDialog>
                </> : <></>
            }
            {
                tabValue === 0 ?
                    <HvGrid container item xs={12}>

                        <HvGrid item sm={6} md={4}>
                            <HvInput type='search' id='userSearch' placeholder='Search User Full Name' value={userSearchBarValue} onChange={handleUserSearch} />
                        </HvGrid>
                        <HvGrid item sm={4} md={2}>
                            <HvDropdown id="userStatus" selected={userStatusValue} values={dropdownMenuItems} onChange={(e) => { setUserStatusValue(e?.label) }} />
                        </HvGrid>
                        <HvGrid item sm={4} md={6} xs={2} lg={6} xl={6}>
                            <HvBox component='div' style={{ display: "flex", flexDirection: "row-reverse", paddingBottom: "32px" }}>
                                <HvButton id="AddLab" variant="primary" onClick={() => setOpen(true)}><Add />Add User</HvButton>
                            </HvBox>
                        </HvGrid>
                        <HvGrid container item xs={12}> <HvGrid item xl={12} lg={12} md={6} sm={12} xs={1}>
                            <HvTableContainer>
                                <HvTable
                                    {...getTableProps({
                                        "aria-rowcount": data?.length,
                                        caption: "Table Caption",
                                    })}
                                    style={{
                                        width: "100%",
                                        position: "relative"
                                    }}
                                >
                                    <HvTableHead>
                                        <HvTableRow>
                                            {headers.map((col) => (
                                                <HvTableHeader key={col.Header} {...col.getHeaderProps()}>
                                                    {col.render("Header")}
                                                </HvTableHeader>
                                            ))}
                                        </HvTableRow>
                                    </HvTableHead>
                                    <HvTableBody {...getTableBodyProps()}>
                                        {page.length === 0 ? <EmptyRow /> : rowRenderer(page)}
                                    </HvTableBody>
                                </HvTable>
                            </HvTableContainer>
                            <HvPagination
                                {...getHvPaginationProps()} />
                        </HvGrid>
                        </HvGrid>
                    </HvGrid>
                    :
                    <HvGrid container item xs={12}>
                        <HvGrid item sm={6} md={4}>
                            <HvInput type='search' id='groupSearch' placeholder='Search Group Name' value={groupSearchBarValue} onChange={handleGroupSearch} />
                        </HvGrid>
                        <HvGrid item sm={4} md={2}>
                            <HvDropdown id="groupStatus" selected={groupStatusValue} values={dropdownMenuItems} onChange={(e) => { setGroupStatusValue(e?.label) }} />
                        </HvGrid>
                        <HvGrid item sm={4} md={6} xs={2} lg={6} xl={6}>
                            <HvBox component='div' style={{ display: "flex", flexDirection: "row-reverse", paddingBottom: "32px" }}>
                                <HvButton id="AddLab" variant="primary" onClick={() => redirectTo(`${masterUrlGroups}/add`)}><Add />Add New Group</HvButton>

                            </HvBox>
                        </HvGrid>
                        <HvGrid container item xs={12}> <HvGrid item xl={12} lg={12} md={6} sm={12} xs={1}>
                            <HvTableContainer>
                                <HvTable
                                    {...getTableProps({
                                        "aria-rowcount": data?.length,
                                        caption: "Table Caption",
                                    })}
                                    style={{
                                        width: "100%",
                                        position: "relative"
                                    }}
                                >
                                    <HvTableHead>
                                        <HvTableRow>
                                            {headers.map((col) => (
                                                <HvTableHeader key={col.Header} {...col.getHeaderProps()}>
                                                    {col.render("Header")}
                                                </HvTableHeader>
                                            ))}
                                        </HvTableRow>
                                    </HvTableHead>
                                    <HvTableBody {...getTableBodyProps()}>
                                        {page.length === 0 ? <EmptyRow /> : rowRenderer(page)}
                                    </HvTableBody>
                                </HvTable>
                            </HvTableContainer>
                            <HvPagination
                                {...getHvPaginationProps()} />
                        </HvGrid>
                        </HvGrid>
                    </HvGrid>
            }
            

        </HvGrid >
        
    );
});


export default UserListComp