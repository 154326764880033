import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { LANGUAGE_BAD_SSO_URL, LANGUAGE_GENERAL_SSO_ERROR } from 'constants/login';
import LoginService from "services/LoginService";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { showLoader } from "redux/actions/commonActions";
const loginServiceObj = new LoginService();
function EmailAuthenticate({ location, history }) {
    const token = location && location.search && location.search.substring(7);
    // const payload = { returnurl: encodeBase64(baseUrl() + from.pathname) };
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(showLoader(true));
        const payload = {
            token: token
        }
        loginServiceObj.authenticateEmail(payload).then(response => {
            if (response) {
                history.push('/email-login');
                dispatch(showLoader(false));
                // window.location.href = response.redirect_url;
            } else {
                toast.error(LANGUAGE_BAD_SSO_URL);
            }
        }).catch(e => {
            toast.error(LANGUAGE_GENERAL_SSO_ERROR);
            history.push('/');
        });
    });


    return <div style={{ height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
        Authenticating email...
    </div>
}

export default withRouter(EmailAuthenticate);