import React, { useState, useEffect } from 'react';
import { HvProgressBar, HvTypography } from '@hitachivantara/uikit-react-core';
import PropTypes from 'prop-types';

const Loader = props => {
    const [remainingTime, setRemainingTime] = useState(props.progressBarTime);
    const [progressBarValue, setProgressValue] = useState();
    const [progressBarStatus, setProgressBarStatus] = useState();
    useEffect(() => {
        if (remainingTime > 0) {
            const timer = setInterval(() => {
                setRemainingTime(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(timer);
        } 
        
    }, [remainingTime]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    }
    useEffect(() => {
        setProgressBarStatus(props.progressBarStatus);
        setProgressValue(props.progressBarValue);
    }, [props.progressBarValue, props.progressBarStatus])
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                marginBottom: 40,
                marginTop: 20
            }}
        >
            {/* {
                remainingTime ? <HvTypography variant="body1">
                    Total Time : {formatTime(remainingTime)}
                </HvTypography> : <></>
            } */}
            <HvTypography variant="label">
                {props.progressBarStatusMsg}
            </HvTypography>
            <HvProgressBar aria-label="progressBarStatus" value={progressBarValue} status={progressBarStatus}></HvProgressBar>
        </div>
    );
};

Loader.propTypes = {
    progressBarValue: PropTypes.any,
    progressBarMessage: PropTypes.any,
    progressBarTime: PropTypes.any,
    progressBarStatusMsg: PropTypes.any,
    progressBarStatus: PropTypes.any
};

export default Loader;