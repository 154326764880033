import { CHANGE_THEME } from "constants/common";
const initialState = {
    theme : "dawn"
}

const themeReducer = (state = initialState, action ) => {
    const {type, payload} = action

    switch (type) {
        case CHANGE_THEME:
             return{
                ...state,
                theme : payload
             }   
        default:
            return state
            
    }

}

export default themeReducer;