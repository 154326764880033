import React, { useState } from "react";
import { HvButton, HvDialogActions, HvDialogContent, HvDialogTitle, HvTextArea, HvStack, HvTypography, HvFileUploader, HvBox } from "@hitachivantara/uikit-react-core";
import { makeStyles } from "@material-ui/core";
import { HvDrawer } from "@hitachivantara/uikit-react-core";
import LabService from "services/LabService";
import { useDispatch } from 'react-redux';
import { showBanner } from 'redux/actions/bannerActions';
import { Rating } from '@mui/material';
import _ from 'lodash'
import { showLoader } from "redux/actions/commonActions";


const FeedbackDrawer = (props) => {

    const { handleDrawerState, openDrawer, labId } = props
    const labServiceObj = new LabService();
    const [feedback, setFeedback] = useState();
    const [rating, setRating] = useState(0)
    const [fileList, setFileList] = useState([])
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const dispatch = useDispatch();
    const drawerWidth = "70%";

    const useStyles = makeStyles(() => ({
        drawerPaper: {
            width: `calc(100% - ${drawerWidth})`,
            overflow: "unset", // we want scroll content, not scroll paper
            padding: "12px",
            ' & .HvDialogTitle-root:not(.fullscreen)': {
                flex: 'none'
            },
            ' & .HvTypography-xxsTitle': {
                font: 'normal normal 600 20px/24px Open Sans',
                letterSpacing: '0px',
                color: '#414141',
                opacity: 1,

            }
        },
        drawerContent: {
            // maximize space for content
            flex: 1,
            overflow: "auto",
            '&::-webkit-scrollbar': {
                width: '4px'
            },
            '&::-webkit-scrollbar-thumb': {
                background: '#CCCED0 0% 0% no-repeat padding-box',
                borderRadius: '2px',
            }
        },
        tabs: {
            borderBottom: '2px solid #E8E8E8',
            margin: '20px 20px 0px 20px',
            '& .HvTab-root::after': {
                backgroundColor: 'unset'
            }
        },
        action: {
            flex: '1',
            height: '72px'
        }
    }));
    const classes = useStyles();

    const submitFeedback = () => {
        if (_.isEmpty(feedback)) {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "Feedback can not be empty." },
                    variant: "error"
                }
            }))
        } else {
            const payload = {
                lab_id: labId,
                message: feedback,
                rating: _.isNull(rating) ? 0 : rating
            };

            if (fileList.length > 0) {
                payload.documents = uploadedDocuments
            }
            labServiceObj.submitFeedback(payload).then((response) => {
                dispatch(showLoader(true));
                if (response?.id) {
                    dispatch(showBanner({
                        payload: {
                            showBanner: true,
                            label: { message: "Thank for your feedback." },
                            variant: "success"
                        }
                    }))
                    dispatch(showLoader(false));
                    props.handleDrawerState(false);
                }

            }).catch((error) => {
                dispatch(showLoader(false));
                console.log(error)
            })
        }

    }

    const assignRating = (event, value) => {
        event?.preventDefault();
        setRating(value)
    }

    const uploadFeedBackImage = (e) => {
        const fileData = new FormData();
        fileData.append("file", e[0]);
        if (e[0]?.size > 10000000) {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "File Size is greater than 10MB." },
                    variant: "error"
                }
            }))
        } else {
            labServiceObj.uploadFeedBackImage(fileData).then((response) => {
                if (response.data) {
                    e[0].resource_id = response.data?.id
                    setFileList([...fileList, e[0]])
                    setUploadedDocuments([...uploadedDocuments, response.data?.id])
                }
            }).catch((error) => {
                dispatch(showLoader(false));
                console.log(error)
            })
        }

    }

    const removeFeedBackImage = (e) => {
        const fileImage = fileList.find((element) => element?.id === e?.id)
        if (fileImage) {
            dispatch(showLoader(true));
            labServiceObj.deleteFeedBackImage(fileImage.resource_id).then((response) => {
                dispatch(showLoader(false));
                setFileList(fileList.filter((element) => element.id !== e?.id))
                setUploadedDocuments(uploadedDocuments.filter((element) => element !== fileImage.resource_id))
            }).catch((error) => {
                dispatch(showLoader(false));
                console.log(error)
            })
        }
    }
    return (
        <div >
            <HvDrawer
                id="drawer-test"
                open={openDrawer}
                PaperProps={{
                    className: classes.drawerPaper,
                    component: 'aside'
                }}
                style={{ overflow: "scroll" }}
                onClose={() => handleDrawerState(false)}
                hideBackdrop
            >
                <HvTypography variant="xsTitle" style={{ paddingLeft: "15px", paddingTop: "10px" }}>Feedback</HvTypography>
                <HvDialogContent >
                    <HvStack direction={"column"} spacing="xs">
                        <HvBox>
                            <Rating style={{ width: "max-content", paddingTop: "10px" }} defaultValue={rating} onChange={(event, newValue) => assignRating(event, newValue)} precision={1} readOnly={false} />
                        </HvBox>
                        <HvBox><HvTextArea id="main" label="Message" placeholder="Enter text" rows={window.screen.height > 780 ? 13 : 5} onChange={(e) => { setFeedback(e.target.value) }} /></HvBox>
                        <HvBox><div style={{ overflow: "scroll", height: "200px" }}> <HvFileUploader acceptedFiles={[
                            '.jpg',
                            '.jpeg',
                            '.png'
                        ]} fileList={fileList}
                            labels={{
                                sizeWarning: 'Maximum file size:',
                                dropzone: "Upload"
                            }}
                            maxFileSize={10000000}
                            onFileRemoved={(e) => { removeFeedBackImage(e) }}
                            onFilesAdded={(e) => {
                                uploadFeedBackImage(e)
                            }} /></div></HvBox>

                    </HvStack>
                </HvDialogContent>
                <HvDialogActions className={classes.action}>
                    <HvBox display="flex">
                        <HvButton variant="secondary" onClick={() => { props.handleDrawerState(false) }}>Cancel</HvButton>
                        <HvButton variant="primary" style={{ marginLeft: '10px' }} onClick={submitFeedback}>Submit</HvButton>
                    </HvBox>
                </HvDialogActions>
            </HvDrawer>
        </div>
    );
}

export default FeedbackDrawer;