import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { HvBox, HvButton, HvGrid, HvPanel, HvTextArea } from '@hitachivantara/uikit-react-core';
import CommonService from 'services/CommonService';
import { showBanner } from 'redux/actions/bannerActions';
import { useDispatch } from 'react-redux';

const DynamicBanner = props => {
    const [bannerMessage, setBannerMessage] = useState('');
    const [enableDelete, setEnableDelete] = useState(true);
    const [bannerId, setBannerId] = useState();
    const commonServiceObj = new CommonService();
    const dispatch = useDispatch();
    
    const sendBannerMessage = () => {
        const payload = {
            message: bannerMessage
        };
        commonServiceObj.sendBannerMessage(payload)
            .then((response) => {
                if (response) {
                    dispatch(showBanner({
                        payload: {
                            showBanner: true,
                            label: { message: "Banner saved." },
                            variant: "success"
                        }
                    }));
                }
                setEnableDelete(!enableDelete);
                setBannerMessage("");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const removeBannerMessage = () =>{
        commonServiceObj.removeBannerMessage(bannerId).then((response)=>{
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "Banner deleted." },
                    variant: "success"
                }
            }));
            setEnableDelete(!enableDelete);
            setBannerMessage("");
        }).catch((error)=>{
            console.log(error);
        })
    }
    const handleSubmit = (event) => {
        event.preventDefault();  
        sendBannerMessage();
    };

    useEffect(()=>{
        commonServiceObj.getBannerMessage().then((response)=>{
            if(response){
                setEnableDelete(!enableDelete);
                setBannerMessage(response?.message);
                setBannerId(response?.id)
            }
        }).catch((error)=>{
            console.log(error);
        })
    },[])

    return (
        <div>
            <HvPanel>
                <HvGrid container>
                    <HvGrid item xs={12}>
                        <form onSubmit={handleSubmit}>
                            <HvTextArea
                                placeholder="Enter Message"
                                name="bannerMessage"
                                rows={10}
                                required
                                label="Banner Description"
                                onChange={(event) => setBannerMessage(event.target.value)}
                                value={bannerMessage}
                            />
                            <HvBox style={{ display: "flex", gap: "15px" }}>
                                <HvButton variant="primary" type="submit">Save</HvButton>
                                <HvButton variant="primary" onClick={removeBannerMessage} disabled={enableDelete}>Delete</HvButton>
                            </HvBox>
                            
                        </form>
                    </HvGrid>
                </HvGrid>
            </HvPanel>
        </div>
    );
};

DynamicBanner.propTypes = {
    // You can define prop types here if needed
};

export default DynamicBanner;
