import NetworkService from "services/NetworkService";
import { METHOD_LIST, CLOUD_TYPE_LIST, CONSOLE_TYPE_LIST, TEMPLATE_LIST, LAB_CONSOLE_ACCESS_TYPE, VM_ACCESS, GET_ALL_TEMPLATE_VM } from "constants/admin/masters/console";

class ConsoleService {
    getAll = (urlParams) => {
        return NetworkService
            .get(`${METHOD_LIST}?${urlParams}`)
            .then((response) => response.data);
    };
    getById = (params) => {
        return NetworkService
            .get(`${METHOD_LIST}${params.id}/`)
            .then((response) => response.data);
    };
    create = (data) => {
        return NetworkService
            .post(METHOD_LIST, data)
            .then((response) => response.data);
    };
    update = (data, id) => {
        return NetworkService
            .patch(`${METHOD_LIST}${id}/`, data)
            .then((response) => response.data);
    };
    delete = (id) => {
        return NetworkService
            .delete(`${METHOD_LIST}${id}/`)
            .then((response) => response.data);
    }

    getCloudTypeList = () => {
        return NetworkService
            .get(`${CLOUD_TYPE_LIST}`)
            .then((response) => response.data);
    }
    getConsoleTypeList = () => {
        return NetworkService
            .get(`${CONSOLE_TYPE_LIST}`)
            .then((response) => response.data);
    }
    getTemplateList = () => {
        return NetworkService
            .get(`${TEMPLATE_LIST}`)
            .then((response) => response.data);
    }
    getLabConsoleAccessType = () => {
        return NetworkService
            .get(`${LAB_CONSOLE_ACCESS_TYPE}`)
            .then((response) => response.data);
    }
    getALLVmAccess = (id)=>{
        return NetworkService.get(`${VM_ACCESS}?lab=${id}`).then((response)=> response.data)
    }
    getAllTemplateVM = (id)=>{
        return NetworkService.get(`${GET_ALL_TEMPLATE_VM}?template_id=${id}`).then((response)=>response.data)
    }
    setAllVmAcces = (payload)=>{
        return NetworkService.post(`${VM_ACCESS}`,payload).then((response)=> response.data)
    }
    updateAllVmAcces = (payload,id)=>{
        return NetworkService.patch(`${VM_ACCESS}${id}/`,payload[0]).then((response)=> response.data)
    }
}

export default ConsoleService;

