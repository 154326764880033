import React, { useEffect, useState } from 'react';
import { MenuItem, TextField, FormHelperText, ListItemText, Checkbox, makeStyles } from "@material-ui/core";
import { Search as SearchIcon, Clear as ClearIcon } from "@material-ui/icons";
import _ from 'lodash';

const useStyles = makeStyles({
    mainWrapper: {
        position: 'relative',
        minWidth: '250px',
        width: '21%',
        borderRadius: '5px',
        margin: '8px',
        float: 'left',
        " & .MuiFormControl-root": {
            margin: '4px 0px 4px 6px',
        },
        " & .MuiOutlinedInput-adornedEnd": {
            paddingRight: 18,
            " & .MuiSvgIcon-root": {
                left: 12,
                position: 'relative',
                color: '#bd0912',
            }
        },

    },
    searchWrapper: {

    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: `rgba(0,0,0,0.5)`,
        zIndex: 2,
        cursor: 'pointer'
    },
    text: {
        position: 'absolute',
        top: '50%',
        left: '20%',
        fontSize: '15px',
        color: 'white',
    },
    itemList: {
        minHeight: '200px',
        height: 'auto',
        overflow: 'auto',
        " & .Mui-checked": {
            color: '#bd0912'
        }
    },
    itemHorizontalList: {
        marginTop: 2,
        marginLeft: 24,
        display: 'flex',
        " & .Mui-checked": {
            color: '#bd0912'
        },
        " & .MuiMenuItem-root": {
            marginRight: 8,
        },

    },
    errorMessage: {
        padding: '5px'
    },
    clearIcon: {
        cursor: 'pointer'
    },
    menuItem: {
        fontSize: 14,
        padding: '6px 12px 6px 12px ',
        " & .Mui-checked": {
            padding: 6,
            " & .MuiIconButton-label": {
                " & .MuiSvgIcon-root": {
                    color: '#bd0912'
                }
            }
        },
        " & .MuiCheckbox-root": {
            padding: '0px 4px 0px 0px',
        }
    },
    checkboxItem: {
        padding: 0,
        width: 'max-content',
        " & .MuiTypography-root": {
            fontSize: 14,
        }
    },
    checkbox: {
        padding: '0px 2px 0px 0px',
    },
})

export default function MultiSelect({ title, data, isSearchHidden, showCheckboxesHorizontally, error, ...props }) {
    const classes = useStyles();
    const { handleBlur } = props;
    const [search, setSearch] = useState('');
    const [checked, setChecked] = useState([]);
    const [disabledControl, setDisabledControl] = useState(false);

    useEffect(() => {
        const selectedItems = _.union(props?.selected, props?.alwaysSelected);
        setChecked(selectedItems)

    }, [props?.selected, props?.alwaysSelected]);

    useEffect(() => {

        if (props?.disabled === true) {
            setChecked([]);//blank all checkboxes 
            setDisabledControl(true);
        } else {
            setDisabledControl(false);
        }

    }, [props?.disabled])

    const handleCheck = (selectedOption) => {
        const checkedData = [...checked];
        if (!checkedData.includes(selectedOption)) {
            checkedData.push(selectedOption);
        } else {
            if (!props?.alwaysSelected?.includes(selectedOption)) {
                const index = checkedData.indexOf(selectedOption);
                if (index > -1) {
                    checkedData.splice(index, 1);
                }
            }
        }
        setChecked([
            ...checkedData
        ]);
        props?.onChange(props.name, checkedData);//invoke the parent
    }

    const list = data && data?.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
    return (
        <div className={!isSearchHidden ? classes.mainWrapper : classes.searchWrapper} style={isSearchHidden ? {} : { border: `1px solid ${(error) ? 'red' : ''}` }} >

            {
                (disabledControl) ? (
                    <div className={classes.overlay} >
                        <div className={classes.text} >This control is disabled</div>
                    </div>
                ) : null
            }

            {!isSearchHidden &&
                <div className={classes.searchBox} >
                    <TextField
                        size="small"
                        placeholder={`Search ${title} here`}
                        variant="outlined"
                        InputProps={{
                            endAdornment: search ? (
                                <ClearIcon className={classes.clearIcon} onClick={() => setSearch('')} />
                            ) : <SearchIcon />
                        }}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            }
            <div className={!showCheckboxesHorizontally ? classes.itemList : classes.itemHorizontalList}>
                {
                    list && list.map((row, index) => (
                        <MenuItem className={classes.checkboxItem} key={index} onClick={() => handleCheck(row.id)}   onBlur={() => handleBlur('categories', checked)} >
                            <Checkbox
                                checked={checked?.includes(row.id)}
                                className={classes.checkbox}
                            />
                            <ListItemText primary={row.name} className={classes.checkboxItem} />
                        </MenuItem>
                    ))
                }
            </div>
            {(!isSearchHidden || error) && <div className={classes.errorMessage}>
                <FormHelperText error={(error) ? true : false}>{error ? error : ' '}</FormHelperText>
            </div>
            }


        </div>
    );
}