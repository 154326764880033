
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container:{
        padding:'24px 24px 0px 24px',
    },
    inputLabel:{
        fontSize:14,
        color:'#000',
        fontWeight:500,
        marginBottom:4,
        margin:'auto 0',
        width:'97%'
    },
    inputValue:{
        fontSize:14,
        color:'#2a2c2e',
        fontWeight:400,
        margin:'auto 0',
    },
    icon:{
        width:20,
        height:20,
        color:'#bd0912',
        margin:'auto 12px',
        cursor:'pointer'
    }

}));