import React, { useEffect, useState, useRef } from 'react';
import { HvGrid, HvFileUploader, HvTooltip, HvInput, HvButton, HvSection, HvListContainer, HvListItem } from '@hitachivantara/uikit-react-core';
import Footer from 'components/footer/Footer';
import { checkSupportedFormat } from "utils/common";
import uuid from 'react-uuid';
import { Close } from '@hitachivantara/uikit-react-icons';
import { useDispatch } from 'react-redux';
import { showLoader } from 'redux/actions/commonActions';
import { toast } from "react-toastify";
import LabService from "services/LabService";
import { useStyles } from "../wizard/style";
import { buildQueryParams } from "utils/common";
import _ from "lodash";

const InstructionSteps = props => {
    const labServiceObj = new LabService();
    const { currentStep } = props
    const [labId] = useState(props?.labData?.id ? props?.labData?.id : props?.labId);
    const inputRefs = useRef({});
    const [content, setContent] = useState();
    const [instructionId, setInsructionId] = useState();
    const [videoInstruction, setVideoInstruction] = useState();
    const valuesArray = videoInstruction?.split(',').map((value) => value.trim());
    const dispatch = useDispatch();
    const [videoArray, setVideoArray] = useState([]);
    const [fileName, setFileName] = useState(content);
    const [videoUrl, setVideoUrl] = useState();
    const [fileList, setFileList] = useState([])
    const [instructionFileData, setInstructionFileData] = useState();
    const classes = useStyles();
    const supportedFileTypes = ["pdf"];
    const videoRef = useRef(null);

    useEffect(() => {
        if (props.mode === "Edit") {
            const urlParams = { lab_id: labId }
            labServiceObj.getInstruction(buildQueryParams(urlParams)).then(response => {
                if (response) {
                    setContent(response?.data.results[0]?.instruction_file);
                    setVideoInstruction(response?.data.results[0]?.video_instruction);
                    setInsructionId(response?.data.results[0]?.id);
                }
            }).catch(err => {
                console.log('error in Instructions data')
            })

        }

    }, []);
    const createInstructionTabPayload = () => {
        const formData = new FormData();
        formData.append("instruction_file", instructionFileData);
        formData.append("lab", labId);
        if (_.isUndefined(videoUrl)) {

            formData.append("video_instruction", !_.isNull(videoInstruction) ? videoInstruction : "");
        } else {
            formData.append("video_instruction", videoUrl);
        }

        return formData;


    }
    const createOrUpdateInstruction = (data) => {
        dispatch(showLoader(true));
        const instruction_file = data?.get("instruction_file")
        const video_instruction = data?.get("video_instruction")
        if (instruction_file === "undefined" && video_instruction === "undefined") {
            dispatch(showLoader(false));
            props.setNextStep(currentStep + 1);
        } else {
            if (instruction_file === "undefined" && (!_.isEmpty(video_instruction) || _.isEmpty(video_instruction))) {
                data?.delete("instruction_file")
                if (_.isUndefined(instructionId)) {
                    labServiceObj.setInstruction(data).then(response => {
                        if (response) {
                            dispatch(showLoader(false));
                            props.setNextStep(currentStep + 1);
                        }
                    }).then(err => {
                        console.log(err)
                    })
                } else {
                    labServiceObj.updateInstruction(data, instructionId, labId).then(response => {
                        if (response) {
                            dispatch(showLoader(false));
                            props.setNextStep(currentStep + 1);
                        }
                    }).then(err => {
                        console.log(err)
                    })
                }
            } else {
                if (video_instruction === "undefined") {
                    data?.delete("video_instruction")
                }

                labServiceObj.setInstruction(data).then(response => {
                    if (response) {
                        dispatch(showLoader(false));
                        props.setNextStep(currentStep + 1);
                    }
                }).then(err => {
                    console.log(err)
                })
            }

        }

    }
    const createFormPayload = async (event, file) => {
        //setFileName(file?.name)
        const pdfFile = file;
        setInstructionFileData(pdfFile);
        setFileList([...fileList, pdfFile])

    }

    useEffect(() => {
        if (_.isEmpty(videoArray)) {
            if (videoInstruction !== "undefined" && !_.isEmpty(videoInstruction)) {
                const valuesArray = videoInstruction?.split(',').map((value) => value.trim()) || [];
                setVideoArray(valuesArray)
            }
        }
    }, [videoInstruction]);


    const fileUpload = async (fileObject) => {
        try {
            for (var index = 0; index < fileObject.length; index++) {
                const file = fileObject[index];
                const isValidFormat = checkSupportedFormat(file.name, supportedFileTypes);
                if (isValidFormat) {

                    await createFormPayload(fileObject, file);
                }
                else {
                    toast.error(`${file.name} file's format not supported.`);
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    const openFileInNewTab = (url) => {
        var sanitizeUrl = encodeURI(url);
        window.open(sanitizeUrl, '_blank')
    }

    const uploadVideo = () => {
        if (!_.isEmpty(videoUrl)) {
            videoRef.current = videoUrl;
            const newVideoArray = videoUrl?.split(',').map((value) => value.trim()) || videoUrl
            setVideoArray([...videoArray, ...newVideoArray]);
            setVideoUrl([...videoArray, ...newVideoArray].join(","))
        } else {
            toast.error(`Can not upload empty URL.`);
        }

    }

    const removeVideoUrl = (index) => {
        const newArray = [...videoArray];
        newArray.splice(index, 1);
        setVideoArray(newArray);
        setVideoUrl(newArray)
    }
    const removeFile = () => {

        setInstructionFileData('');
        setFileList([]);
        setFileName('');
        setContent('');
    }

    const handleInstructionSave = () => {
        let payload = createInstructionTabPayload()
        createOrUpdateInstruction(payload)
    }

    return (
        <div>
            <form autoComplete='on' onSubmit={(event) => {
                handleInstructionSave()
                event.preventDefault();

            }}>

                <HvGrid container spacing={3} >
                    <HvGrid item xs={5} style={{ paddingTop: "26px" }}>
                        <HvFileUploader
                            fileList={fileList}

                            labels={{
                                acceptedFiles: '(pdf )',
                                dropzone: 'Upload File'
                            }}
                            onFileRemoved={(e) => { setFileList(fileList.filter((element) => element.id !== e?.id)) }}
                            onFilesAdded={(e) => { fileUpload(e) }}
                        />

                    </HvGrid>
                    <HvGrid item xs={5} >
                        <HvInput
                            fullWidth
                            name="video"
                            label="Video Url"
                            variant="outlined"
                            disabled={false}
                            classes={{ root: classes.textField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                className: classes.inputLabel

                            }}
                            onChange={(e) => {
                                setVideoUrl(e.target.value)

                            }}
                            value={videoUrl}
                            ref={videoRef}
                        >
                        </HvInput>

                    </HvGrid>
                    <HvGrid item xs={2} style={{ paddingTop: "5%" }}>
                        <HvButton
                            variant="primary"
                            component="label"
                            onClick={uploadVideo}
                        >
                            Upload Video
                        </HvButton>
                    </HvGrid>
                </HvGrid>
                < HvGrid container spacing={2} style={{ padding: "20px" }
                }>
                    <HvGrid item xs={5} >
                        <HvListContainer
                        >
                            {
                                !_.isEmpty(fileName || content) ? <>
                                    <div key={uuid()} style={{ cursor: "pointer" }}>
                                        {
                                            !_.isEmpty(fileName) ?
                                                <div style={{}} >{fileName}</div>
                                                : <> <HvListItem style={{ padding: "8px", cursor: "pointer" }} className={classes.fileName} onClick={() => openFileInNewTab(content)}>{content?.split(".pdf")[0]?.split("/")[6]}
                                                </HvListItem><HvTooltip title="Remove File"> <Close style={{ '--color-0': 'red' }} className={classes.clearIcon} onClick={() => { removeFile(fileName) }} /></HvTooltip>
                                                </>


                                        }

                                    </div>
                                </> : <></>
                            }
                        </HvListContainer>
                    </HvGrid>

                    <HvGrid item xs={6} >
                        <HvListContainer
                        >
                            {
                                videoArray?.length > 0 ?
                                    videoArray?.map((item, index) => (
                                        <><HvListItem style={{ padding: "8px" }} key={index} className={classes.fileName}>{item}</HvListItem><HvTooltip title="Remove Video"> <Close style={{ '--color-0': 'red' }} className={classes.clearIcon} onClick={() => { removeVideoUrl(index) }} /></HvTooltip></>
                                    )) : <></>
                            }
                        </HvListContainer>
                    </HvGrid>

                </HvGrid >

                < Footer handleSumbit={(event) => { event?.preventDefault() }} />
            </form >
        </div >
    );
};

InstructionSteps.propTypes = {

};

export default InstructionSteps;