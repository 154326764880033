export const tabsName = [
    {
        index: 0,
        name: 'My Running Labs',
    },
    {
        index: 1,
        name: 'New Labs',
    },
    {
        index: 2,
        name: 'All Labs',
    },
];

// export const menuOptions = ['Edit', 'Copy'];
export const menuOptions = ['Feedback'];

export const toolsTabsName = [
    {
        index: 0,
        name: 'Tools',
    },
];

export const documentsTabsName = [
    {
        index: 1,
        name: 'Documents',
    },
];

export const reportsTabsName = [
    {
        index: 0,
        name: 'Top Labs by session',
    },
    {
        index: 1,
        name: 'Top Labs by usage',
    }
]