import { MAX_TEXT_LENGTH, MIN_TEXT_LENGTH } from "constants/common";

export const METHOD_LIST = 'documents/';
export const METHOD_CREATE = 'documents/';
export const METHOD_UPDATE = 'documents/{DOCUMENT_ID}/';
export const METHOD_DELETE = 'documents/{DOCUMENT_ID}/';
export const METHOD_DETAIL = 'documents/{DOCUMENT_ID}/';
export const METHOD_CATEGORY_LIST = 'documents-category/drop/';
export const METHOD_TAGS_LIST = 'tag/';
export const METHOD_GROUP_WITH_USERS_LIST = 'user-group/';
export const METHOD_CREATE_TAG = 'tag/';


//text box length
export const TEXT_MIN_LENGTH=MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH=MAX_TEXT_LENGTH;

//language constants
export const MSG_WARNING_DELETE = 'Are you sure to delete this document?';
export const MSG_DELETE_SUCCESS = 'Deleted Successfully';
export const MSG_ADD_SUCCESS = 'Created Successfully';
export const MSG_UPDATE_SUCCESS = 'Updated Successfully';
export const MSG_UNDO_RECORD = 'Document Restored Successfully'; 
export const MSG_WARNING_UNDO_DELETE = 'Are you sure to undo this deleted document?';