import NetworkService from "services/NetworkService";
import {
  METHOD_LIST,
  METHOD_CREATE,
  METHOD_UPDATE,
  METHOD_DELETE,
  METHOD_DETAIL,
  METHOD_ALL_GROUPS,
  METHOD_ALL_REPORTS
} from "constants/admin/masters/users";
class UserService {
  getAllUsers = (urlParams) => {
    return NetworkService.get(`${METHOD_LIST}?${urlParams}`).then((response) => response.data);
  }

  getAll = (data) => {
    return NetworkService
      .get(METHOD_LIST, data)
      .then((response) => response.data);
  };
  getDetail = (userId) => {
    const updatedMethodName = METHOD_DETAIL.replace("{USER_ID}", userId);
    return NetworkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return NetworkService
      .post(METHOD_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, userId) => {
    const updatedMethodName = METHOD_UPDATE.replace("{USER_ID}", userId);
    return NetworkService
      .post(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (userId) => {
    const updatedMethodName = METHOD_DELETE.replace("{USER_ID}", userId);
    return NetworkService
      .delete(updatedMethodName)
      .then((response) => response.data);
  };
  getAllGroups = () => {
    return NetworkService
      .get(METHOD_ALL_GROUPS)
      .then((response) => response.data);
  };
  getAllReports = () => {
    return NetworkService
      .get(METHOD_ALL_REPORTS)
      .then((response) => response.data);
  };
  createUser =(data)=>{
    return NetworkService.post(METHOD_LIST, data)
    .then((response) => response.data);
  }
}
export default UserService;
