import NetworkService  from "services/NetworkService";
import {
  METHOD_LIST,
  METHOD_CREATE,
  METHOD_UPDATE,
  METHOD_DELETE,
  METHOD_DETAIL,
  METHOD_CATEGORY_LIST,
  METHOD_TAGS_LIST,
  METHOD_GROUP_WITH_USERS_LIST,
  METHOD_CREATE_TAG
} from "constants/admin/masters/documents";
class DocumentsService {
  getAll = (urlParams) => {
    return NetworkService
      .get(`${METHOD_LIST}?${urlParams}`)
      .then((response) => response.data);
  };
  getDetail = (documentId) => {
    const updatedMethodName = METHOD_DETAIL.replace("{DOCUMENT_ID}", documentId);
    return NetworkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return NetworkService
      .post(METHOD_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, documentId) => {
    const updatedMethodName = METHOD_UPDATE.replace("{DOCUMENT_ID}", documentId);
    return NetworkService
      .patch(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (documentId) => {
    const updatedMethodName = METHOD_DELETE.replace("{DOCUMENT_ID}", documentId);
    return NetworkService
      .delete(updatedMethodName)
      .then((response) => response.data);
  };
  undoDelete = (documentId) => {
    const updatedMethodName = METHOD_UPDATE.replace("{DOCUMENT_ID}", documentId);
    return NetworkService
      .patch(updatedMethodName,{is_deleted:false})
      .then((response) => response.data);
  };
  getAllCategoryList = () => {
    return NetworkService
      .get(METHOD_CATEGORY_LIST)
      .then((response) => response.data);
  };
  getAllTagsList = () => {
    return NetworkService
      .get(METHOD_TAGS_LIST)
      .then((response) => response.data);
  };
  getAllGroupsWithUsers = () => {
    return NetworkService
      .get(METHOD_GROUP_WITH_USERS_LIST)
      .then((response) => response.data);
  };
  createTag = (data) => {
    return NetworkService
      .post(METHOD_CREATE_TAG, data)
      .then((response) => response.data);
  };
  
}
export default DocumentsService;
