import React, { useState } from "react";
import { toast } from "react-toastify";
import DataGrid from "components/datagrid/DataGrid";
import _ from "lodash";
import UserService from "services/admin/masters/UserService";
import { MSG_WARNING_DELETE, MSG_DELETE_SUCCESS } from "constants/admin/masters/users";
import Breadcrumb from "components/Breadcrumb";
import { Grid } from "@material-ui/core";
import { useStyles } from "utils/theme";
const userServiceObj = new UserService();
const List = (props) => {
    const classes = useStyles();
    const { url: masterUrl } = props.match;
    const [refreshToken, setRefreshToken] = useState(Date.now());
    const [pageSize, setPageSize] = useState(20);
    const metaInfo = {
        filtering: true,
        columns: [
            { title: "Full Name", field: "full_name" },
            { title: "Email", field: "email" }
        ],
        actions: [
            {
                icon: "edit",
                tooltip: "Edit Record",
                onClick: (event, rowData) => {
                    props.history.push(`${masterUrl}/edit/${rowData.id}`);
                },
            },
            {
                icon: "delete",
                tooltip: "Delete Record",
                onClick: (event, rowData) => {
                    if (window.confirm(MSG_WARNING_DELETE.replace("ITEM_NAME", rowData.name))) {
                        userServiceObj.delete(rowData.id).then((response) => {
                            if (response) {
                                toast.success(response.message || MSG_DELETE_SUCCESS);
                                handleRefresh();
                            }
                        });
                    }

                },
            },
        ],
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "User List" },
        ]
    };
    const handleRefresh = (e) => {
        setRefreshToken(Date.now());
    };

    const handlePagingData = (query) => {
        return new Promise((resolve, reject) => {
            const sortData = [{ field: "id", dir: "desc" }]; //default sorting
            if (!_.isEmpty(query.orderBy)) {
                sortData.push({
                    field: query.orderBy.field,
                    dir: query.orderDirection,
                });
            }
            setPageSize(query.pageSize);
            let filters = [];
            if (!_.isEmpty(query.filters)) {
                query.filters.map((filter) =>
                    filters.push({
                        field: filter.column.field,
                        type: "string",
                        value: filter.value,
                        operator: "eq",
                    })
                );
            }
            const reqParams = {
                take: query.pageSize,
                skip: query.pageSize * query.page,
                sort: sortData,
                filter: filters,
            };
            userServiceObj.getAll(reqParams).then((response) => {

                resolve({
                    data: response.items,
                    page: query.page,
                    totalCount: response.total,
                });
            }).catch(error => {

            });
        });
    }

    return (
        <Grid container spacing={1} className={classes.outerContainer} >
            <Grid item xs={10}>
                <Breadcrumb data={metaInfo.breadcrumData} />
            </Grid>

            <Grid item xs={12}>
                <div className={classes.contentGrid}>
                    <DataGrid
                        actions={metaInfo.actions}
                        columns={metaInfo.columns}
                        dataHandler={handlePagingData}
                        filtering={metaInfo.filtering}
                        pageSize={pageSize}
                        refreshToken={refreshToken}
                    ></DataGrid>
                </div>
            </Grid>
        </Grid>
    );
}
export default List;