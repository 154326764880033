import { GET_SUPPORTED_FILE_FORMATS_URL, GET_MAX_SIZE_FOR_FILE, GET_FILE_UPLOAD_URL, GET_FILE_UPLOAD_DELETE_URL, FILE_UPLOAD_GENERAL, FILE_UPLOAD_LABS, UPLOAD_RESOURCE_URL } from "constants/fileUpload";
import NetworkService from "./NetworkService";
import store from "redux/store";
import { SHOW_PROGRESS } from "constants/common";
class FileUploadService {
    updateProgress(payload) {
        return {
            type: SHOW_PROGRESS,
            payload
        }
    }
    getSupportedFileFormatUrl = () => {
        return NetworkService.get(GET_SUPPORTED_FILE_FORMATS_URL).then((response) => response.data);
    }
    getMaxSizeForFile = () => {
        return NetworkService.get(GET_MAX_SIZE_FOR_FILE).then((response) => response.data);
    }
    getFileUploadUrl = async (payload) => {
        return NetworkService.post(GET_FILE_UPLOAD_URL, payload).then((response) => response.data);
    }
    getFileUploadUrlByLabId = async (params) => {
        return NetworkService.get(`${GET_FILE_UPLOAD_URL}?${params}`).then((response) => response.data);
    }
    getFileDeleteUrl = async (resourceId) => {
        const response = await NetworkService.delete(`${GET_FILE_UPLOAD_DELETE_URL}${resourceId}/`);
        return response.data;
    }
    fileUploadGeneral = async (payload) => {
        return NetworkService.post(FILE_UPLOAD_GENERAL, payload, {
            onUploadProgress: (ProgressEvent) => {
                const progress = (ProgressEvent.loaded / ProgressEvent.total) * 100;
                store.dispatch(this.updateProgress(progress));
            },
        }).then((response) => response.data);
    }

    fileUploadLabs = async (payload) => {
        return NetworkService.post(FILE_UPLOAD_LABS, payload, {
            onUploadProgress: (ProgressEvent) => {
                const progress = (ProgressEvent.loaded / ProgressEvent.total) * 100;
                store.dispatch(this.updateProgress(progress));
            },
        }).then((response) => response.data);
    }
    
    uploadResourceUrl = async (payload)=>{
        return NetworkService.post(UPLOAD_RESOURCE_URL, payload).then((response) => response.data);
    }

    updateResource = async(payload, resourceId)=>{
        return NetworkService.patch(`${UPLOAD_RESOURCE_URL}${resourceId}/`, payload).then((response) => response.data);
    }

    deleteResourceUrl = async (resourceId) => {
        const response = await NetworkService.delete(`${UPLOAD_RESOURCE_URL}${resourceId}/`);
        return response.data;
    }

    getResourceUrlByLabId = async (params) => {
        return NetworkService.get(`${UPLOAD_RESOURCE_URL}?${params}`).then((response) => response.data);
    }
}
export default FileUploadService;