import { SHOW_LOADER, SHOW_PROGRESS,SHOW_FULLSCREEN, AUTHENTICATE_OKTA_TOKEN, CURRENT_PAGE, CURRENT_PAGE_SIZE, LAB_ENVIRONMENT_ID } from "constants/common";

const initialState = {
    showLoader: false,
    progress: 0,
    authenticateOktaToken : false,
    currentPage : 0,
    currentPageSize : 0,
    environment_id : 0
};

const commonReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_LOADER:
            return {
                ...state,
                showLoader: payload,
            };
        case SHOW_PROGRESS:
            return {
                ...state,
                progress: payload,
            };
        case SHOW_FULLSCREEN:
            {
                return {
                    ...state,
                    showFullScreen: payload
                }
            }
        case AUTHENTICATE_OKTA_TOKEN : {
            return {
                ...state,
                authenticateOktaToken: payload,
            };
        }
        case CURRENT_PAGE:{
            return {
                ...state,
                currentPage: payload,
            };
        }
        case CURRENT_PAGE_SIZE:{
            return {
                ...state,
                currentPageSize: payload,
            };
        }
        case LAB_ENVIRONMENT_ID:{
            return {
                ...state,
                environment_id: payload,
            };
        }
        default:
            return state;
    }
};

export default commonReducer;