import React from "react";
function BrowserCompatible(props) {
    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="panel panel-danger">
                        <div className="panel-heading">
                            <h3 className="text-center alert alert-warning">
                                <i className="fa fa-exclamation"></i> Oops:
                                <small>Upgrade Your Browser- <b>Browser Incompatibility Issue</b></small>
                            </h3>
                        </div>
                        <div className="panel-body">
                            <p>It looks like you may be using a web browser({props.browserInfo.name}) version({props.browserInfo.version}) 
                            that we don't support. Make sure you're using the most recent version of your browser or try one of these supported 
                            browsers to get into our system.</p>

                            <ul className="list-group">
                                <li className="list-group-item">{'Microsoft IE version 11'}</li>
                                <li className="list-group-item">{'Microsoft Edge version >= 80'}</li>
                                <li className="list-group-item">{'Mozilla Firefox version >= 52'}</li>
                                <li className="list-group-item">{'Google Chrome version >= 49'}</li>
                                <li className="list-group-item">{'Safari version >= 10'}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    );
}
export default BrowserCompatible;



