import NetworkService  from "services/NetworkService";
import {
  METHOD_LIST,
  METHOD_CREATE,
  METHOD_UPDATE,
  METHOD_DELETE,
  METHOD_DETAIL,
  METHOD_ALL_USERS
} from "constants/admin/masters/groups";
class GroupService {
  getAll = (urlParams) => {
    return NetworkService
      .get(`${METHOD_LIST}?${urlParams}`)
      .then((response) => response.data);
  };
  getDetail = (groupId) => {
    const updatedMethodName = METHOD_DETAIL.replace("{GROUP_ID}", groupId);
    return NetworkService
      .get(updatedMethodName)
      .then((response) => response.data);
  };
  create = (data) => {
    return NetworkService
      .post(METHOD_CREATE, data)
      .then((response) => response.data);
  };
  update = (data, groupId) => {
    const updatedMethodName = METHOD_UPDATE.replace("{GROUP_ID}", groupId);
    return NetworkService
      .patch(updatedMethodName, data)
      .then((response) => response.data);
  };
  delete = (groupId) => {
    const updatedMethodName = METHOD_DELETE.replace("{GROUP_ID}", groupId);
    return NetworkService
      .delete(updatedMethodName)
      .then((response) => response.data);
  };
  undoDelete = (groupId) => {
    const updatedMethodName = METHOD_UPDATE.replace("{GROUP_ID}", groupId);
    return NetworkService
      .patch(updatedMethodName,{is_deleted:false})
      .then((response) => response.data);
  };
  getAllUsers = () => {
    return NetworkService
      .get(METHOD_ALL_USERS)
      .then((response) => response.data);
  };
}
export default GroupService;
