import React from "react";
import { Box } from "@material-ui/core";
import { useStyles } from "./style.js";
import Unauthorized from "components/Unauthorized";

import DocumentsWidget from "./Widget";

export default function Documents() {
  const classes = useStyles();
  const screenSize = window.screen.width;

  return (
    <>
      {screenSize >= 768 ? (
        <div className="main-header">
          <Box component="div" className={classes.headerContainer}>
            <span className="main-label">DOCUMENTS</span>
          </Box>
          <div className={classes.root}>
            <DocumentsWidget />
          </div>
        </div>
      ) : (
        <Unauthorized />
      )}
    </>
  );
}
