import NetworkService from "./NetworkService";
import {
    METHOD_ADMIN_SETINGS,
    METHOD_BANNER,
    METHOD_ENCRYPT_PASSWORD
} from "constants/common";

class CommonService {
    getAdminSettings = async () => {
        const response = await NetworkService.get(METHOD_ADMIN_SETINGS);
        return response.data;
    };
    saveAdminSettings = async (payload) => {
        const response = await NetworkService.patch(METHOD_ADMIN_SETINGS, payload);
        return response.data;
    };
    encryptPlainText = async (payload) =>{   
        const response = await NetworkService.post(METHOD_ENCRYPT_PASSWORD, payload);
        return response.data;
    }
    sendBannerMessage = async (payload) =>{
        const response = await NetworkService.post(METHOD_BANNER, payload);
        return response.data;
    }
    getBannerMessage = async () =>{
        const response = await NetworkService.get(METHOD_BANNER);
        return response.data;
    }
    removeBannerMessage = async (messageId)=>{
        const response = await NetworkService.delete(`${METHOD_BANNER}${messageId}/`)
        return response.data;
    }
}
export default CommonService;