import { USER_INFO, LAB_INFO, TOKEN_EXPIRE_TIME } from "constants/login";
import HistoryService from "services/HistoryService";
import LoginService from "services/LoginService";
import StorageService from "services/StorageService";
import LabService from "services/LabService";

const loginServiceObj = new LoginService();
const storageServiceObj = new StorageService();
const historyServiceObj = new HistoryService();


export const verifyLogin = () => async dispatch => {
    const userInfo = storageServiceObj.getUserData();
    if (userInfo && userInfo.id > 0) {
        dispatch({
            type: USER_INFO,
            payload: userInfo,
        });
    }
};
export const doSsoLogin = (userInfo) => async dispatch => {
    if (userInfo && userInfo.id > 0) {
        storageServiceObj.setUserData(userInfo);
        dispatch({
            type: USER_INFO,
            payload: userInfo,
        });
    }
};

export const logout = () => async dispatch => {
    const userInfo = storageServiceObj.getUserData();
    if (userInfo?.type && userInfo?.type === 'backdoor') {
        //this is case of backdoor user logout
        dispatch({
            type: USER_INFO,
            payload: {},
        });
        historyServiceObj.redirect('/')
    } else {
        //this is for sso logout
        const response = await loginServiceObj.getSsoLogoutUrl();
        if (response) {
            dispatch({
                type: USER_INFO,
                payload: {},
            });
            // window.location.href = response.logouturl;
        }
    }
    storageServiceObj.clearAllData();
};

export const backdoorLogin = (payload) => async dispatch => {

    try {
        const userInfo = await loginServiceObj.backdoorLogin(payload);
        if (userInfo.access_token && userInfo?.id) {
            userInfo['type'] = 'backdoor';
            dispatch({
                type: USER_INFO,
                payload: userInfo,
            });
            storageServiceObj.setUserData(userInfo);
            return true;
        }
    } catch (error) {
        console.error('Backdoor Login Error :', error)
        return false;
    }


};

export const labLandingData = (labInfo)=> dispatch =>{
    try {
        dispatch({
            type : LAB_INFO,
            payload : labInfo
        })
        
    } catch (error) {
        console.error("Landing Page Error : ", error);
        return false
    }
}
export const setTokenExpiry = (expiryTime) => async dispatch => {
    if (expiryTime) {
        dispatch({
            type: TOKEN_EXPIRE_TIME,
            payload: expiryTime,
        });
    }
};
