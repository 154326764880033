import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "hooks/useForm";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import {
  MSG_ADD_SUCCESS,
  MSG_UPDATE_SUCCESS,
} from "constants/admin/masters/faq";
import { showBanner } from "redux/actions/bannerActions";
import { useDispatch } from "react-redux";
import FAQService from "services/admin/masters/FAQService";
import { BootstrapInput } from "containers/labs/style";
import { buildQueryParams } from "utils/common";
import uuid from "react-uuid";
import RichTextBox from "components/richTextBox";
import {
  HvBreadCrumb,
  HvBox,
  HvGrid,
  HvButton,
  HvTypography,
  HvDropdown,
  HvTextArea,
} from "@hitachivantara/uikit-react-core";

const faqServiceObj = new FAQService();

const QuestionForm = ({ history, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { params } = props.match;
  const questionId = params?.questionId;
  const [faqCategoryType, setFaqCategoryType] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const initialFormData = {
    // used to initialize the data
    question: "",
    answer: "",
    faq_category_id: 0,
    sort_order: 0,
  };
  const metaInfo = {
    breadcrumData: [
      {
        ariaLabel: "Home",
        label: "Home",
        path: "/labs/",
        to: "/",
      },
      {
        ariaLabel: "FAQ",
        label: "FAQ",
        path: "/admin/masters/faq",
        to: "/admin/masters/faq",
      },
      {
        ariaLabel: "FAQ Question",
        label: questionId > 0 ? "Edit FAQ Question" : "Add FAQ Question",
        path: "",
        to: "",
      },
    ],
  };

  const {
    handleSubmit, // handles form submission
    handleChange, // handles input changes
    handleChangeCustom,
    data, // access to the form data
    errors, // includes the errors to show
    populateForm, //to populate the values in case of edit
  } = useForm({
    // the hook we are going to create
    initialValues: initialFormData, //pass the initializing data
    validations: {
      // all our validation rules go here
      question: {
        required: {
          value: true,
          message: "Question is required",
        },
      },
      answer: {
        custom: {
          isValid: (value) => value.replace(/<[^>]+>/g, '').trim().length !== 0,
          message: "Answer is required",
        },
      },
      faq_category_id: {
        custom: {
          isValid: (value) => value > 0,
          message: "FAQ category is required",
        },
      },
    },
    onSubmit: () => {
      //trigger the actual post submit function from here
      submitForm(data);
    },
  });

  //loads the initial data when form is loading//
  useEffect(() => {
    let faqCategories = [{ label: "--Select--", value: 0 }];
    let urlParams = { is_deleted: false };
    urlParams["limit"] = 500;
    urlParams["offset"] = 0;
    faqServiceObj
      .getAllFaqList(buildQueryParams(urlParams))
      .then((response) => {
        if (response) {
          response.results &&
            response.results.length > 0 &&
            response.results.forEach((record) => {
              faqCategories.push({ label: record.name, value: record.id });
            });
          setFaqCategoryType(faqCategories);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    if (questionId > 0) {
      faqServiceObj
        .getDetailByQuestionId(questionId)
        .then((response) => {
          initialFormData.question = response?.question || "";
          initialFormData.answer = response?.answer || "";
          initialFormData.sort_order = response?.sort_order || 0;
          initialFormData.faq_category_id = response?.faq_category_id || 0;
          populateForm(initialFormData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, []);

  const submitForm = async (data) => {
    let response;
    if (questionId > 0) {
      response = await faqServiceObj.updateFaqQuestion(data, questionId);
    } else {
      response = await faqServiceObj.createFaqQuestion(data);
    }
    if (response) {
      response.message || questionId > 0
        ? dispatch(
          showBanner({
            payload: {
              showBanner: true,
              label: {
                message: response.message || MSG_UPDATE_SUCCESS,
              },
              variant: "success",
            },
          })
        )
        : dispatch(
          showBanner({
            payload: {
              showBanner: true,
              label: {
                message: response.message || MSG_ADD_SUCCESS,
              },
              variant: "success",
            },
          })
        );
      history.push(`/admin/masters/faq`);
    }
  };

  const bindFaqAnswer = (property, value) => {
    let updatedData = {
      ...data,
      answer: value,
    };
    populateForm(updatedData);
  };

  return (
    <HvGrid container spacing={1} className={classes.outerContainer}>
      <HvGrid item xs={10}>
        <HvBreadCrumb
          aria-label="Breadcrumb"
          listRoute={metaInfo.breadcrumData}
        />
      </HvGrid>
      <HvGrid item xs={12}>
        <div className={classes.contentRoot}>
          <form
            onSubmit={handleSubmit}
            className={classes.questionForm}
            noValidate
          >
            <HvBox marginBottom={1}>
              <HvTypography
                color="textPrimary"
                className={classes.inputLabel}
                style={{ marginTop: 6, marginBottom: 8 }}
              >
                Category
              </HvTypography>
              <HvDropdown
                status="valid"
                required
                placeholder={selectedCategory}
                values={faqCategoryType.map((option) => ({
                  label: option.label,
                  value: option.value,
                }))}
                onChange={(targetValue) => {
                  setSelectedCategory(targetValue?.label);
                  handleChangeCustom("faq_category_id", targetValue.value);
                }}
              ></HvDropdown>
              {errors["faq_category_id"] &&
                errors["faq_category_id"] !== undefined ? (
                <p className={classes.errorLabel}>
                  {errors["faq_category_id"]}
                </p>
              ) : null}
            </HvBox>

            <HvTypography
              color="textPrimary"
              className={classes.inputLabel}
              style={{ marginTop: 6, marginBottom: 2 }}
            >
              Order
            </HvTypography>

            <HvTextArea
              fullWidth
              name="sort_order"
              // variant="outlined"
              placeholder="sort_order"
              type="number"
              classes={{ root: classes.customTextField }}
              inputProps={{
                classes: {
                  input: classes.inputField,
                  root: classes.base,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={data.sort_order || 0}
              onChange={handleChange("sort_order")}
            />

            <HvTypography
              color="textPrimary"
              className={classes.inputLabel}
              style={{ marginTop: 6, marginBottom: 2 }}
            >
              Question
            </HvTypography>
            <HvTextArea
              fullWidth
              name="question"
              // variant="outlined"
              classes={{ root: classes.customTextField }}
              InputProps={{
                classes: {
                  input: classes.inputField,
                  root: classes.base,
                  focused: classes.focused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              value={data.question || ""}
              onChange={handleChange("question")}
            />
            {errors?.question && (
              <p style={{ color: "red" }}>{errors?.question}</p>
            )}
            <HvBox marginBottom={2}>
              <HvTypography
                color="textPrimary"
                className={classes.inputLabel}
                style={{ marginTop: 6, marginBottom: 8 }}
              >
                Answer
              </HvTypography>
              <RichTextBox
                content={data.answer}
                onChange={bindFaqAnswer}
                property="Faq"
              />
              {errors?.answer && (
                <p style={{ color: "red" }}>{errors?.answer}</p>
              )}
            </HvBox>

            <HvGrid item xs={12}>
              <HvBox
                className={classes.masterBtn}
                display="flex"
                justifyContent="right"
              >
                <HvButton
                  variant="secondary"
                  style={{ marginRight: 12, marginTop: 12 }}
                  component={Link}
                  to={`/admin/masters/faq`}
                >
                  Back
                </HvButton>
                <HvButton variant="primary" type="submit">
                  {questionId > 0 ? "Update" : "Add"}
                </HvButton>
              </HvBox>
            </HvGrid>
          </form>
        </div>
      </HvGrid>
    </HvGrid>
  );
};

export default withRouter(QuestionForm);
