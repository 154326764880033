import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import DocumentsService from "services/admin/masters/DocumentsService";
import Breadcrumb from "components/Breadcrumb";
// import Radio from 'components/radioButton';
import { Box, Grid, TextField, Button, Typography } from "@material-ui/core";
import { getMasterBaseUrl } from "utils/masterHelpers";
import { useForm } from "hooks/useForm";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import { MSG_ADD_SUCCESS, MSG_UPDATE_SUCCESS } from "constants/admin/masters/documents";
import GroupUserPermissions from "components/permission/GroupUserPermissions";
import { transformDataCustomSelect } from "utils/common";
import CustomSelect from "react-select";
import CreatableSelect from "react-select/creatable"

// const groupItems = [
//     {
//         name: '1',
//         label: "Document",
//         disabled: false
//     },
//     {
//         name: '2',
//         label: "Asset",
//         disabled: false
//     },
// ];

const documentsServiceObject = new DocumentsService();
const Form = ({ history, ...props }) => {
    const classes = useStyles();
    const { url, params } = props.match;
    const documentId = params?.documentId;
    const masterUrl = getMasterBaseUrl(url, params);

    const [categoryList, setCategoryList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [groupUserList, setGroupUserList] = useState([]);

    const initialFormData = { // used to initialize the data
        name: '',
        description: '',
        link: '',
        users: [],
        groups: [],
        documents_category: [],
        tags: [],
        type: '1',
    };
    const metaInfo = {
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "Documents List", link: `${masterUrl}` },
            { title: (documentId > 0) ? "Edit Document" : "Add Document" },
        ]
    };

    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        handleChangeCustom,
        handleMultipleChange,
        data, // access to the form data
        errors, // includes the errors to show
        populateForm, //to populate the values in case of edit
    } = useForm({ // the hook we are going to create
        initialValues: initialFormData,//pass the initializing data 
        validations: { // all our validation rules go here
            name: {
                required: {
                    value: true,
                    message: 'Document Name is required',
                }
            },
            link: {
                required: {
                    value: true,
                    message: 'Link is required',
                },
                pattern: {
                    value: /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                    message: 'Link is not valid',
                },
            },
            documents_category: {
                required: {
                    value: true,
                    message: 'Documents Category need to select',
                }
            },
            tags: {
                required: {
                    value: true,
                    message: 'Atleast 1 tag need to select',
                }
            },
        },
        onSubmit: () => {
            //trigger the actual post submit function from here 
            submitForm(data)
        }
    });

    //loads the initial data when form is loading//
    useEffect(() => {
        documentsServiceObject.getAllCategoryList().then(response => {
            setCategoryList(transformDataCustomSelect(response))
        }).catch(err => {
            console.log('error in category data')
        })
        documentsServiceObject.getAllTagsList().then(response => {
            setTagList(transformDataCustomSelect(response))
        }).catch(err => {
            console.log('error in tags data')
        })
        documentsServiceObject.getAllGroupsWithUsers().then(response => {
            setGroupUserList(response)
        }).catch(err => {
            console.log('error in group user data')
        })

        if (documentId > 0) {
            documentsServiceObject.getDetail(documentId).then(response => {
                initialFormData.name = response?.name || '';
                initialFormData.description = response?.description || '';
                initialFormData.link = response?.link || '';
                initialFormData.type = response?.type || '1';
                initialFormData.users = response?.users || [];
                initialFormData.groups = response?.groups || [];
                initialFormData.documents_category = response?.documents_category || [];
                initialFormData.tags = response?.tags || [];
                populateForm(initialFormData);
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line
    }, [])

    const submitForm = async (data) => {
        data['is_public_viewable'] = data.groups.length === 0 && data.users.length === 0;
        let response;
        if (documentId > 0) {
            response = await documentsServiceObject.update(data, documentId);
        } else {
            response = await documentsServiceObject.create(data);
        }
        if (response) {
            toast.success(response.message || (documentId > 0) ? MSG_UPDATE_SUCCESS : MSG_ADD_SUCCESS);
            history.push(`${masterUrl}`);
        }

    }
    return (
        <Grid container spacing={1} className={classes.outerContainer}>
            <Grid item xs={10}>
                <Breadcrumb data={metaInfo.breadcrumData} />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.contentRoot}>
                    <form onSubmit={handleSubmit} noValidate>
                        <TextField fullWidth
                            name="name"
                            label="Document Name"
                            variant="outlined"
                            placeholder="Document Name"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}

                            value={data.name || ''}
                            onChange={handleChange('name')}
                            error={(errors['name'] && errors['name'] !== undefined) ? true : false}
                            helperText={(errors['name'] && errors['name'] !== undefined) ? errors['name'] : null}
                        />

                        <TextField fullWidth
                            name="description"
                            label="Description"
                            variant="outlined"
                            placeholder="Description"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.description || ''}
                            onChange={handleChange('description')}
                            error={(errors['description'] && errors['description'] !== undefined) ? true : false}
                            helperText={(errors['description'] && errors['description'] !== undefined) ? errors['description'] : null}
                        />

                        <TextField fullWidth
                            name="link"
                            label="link"
                            variant="outlined"
                            placeholder="Link"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.link || ''}
                            onChange={handleChange('link')}
                            error={(errors['link'] && errors['link'] !== undefined) ? true : false}
                            helperText={(errors['link'] && errors['link'] !== undefined) ? errors['link'] : null}
                        />

                        {/* <Radio
                            groupItems={groupItems}
                            checkedDefault={data.type}
                            group='Type'
                            onChange={(selectedValue) => {
                                handleChangeCustom('type', selectedValue)
                            }}
                        /> */}

                        <Grid item xs={12}>
                            <div className={classes.externalFormControl} >
                                <Typography color="textPrimary" className={classes.inputLabel} style={{ marginTop: 6, marginBottom: 8 }}>Categories</Typography>
                                <CustomSelect
                                    isMulti
                                    placeholder={`Search Category Here`}
                                    options={categoryList}
                                    value={
                                        categoryList.filter(
                                            item => data?.documents_category?.includes(item?.value)
                                        ) || []
                                    }
                                    onChange={e => {
                                        const selectedItems = e?.map(i => i.value);
                                        handleChangeCustom('documents_category', selectedItems)
                                    }}
                                />
                                {(errors['documents_category'] && errors['documents_category'] !== undefined) ? <p className={classes.errorLabel}>{errors['documents_category']}</p> : null}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={classes.externalFormControl} >
                                <Typography color="textPrimary" className={classes.inputLabel} style={{ marginTop: 6, marginBottom: 8 }} >Tags</Typography>
                                <CreatableSelect
                                    isMulti
                                    placeholder={`Search Tags Here`}
                                    options={tagList}
                                    value={
                                        tagList.filter(
                                            item => data?.tags?.includes(item?.value)
                                        ) || []
                                    }

                                    onChange={async e => {
                                        let selectedItems = [];
                                        for (const i of e) {
                                            if (i['__isNew__'] === true) {
                                                let response = await documentsServiceObject.createTag({ name: i.value, tag_type_id: 1 });
                                                if (response && response?.id) {
                                                    setTagList(prevData => {
                                                        return [...prevData, {
                                                            value: response.id,
                                                            label: response.name,
                                                            isDisabled: false
                                                        }];
                                                    })
                                                    selectedItems.push(response.id);
                                                }
                                            } else {
                                                selectedItems.push(i.value)
                                            }
                                        }
                                        handleChangeCustom('tags', selectedItems);
                                    }}
                                />
                                {(errors['tags'] && errors['tags'] !== undefined) ? <p className={classes.errorLabel}>{errors['tags']}</p> : null}
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className={classes.externalFormControl} >
                                <Typography color="textPrimary" className={classes.inputLabel} style={{ marginTop: 6, marginBottom: 8 }}>Permissions</Typography>
                                <Grid item xs={8}>
                                    <GroupUserPermissions selectedData={{ groups: data?.groups, users: data?.users }} changeHandler={handleMultipleChange} data={groupUserList} />
                                </Grid>
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <Box className={classes.masterBtn} display="flex" justifyContent='right'>
                                <Button className='back-btn' component={Link} to={`${masterUrl}`} >
                                    Back
                                </Button>
                                <Button className='add-btn' type="submit" >
                                    {(documentId > 0) ? 'Update' : 'Add'}
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(Form);