import React from "react";
import RadioButton from "./radioButton";

export default function Radio(props) {
    const { groupItems, group, checkedDefault, onChange } = props;
    return (
        <RadioButton
            groupItems={groupItems}
            group={group}
            checkedDefault={checkedDefault}
            onChange={onChange}
        />
    );
}