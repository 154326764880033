import { SHOW_BANNER } from "constants/common";

const initialState = {
    showBanner : {showBanner: false,
        label: "",
        variant: ""}
};

const bannerReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOW_BANNER:
            return {
                ...state,
                showBanner: payload,
            };
        default:
            return state;
    }
};

export default bannerReducer;