import React, { useEffect } from "react";
import { toast } from "react-toastify";
import ToolsCategoryService from "services/admin/masters/ToolsCategoryService";
import Breadcrumb from "components/Breadcrumb";
import { Box, Grid, TextField, Button } from "@material-ui/core";
import { getMasterBaseUrl } from "utils/masterHelpers";
import { useForm } from "hooks/useForm";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import { MSG_ADD_SUCCESS, MSG_UPDATE_SUCCESS } from "constants/admin/masters/toolCategory";

const toolsCategoryServiceObject = new ToolsCategoryService();
const Form = ({ history, ...props }) => {
    const classes = useStyles();
    const { url, params } = props.match;
    const categoryId = params?.categoryId;
    const masterUrl = getMasterBaseUrl(url, params);

    const initialFormData = { // used to initialize the data
        name: '',
        description: ''
    };
    const metaInfo = {
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "Tools Category List", link: `${masterUrl}` },
            { title: (categoryId > 0) ? "Edit Category" : "Add Category" },
        ]
    };

    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
        populateForm, //to populate the values in case of edit
    } = useForm({ // the hook we are going to create
        initialValues: initialFormData,//pass the initializing data 
        validations: { // all our validation rules go here
            name: {
                required: {
                    value: true,
                    message: 'Tool Name is required',
                }
            }
        },
        onSubmit: () => {
            //trigger the actual post submit function from here 
            submitForm(data)
        }
    });

    //loads the initial data when form is loading//
    useEffect(() => {

        if (categoryId > 0) {
            toolsCategoryServiceObject.getDetail(categoryId).then(response => {
                initialFormData.name = response?.name || '';
                initialFormData.description = response?.description || '';
                populateForm(initialFormData);
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line
    }, [])

    const submitForm = async (data) => {
        let response;
        if (categoryId > 0) {
            response = await toolsCategoryServiceObject.update(data, categoryId);
        } else {
            response = await toolsCategoryServiceObject.create(data);
        }
        if (response) {
            toast.success(response.message || (categoryId > 0) ? MSG_UPDATE_SUCCESS : MSG_ADD_SUCCESS);
            history.push(`${masterUrl}`);
        }

    }
    return (
        <Grid container spacing={1} className={classes.outerContainer}>
            <Grid item xs={10}>
                <Breadcrumb data={metaInfo.breadcrumData} />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.contentRoot}>
                    <form onSubmit={handleSubmit} noValidate>
                        <TextField fullWidth
                            name="name"
                            label="Category Name"
                            variant="outlined"
                            placeholder="Category Name"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}

                            value={data.name || ''}
                            onChange={handleChange('name')}
                            error={(errors['name'] && errors['name'] !== undefined) ? true : false}
                            helperText={(errors['name'] && errors['name'] !== undefined) ? errors['name'] : null}
                        />
                        <TextField fullWidth
                            name="description"
                            label="Category Description"
                            variant="outlined"
                            placeholder="Category Description"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}

                            value={data.description || ''}
                            onChange={handleChange('description')}
                            error={(errors['description'] && errors['description'] !== undefined) ? true : false}
                            helperText={(errors['description'] && errors['description'] !== undefined) ? errors['description'] : null}
                        />




                        <Grid item xs={12}>
                            <Box className={classes.masterBtn} display="flex" justifyContent='right'>
                                <Button className='back-btn' component={Link} to={`${masterUrl}`} >
                                    Back
                                </Button>
                                <Button className='add-btn' type="submit" >
                                    {(categoryId > 0) ? 'Update' : 'Add'}
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(Form);