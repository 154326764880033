import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import GroupService from "services/admin/masters/GroupService";
import { HvBox, HvGrid, HvInput, HvButton, HvTypography } from '@hitachivantara/uikit-react-core';
import { useForm } from "hooks/useForm";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import { Backwards } from '@hitachivantara/uikit-react-icons';
import { MSG_ADD_SUCCESS, MSG_UPDATE_SUCCESS } from "constants/admin/masters/groups";
import UserPermissions from "components/permission/UserPermissions";

const groupServiceObject = new GroupService();
const Form = ({ history, ...props }) => {
    const classes = useStyles();
    const { url, params } = props.match;
    const groupId = params?.groupId || '';
    //const masterUrl = getMasterBaseUrl(url, params);
    const masterUrl = "/admin/masters/users?tabValue=1"

    const [allUsers, setAllUsers] = useState([]);

    const initialFormData = { // used to initialize the data
        name: '',
        description: '',
        users: []
    };

    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        handleChangeCustom,
        data, // access to the form data
        errors, // includes the errors to show
        populateForm, //to populate the values in case of edit
    } = useForm({ // the hook we are going to create
        initialValues: initialFormData,//pass the initializing data
        validations: { // all our validation rules go here
            name: {
                required: {
                    value: true,
                    message: 'Group Name is required',
                }
            },
            users: {
                required: {
                    value: true,
                    message: 'Atleast 2 user is required to create a group',
                }
            },

        },
        onSubmit: () => {
            //trigger the actual post submit function from here
            submitForm(data)
        }
    });

    //loads the initial data when form is loading//
    useEffect(() => {

        groupServiceObject.getAllUsers().then(response => {
            setAllUsers(response)
        }).catch(err => {
            console.log('error in get all users data')
        })

        if (groupId > 0) {
            groupServiceObject.getDetail(groupId).then(response => {
                initialFormData.name = response?.name || '';
                initialFormData.description = response?.description || '';
                initialFormData.users = response?.users?.map(u => u.id) || [];
                populateForm(initialFormData);
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line
    }, [])

    const submitForm = async (data) => {
        let response;
        if (groupId > 0) {
            response = await groupServiceObject.update(data, groupId);
        } else {
            response = await groupServiceObject.create(data);
        }
        if (response) {
            toast.success(response.message || (groupId > 0) ? MSG_UPDATE_SUCCESS : MSG_ADD_SUCCESS);

            history.replace(`${masterUrl}`);
        }

    }

    return (
        <HvGrid container spacing={1} className={classes.outerContainer}>
            <HvButton variant='primaryGhost' component={Link} to={`${masterUrl}`} ><Backwards /></HvButton>
            <HvTypography variant="sTitle">
                {Number(groupId) > 0 ? "Edit Group" : "Add Group"}</HvTypography>

            <HvGrid item xs={12}>
                <div className={classes.contentRoot} >
                    <form onSubmit={handleSubmit} noValidate>
                        <HvInput fullWidth
                            name="name"
                            label="Group Name"
                            variant="outlined"
                            placeholder="Group Name"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}

                            value={data.name}
                            error={(errors['name'] && errors['name'] !== undefined) ? true : false}
                            onChange={handleChange('name')}
                            helperText={(errors['name'] && errors['name'] !== undefined) ? errors['name'] : null}
                        />
                        {(errors['name'] && errors['name'] !== undefined) ? <p className={classes.errorLabel}>{errors['name']}</p> : null}
                        <div style={{ paddingTop: "10px" }}>
                            <HvInput fullWidth
                                name="description"
                                label="Description"
                                variant="outlined"
                                placeholder="Description"
                                classes={{ root: classes.customTextField }}
                                InputProps={{
                                    classes: {
                                        input: classes.inputField,
                                        root: classes.base,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline
                                    },
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: { color: '#2a2c2e' },
                                }}
                                value={data.description || ''}
                                onChange={handleChange('description')}
                                error={(errors['description'] && errors['description'] !== undefined) ? true : false}
                                helperText={(errors['description'] && errors['description'] !== undefined) ? errors['description'] : null}
                            />
                        </div>



                        <HvGrid item xs={12} style={{ marginTop: "15px" }}>
                            <div className={classes.externalFormControl}  >
                                <HvTypography color="textPrimary" className='typo-header'>Users</HvTypography>
                                <UserPermissions changeHandler={handleChangeCustom} allUsers={allUsers} selectedUsers={data.users} />
                                {(errors['users'] && errors['users'] !== undefined) ? <p className={classes.errorLabel}>{errors['users']}</p> : null}
                            </div>
                        </HvGrid>

                        <HvGrid item xs={12}>
                            <HvBox className={classes.masterBtn} style={{ display: "flex", justifyContent: 'right', gap: "10px" }}>
                                <HvButton variant='secondaryGhost' component={Link} to={`${masterUrl}`} >
                                    Back
                                </HvButton>
                                <HvButton type="submit" variant='primarySubtle'>
                                    {(groupId > 0) ? 'Update' : 'Add'}
                                </HvButton>
                            </HvBox>
                        </HvGrid>
                    </form>
                </div>
            </HvGrid>
        </HvGrid>
    );
}

export default withRouter(Form);