import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DataGrid from "components/datagrid/DataGrid";
import _ from "lodash";
import { MSG_WARNING_DELETE, MSG_DELETE_SUCCESS, MSG_WARNING_UNDO_DELETE, MSG_UNDO_RECORD } from "constants/admin/masters/labCategory";
import Breadcrumb from "components/Breadcrumb";
import { Box, Button, Grid } from "@material-ui/core";
import { useStyles } from "utils/theme";
import Toggle from "components/Toggle";
import { buildQueryParams } from "utils/common";
import LabService from "services/LabService";

const labServiceObj = new LabService();
const List = (props) => {
    const classes = useStyles();
    const { url: masterUrl } = props.match;
    const [refreshToken, setRefreshToken] = useState(Date.now());
    const [pageSize, setPageSize] = useState(20);
    const [customFilter, setFilters] = useState({ "status": 'Active' });
    const metaInfo = {
        filtering: true,
        columns: [
            { title: "Name", field: "name" },
            { title: "Description", field: "description", render: row => row.description && row.description.length > 350 ? renderHTML(row) : <div className='description' dangerouslySetInnerHTML={{ __html: row.description || '' }} /> },
            { title: "Categories", field: "categories" }
        ],
        actions: [(row) => decideAction(row, 'undo'), (row) => decideAction(row, 'edit'), (row) => decideAction(row, 'delete')],
        breadcrumData: [
            { title: "Home", link: "/labs" },
            { title: "Lab List" },
        ]
    };
    const handleRefresh = (e) => {
        setRefreshToken(Date.now());
    };

    const ReadMore = ({ children }) => {
        // Keep this constant and function in this button in order for the button to work properly
        const [isReadMore, setIsReadMore] = useState(true); 
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };

        const text = children;
        const outputString = isReadMore ? text.slice(0, 350) : text
        return (
            <div className="text">
                <span className='description' dangerouslySetInnerHTML={{ __html: outputString || '' }} />
                <span onClick={toggleReadMore} className={classes.readOrHide}>
                    {isReadMore ? "...Read more" : " Read less"}
                </span>
            </div>
        );
    };

    const renderHTML = (row) => {
        const text = row.description;
        return (
           <p>
            {text.length > 50 ? <ReadMore>
                {text}
            </ReadMore> :
                <div className='description' dangerouslySetInnerHTML={{ __html: row.description || '' }} />
            }
            </p>
        );
    }

    const decideAction = (rowData, actionType) => {

        if (actionType === 'undo') {
            return (rowData.is_deleted === true) ? (
                {
                    icon: 'undo',
                    iconProps: { style: { fontSize: "20px", color: "#2a2c2e" } },
                    tooltip: "Undo Deleted Record",
                    onClick: (event, rowData) => {
                        if (window.confirm(MSG_WARNING_UNDO_DELETE.replace("ITEM_NAME", rowData.name))) {
                            labServiceObj.undoDelete(rowData.id).then((response) => {
                                toast.success(response.message || MSG_UNDO_RECORD);
                                handleRefresh();
                            });
                        }

                    },
                }

            ) : null;
        }
        else if (actionType === 'edit') {
            return (rowData.is_deleted === false) ? (
                {
                    icon: 'edit',
                    tooltip: "Edit Record",
                    iconProps: { style: { fontSize: "20px", color: "#2a2c2e" } },
                    onClick: (event, rowData) => {
                        props.history.push(`${masterUrl}/edit/${rowData.id}`);
                    },
                }
            ) : null;
        }
        else if (actionType === 'delete') {
            return (rowData.is_deleted === false) ? ({
                icon: 'delete',
                tooltip: "Delete Record",
                iconProps: { style: { fontSize: "20px", color: "#2a2c2e", } },
                onClick: (event, rowData) => {
                    if (window.confirm(MSG_WARNING_DELETE.replace("ITEM_NAME", rowData.name))) {
                        labServiceObj.deleteLab(rowData.id).then((response) => {
                            toast.success(response.message || MSG_DELETE_SUCCESS);
                            handleRefresh();
                        });
                    }
                },
            }) : null;
        }
    }

    const toggleChangehandler = (e) => {
        e === '' ? setFilters(null) : setFilters({ "status": e })
        setRefreshToken(Date.now());
    }


    const handlePagingData = (query) => {
        return new Promise((resolve, reject) => {
            let urlParams = { ordering: "-id" }; //default sorting - is used for decending
            if (!_.isEmpty(query.orderBy)) {
                urlParams.ordering = (query.orderDirection === 'desc' ? '-' : '') + query.orderBy.field;
            }
            setPageSize(query.pageSize);
            if (!_.isEmpty(query.filters)) {
                query.filters.map(filter => urlParams[filter.column.field] = filter.value);
            }
            if (customFilter) {
                urlParams = { ...urlParams, ...customFilter };
            }
            urlParams['limit'] = query.pageSize;
            urlParams['offset'] = query.pageSize * query.page;
            if (urlParams.status) {
                urlParams['is_deleted'] = urlParams.status === 'Retired' ? true : false;
            }
            let params = {
                tabIndex: 1
            }
            if(urlParams.status === "Retired"){
                delete urlParams['is_deleted'];
            }
            if(urlParams.status === "All"){
                delete urlParams['is_deleted'];
                delete urlParams['status']
            }
            labServiceObj.runningLabUrl(params, buildQueryParams(urlParams)).then((response) => {
                resolve({
                    data: response.results,
                    page: query.page,
                    totalCount: response.count,
                });
            }).catch(error => {
                console.log(error)
            });
        });
    }

    return (
        <>
            <Grid container spacing={1} className={classes.outerContainer}>
                <Grid item xs={10}>
                    <Breadcrumb data={metaInfo.breadcrumData} />
                </Grid>
                <Grid style={{ padding: 0 }} item xs={2}>
                    <Box className={classes.masterBtn} display="flex" flexDirection="row-reverse" >
                        <Button component={Link} to={`${masterUrl}/add`}>
                            Add Lab
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Toggle triggerHandler={toggleChangehandler} default={ 'Active'} options={[{ name: 'Active', value: 'Active' }, { name: 'In development', value: 'In development' }, { name: 'Retired', value: 'Retired' }, { name: 'All', value: 'All' }]} />
                </Grid>
                <Grid item xs={12} style={{ zIndex :1}}>
                    <div className={classes.contentGrid} >
                        <DataGrid
                            actions={metaInfo.actions}
                            columns={metaInfo.columns}
                            dataHandler={handlePagingData}
                            filtering={metaInfo.filtering}
                            pageSize={pageSize}
                            refreshToken={refreshToken}
                        ></DataGrid>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
export default List;