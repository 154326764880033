import React, { useState } from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';
import { HvTooltip, HvTypography } from '@hitachivantara/uikit-react-core';
export const RESIZER_DEFAULT_CLASSNAME = 'Resizer';

class Resizer extends React.Component {
 
  render() {
    const {
      className,
      onClick,
      onDoubleClick,
      onMouseDown,
      onTouchEnd,
      onTouchStart,
      resizerClassName,
      split,
      style,
    } = this.props;
    const classes = [resizerClassName, split, className];

    const data = <HvTypography>Double Click, Hold, and Drag to ReSize</HvTypography>
    return (
      <>
        <HvTooltip title={data} placement='right'>
          <span
            role="presentation"
            className={classes.join(' ')}
            style={style}
            onMouseDown={event => onMouseDown(event)}
            onTouchStart={event => {
              event.preventDefault();
              onTouchStart(event);
            }}
            onTouchEnd={event => {
              event.preventDefault();
              onTouchEnd(event);
            }}
            onClick={event => {
              if (onClick) {
                event.preventDefault();
                onClick(event);
              }
            }}
            onDoubleClick={event => {
              if (onDoubleClick) {
                event.preventDefault();
                onDoubleClick(event);
              }
            }}
            onMouseOver={event=>{
              event.preventDefault();
              onTouchEnd(event);
            }}
          />
        </HvTooltip>




      </>

    );
  }
}

Resizer.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  onMouseDown: PropTypes.func.isRequired,
  onTouchStart: PropTypes.func.isRequired,
  onTouchEnd: PropTypes.func.isRequired,
  split: PropTypes.oneOf(['vertical', 'horizontal']),
  style: stylePropType,
  resizerClassName: PropTypes.string.isRequired,
};

Resizer.defaultProps = {
  resizerClassName: RESIZER_DEFAULT_CLASSNAME,
};

export default Resizer;
