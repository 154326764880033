import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ROLES from "constants/roles";
import SYSTEM_GROUPS from "constants/system_groups";
import UserService from "services/admin/masters/UserService";
import Breadcrumb from "components/Breadcrumb";
import { Box, Grid, TextField, Button, FormControl } from "@material-ui/core";
import { getMasterBaseUrl } from "utils/masterHelpers";
import { useForm } from "hooks/useForm";
import { Link, withRouter } from "react-router-dom";
import { useStyles } from "utils/theme";
import { MSG_ADD_SUCCESS, MSG_UPDATE_SUCCESS } from "constants/admin/masters/users";
import MultiSelect from "components/MultiSelect";
import { transformRoles } from "utils/common";
const userServiceObject = new UserService();
const Form = ({ history, ...props }) => {

    const classes = useStyles();
    const { url, params } = props.match;
    const userId = params?.userId;
    const masterUrl = getMasterBaseUrl(url, params);

    const [groups, setGroupsData] = useState([]);
    // const [reports, setReportsData] = useState([])
    const initialFormData = { // used to initialize the data
        full_name: '',
        email: '',
        phone: '',
        address: '',
        country: '',
        hdsusername: '',
        company_name: '',
        roles: [ROLES.Lab_User],//by default lab user will be there
        groups: [],
        reports: []
    };
    const metaInfo = {
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "User List", link: `${masterUrl}` },
            { title: (userId > 0) ? "Edit User" : "Add User" },
        ]
    };

    const allowOnlyOneSystemGroup = groups => {
        const allSystemGroups = Object.values(SYSTEM_GROUPS);
        const selectedSystemGroups = groups.filter(g => allSystemGroups.includes(g));
        if (selectedSystemGroups.length > 1) {
            return false;
        } else {
            return true;
        }
    }


    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        handleChangeCustom,//handle chaneg on custom components
        data, // access to the form data
        errors, // includes the errors to show
        populateForm, //to populate the values in case of edit
    } = useForm({ // the hook we are going to create
        initialValues: initialFormData,//pass the initializing data 
        validations: { // all our validation rules go here
            email: {
                required: {
                    value: true,
                    message: 'Email is required',
                },
                pattern: {
                    value: '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
                    message: 'Email is not valid',
                },
            },
            phone: {
                pattern: {
                    value: '^$|^(\\+\\d{1,2}\\s?)?1?\\-?\\.?\\s?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$',
                    message: 'Phone number is not valid',
                },
            },
            groups: {
                required: {
                    value: true,
                    message: 'Atleast 1 group need to select',
                },
                custom: {
                    isValid: allowOnlyOneSystemGroup,
                    message: `More than one system group selected`,
                }
            },
            roles: {
                required: {
                    value: true,
                    message: 'Atleast 1 role need to select',
                }
            }

        },
        onSubmit: () => {
            //trigger the actual post submit function from here 
            submitForm(data)
        }
    });

    //loads the initial data when form is loading//
    useEffect(() => {
        userServiceObject.getAllGroups().then(response => {
            setGroupsData(response)
        }).catch(err => {
            console.log('error in groups data')
        })
        // userServiceObject.getAllReports().then(response => {
        //     setReportsData(response)
        // }).catch(err => {
        //     console.log('error in groups data')
        // })



        if (userId > 0) {
            userServiceObject.getDetail(userId).then(response => {
                initialFormData.full_name = response?.full_name || '';
                initialFormData.email = response?.email || '';
                initialFormData.phone = response?.phone || '';
                initialFormData.address = response?.address || '';
                initialFormData.country = response?.country || '';
                initialFormData.hdsusername = response?.hdsusername || '';
                initialFormData.company_name = response?.company_name || '';
                initialFormData.roles = response?.roles?.map(rl => rl.id) || [];
                initialFormData.groups = response?.groups?.map(gp => gp.id) || [];
                initialFormData.reports = response?.reports?.map(rp => rp.id) || [];
                populateForm(initialFormData);
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line
    }, [])
    //

    // const isReportsDisabled = () => {
    //     if (data.roles?.includes(ROLES.System_Admin)) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }


    const submitForm = async (data) => {
        let response;
        if (userId > 0) {
            response = await userServiceObject.update(data, userId);
        } else {
            response = await userServiceObject.create(data);
        }
        if (response) {
            toast.success(response.message || (userId > 0) ? MSG_UPDATE_SUCCESS : MSG_ADD_SUCCESS);
            history.push(`${masterUrl}`);
        }

    }

    return (
        <Grid container spacing={1} className={classes.outerContainer}>
            <Grid item xs={10}>
                <Breadcrumb data={metaInfo.breadcrumData} />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.contentRoot}>
                    <form onSubmit={handleSubmit} noValidate>
                        <TextField fullWidth
                            name="full_name"
                            label="Full Name"
                            variant="outlined"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.full_name || ''}
                            onChange={handleChange('full_name')}
                            error={(errors['full_name'] && errors['full_name'] !== undefined) ? true : false}
                            helperText={(errors['full_name'] && errors['full_name'] !== undefined) ? errors['full_name'] : null}
                        />
                        <TextField fullWidth
                            type="email"
                            name="email"
                            label="Email"
                            disabled={userId > 0}
                            variant="outlined"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.email || ''}
                            onChange={handleChange('email')}
                            error={(errors['email'] && errors['email'] !== undefined) ? true : false}
                            helperText={(errors['email'] && errors['email'] !== undefined) ? errors['email'] : null}
                        />
                        <TextField fullWidth
                            name="phone"
                            label="Phone"
                            variant="outlined"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.phone || ''}
                            onChange={handleChange('phone')}
                            error={(errors['phone'] && errors['phone'] !== undefined) ? true : false}
                            helperText={(errors['phone'] && errors['phone'] !== undefined) ? errors['phone'] : null}
                        />
                        <TextField fullWidth
                            name="address"
                            label="Address"
                            variant="outlined"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.address || ''}
                            onChange={handleChange('address')}
                            error={(errors['address'] && errors['address'] !== undefined) ? true : false}
                            helperText={(errors['address'] && errors['address'] !== undefined) ? errors['address'] : null}
                        />
                        <TextField fullWidth
                            name="country"
                            label="Country"
                            variant="outlined"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.country || ''}
                            onChange={handleChange('country')}
                            error={(errors['country'] && errors['country'] !== undefined) ? true : false}
                            helperText={(errors['country'] && errors['country'] !== undefined) ? errors['country'] : null}
                        />
                        <TextField fullWidth
                            name="hdsusername"
                            label="HDS Username"
                            // disabled={userId > 0}    This is commented out to satisfy ELGDLHALO-1545
                            variant="outlined"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.hdsusername || ''}
                            onChange={handleChange('hdsusername')}
                            error={(errors['hdsusername'] && errors['hdsusername'] !== undefined) ? true : false}
                            helperText={(errors['hdsusername'] && errors['hdsusername'] !== undefined) ? errors['hdsusername'] : null}
                        />
                        <TextField fullWidth
                            name="company_name"
                            label="Company Name"
                            variant="outlined"
                            classes={{ root: classes.customTextField }}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}
                            value={data.company_name || ''}
                            onChange={handleChange('company_name')}
                            error={(errors['company_name'] && errors['company_name'] !== undefined) ? true : false}
                            helperText={(errors['company_name'] && errors['company_name'] !== undefined) ? errors['company_name'] : null}
                        />


                        <MultiSelect
                            name={'groups'}
                            selected={data?.groups}
                            onChange={handleChangeCustom}
                            title={'Groups'}
                            data={groups}
                            error={errors['groups']}
                            handleBlur={() => { }}
                        />

                        <MultiSelect
                            name={'roles'}
                            alwaysSelected={[ROLES.Lab_User]}
                            selected={data?.roles}
                            onChange={handleChangeCustom}
                            title={'Roles'}
                            data={transformRoles(ROLES)}
                            error={errors['roles']}
                            handleBlur={() => { }}
                        />

                        {/* <MultiSelect
                            disabled={isReportsDisabled()}
                            name={'reports'}
                            selected={!isReportsDisabled() ? data?.reports : []}
                            onChange={handleChangeCustom}
                            title={'Reports'}
                            data={reports}
                            error={errors['reports']}
                            handleBlur={() => { }}
                        /> */}

                        <FormControl fullWidth >
                            <Grid item xs={12}>
                                <Box className={classes.masterBtn} display="flex" justifyContent='right'>
                                    <Button className='back-btn' component={Link} to={`${masterUrl}`} >
                                        Back
                                    </Button>
                                    <Button className='add-btn' type="submit" >
                                        {(userId > 0) ? 'Update' : 'Add'}
                                    </Button>
                                </Box>
                            </Grid>
                        </FormControl>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(Form);