const ROLES = {
    User: 1,
    System_User: 2,
    Technical_Editor: 3,
    // Lab_User: 1,
    // Resource_Admin: 2,
    // Lab_Admin: 3,
    // System_Admin: 4,
    // Lab_Author:5
}
export default ROLES;