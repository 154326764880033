import React, { useEffect, useState, forwardRef, useMemo, useRef, Fragment } from 'react';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { profileMenu } from 'constants/profileMenu';
import { checkPermissions } from 'utils/masterHelpers';
import uuid from 'react-uuid';
import './style.scss';
import ThemeSwtich from './ThemeSwtich';
import store from "redux/store";
import { CHANGE_THEME, LOGGED_IN_USER_INFO } from "constants/common";
import { useSelector, useDispatch } from "react-redux";
import { useOktaAuth } from '@okta/okta-react';
import { OKTA_TOKEN_INFO, SHOW_BANNER } from "constants/common";
import { LANGUAGE_GENERAL_SSO_ERROR } from 'constants/login';
import LoginService from "../../services/LoginService";
import { withRouter } from 'react-router';
import { showBanner } from 'redux/actions/bannerActions';
import { useAuth0 } from "@auth0/auth0-react";


import { css } from "@emotion/css";
import {
  HvPanel,
  HvTreeItem,
  HvTreeView,
  HvTypography,
  theme,
  useHvTreeItem,
} from "@hitachivantara/uikit-react-core";
import { Storage, DropDownXS } from "@hitachivantara/uikit-react-icons";
import { mergeStyles } from "@hitachivantara/uikit-react-utils";


const classes = {
  group: css({
    marginLeft: 0,
  }),
  content: css({
    borderLeft: "4px solid transparent",
    paddingLeft: `calc(${theme.space.sm} * var(--level))`,
  }),
  selected: css({
    borderColor: theme.colors.secondary,
    backgroundColor: theme.colors.atmo3,
  }),
  label: css({
    display: "flex",
    alignItems: "center",
  }),
};
const NavigationItem = forwardRef((props, ref) => {
  const { children, nodeId, label, icon: IconComponent, onOpen, onClick, ...others } = props;
  const { disabled, expanded, level } = useHvTreeItem(nodeId);

  return (
    <HvTreeItem
      ref={ref}
      nodeId={nodeId}
      style={mergeStyles(
        {},
        {
          "--level": level,
          pointerEvents: disabled ? "none" : undefined,
        },
      )}
      classes={{
        group: classes.group,
        content: classes.content,
        selected: classes.selected,
      }}
      icon="" // remove left nav icon
      label={
        <div className={classes.label}>
          {/* Add dynamic icon here */}
          {IconComponent && <IconComponent style={{ marginRight: 8 }} />}
          <HvTypography
            variant={children ? "label" : "body"}
            style={{ flex: 1 }}
          >
            {label}
          </HvTypography>
          {children && (
            <DropDownXS
              style={{ transform: `rotate(${expanded ? 180 : 0}deg)` }}
            />
          )}
        </div>
      }
      {...others}
    >
      {children}
    </HvTreeItem>
  );
});

function useOutsideAlerter(ref, setProfileMenu) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (event.target.id !== 'user-profile') {
          setProfileMenu(false)
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setProfileMenu]);
}


function NestedMenu(props) {
  const [selected, setSelected] = useState("-1");
  const [currentMenus, setCurrentMenus] = useState(profileMenu);
  const [previousStack, setPreviousStack] = useState([]);
  const [state, setState] = useState(false);
  const { userInfo, setProfileMenu } = props;
  const { full_name, role } = userInfo;
  const userRoles = Object.values(userInfo?.roles[0]);
  const userGroups = userInfo?.groups?.map(group => group.id);
  const wrapperRef = useRef(null);
  const theme = useSelector((state) => state.themeReducer.theme);
  const dispatch = useDispatch();
  const loginServiceObj = new LoginService();
  useOutsideAlerter(wrapperRef, setProfileMenu);
  const { isAuthenticated, logout } = useAuth0();
  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_returnTo,
        clientId: process.env.REACT_APP_OKTA_CLIENT_ID
      }
    });
  const findUrlById = (menu, id) => {
    for (const item of menu) {
      if (item.id === id) {
        return item.url || null;
      }
      if (item.data) {
        const foundUrl = findUrlById(item.data, id);
        if (foundUrl) {
          return foundUrl;
        }
      }
    }
    return null;
  };

  const redirectTo = (evt, nodeId) => {
    const url = findUrlById(profileMenu, nodeId)
    if (url === null || url === undefined) {

      return
    }
    const data = localStorage.getItem(OKTA_TOKEN_INFO);
    const token = data ? JSON.parse(data) : {}
    const { history } = props;
    if (url === "/logout") {
      localStorage.clear("LabUrl");
      if (isAuthenticated) {
        let logouturl = "";
        try {
          loginServiceObj.getLogoutUrl().then(async result => {
            if (result?.logouturl) {
              logouturl = result?.logouturl;
              sessionStorage.clear(LOGGED_IN_USER_INFO);
              window.location.href=logouturl
            }
          }).catch(() => {
            store.dispatch({
              type: SHOW_BANNER,
              payload: {
                showBanner: true,
                label: { message: LANGUAGE_GENERAL_SSO_ERROR },
                variant: "error"
              }
            })
          });

        } catch (err) {
          console.log(err)
        }
      } else {
        loginServiceObj.getLogoutUrl().then(async result => {
          if (result?.logouturl) {
            window.location.href = result.logouturl;
            sessionStorage.clear(LOGGED_IN_USER_INFO);
            store.dispatch({
              type: SHOW_BANNER,
              payload: {
                showBanner: true,
                label: { message: "You have been logged out." },
                variant: "success"
              }
            })

          }

        }).catch(() => {
          store.dispatch({
            type: SHOW_BANNER,
            payload: {
              showBanner: true,
              label: { message: LANGUAGE_GENERAL_SSO_ERROR },
              variant: "error"
            }
          })
        });

      }
    } else {
      history.push(url);
      setProfileMenu(false)
    }


  }



  const changeTheme = (_evt, newChecked) => {
    setState(newChecked);
    store.dispatch({
      type: CHANGE_THEME,
      payload: newChecked ? "wicked" : "dawn"

    });


  }



  useEffect(() => {
    theme === "wicked" ? setState(true) : setState(false)
  }, [theme])

  /** Render tree view items with icons */
  const renderItem = ({ id, label, data, icon, requiredRoles = [] }) => {

    if (!checkPermissions({ requiredRoles }, userRoles, userGroups)) {
      return null
    }

    return (<NavigationItem key={id} nodeId={id} label={label} icon={icon}>
      {data?.map(renderItem)}
    </NavigationItem>
    )
  };
  return (
    <HvPanel className='dropdown-content' ref={wrapperRef} style={{ width: 300 }}>
      <HvTreeView
        multiSelect={false}
        aria-label="site navigation"
        selected={selected}
        onNodeSelect={(evt, nodeId) => redirectTo(evt, nodeId)}
      >
        {profileMenu.map(renderItem)}
      </HvTreeView>
    </HvPanel >
  );
}

export default withRouter(NestedMenu);