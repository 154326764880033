import NetworkService from "services/NetworkService";
import {
    METHOD_LIST,
    METHOD_FAQ
} from "constants/admin/masters/faq";

class FAQService {
    getAllFaqList = (urlParams) => {
        return NetworkService
            .get(`${METHOD_LIST}?${urlParams}`)
            .then((response) => response.data);
    };
    createFaqCategory = (data) => {
        return NetworkService
            .post(METHOD_LIST, data)
            .then((response) => response.data);
    }
    updateFaqCategory = (data, categoryId) => {
        return NetworkService
            .patch(`${METHOD_LIST}${categoryId}/`, data)
            .then((response) => response.data);
    }
    getDetailByCategoryId = (categoryId) => {
        return NetworkService
            .get(`${METHOD_LIST}${categoryId}/`)
            .then((response) => response.data);
    }
    deleteFaqCategory = (categoryId) => {
        return NetworkService
            .delete(`${METHOD_LIST}${categoryId}/`)
            .then((response) => response.data);
    }
    createFaqQuestion = (data) => {
        return NetworkService
            .post(METHOD_FAQ, data)
            .then((response) => response.data);
    }
    updateFaqQuestion = (data, questionId) => {
        return NetworkService
            .patch(`${METHOD_FAQ}${questionId}/`, data)
            .then((response) => response.data);
    }
    getDetailByQuestionId = (questionId) => {
        return NetworkService
            .get(`${METHOD_FAQ}${questionId}/`)
            .then((response) => response.data);
    }
    deleteFaqQuestion = (questionId) => {
        return NetworkService
            .delete(`${METHOD_FAQ}${questionId}/`)
            .then((response) => response.data);
    }
}
export default FAQService;
