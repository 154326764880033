import NetworkService  from "services/NetworkService";
import {
  METHOD_LIST,
  METHOD_CREATE,
  Method_Activate_Lab_Url
} from "constants/admin/masters/rogueSession";
class RogueSessionService {
  getAll = (urlParams) => {
    return NetworkService
      .get(`${METHOD_LIST}?${urlParams}`)
      .then((response) => response.data);
  };
  create = (data) => {
    return NetworkService
      .post(METHOD_CREATE, data)
      .then((response) => response.data);
  }; 
  terminateLab = (payload,params) => {
    return NetworkService
      .delete(Method_Activate_Lab_Url + `/${payload.labId}/?${params}`)
      .then((response) => response.data);
  }; 
}
export default RogueSessionService;
