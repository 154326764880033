import { useEffect, useRef, useState } from 'react'
import { DateRangePicker, defaultStaticRanges } from 'react-date-range'

import format from 'date-fns/format'
import { 
  endOfDay,
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
} from "date-fns";

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const DateRangePickerComp = ( {handleDateRangeChange} ) => {

  // date state
  const [range, setRange] = useState([
    {
      startDate: startOfYear(addYears(new Date(), -2)),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  // open close
  const [open, setOpen] = useState(false)

  // get the target element to toggle 
  const refOne = useRef(null)

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    if( e.key === "Escape" ) {
      setOpen(false)
    }
  }

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    if( refOne.current && !refOne.current.contains(e.target) ) {
      setOpen(false)
    }
  }

  const handleDateRangePicker = (date) => {
    // setRange([data.selection])
    setRange([date.selection])
    let check_date = date.selection.startDate.toDateString() === startOfYear(addYears(new Date(), -2)).toDateString()
    handleDateRangeChange(check_date ? {} : date.selection);
}

  return (
    <div className="calendarWrap">
      <input
        style={{height: '36px' ,border: '1px solid #2a2c2e', borderRadius: '4px', padding: '0px 6px'}}
        value={range[0].startDate.toDateString() === startOfYear(addYears(new Date(), -2)).toDateString() ?  "All" : `${format(range[0].startDate, "MM/dd/yyyy")} to ${format(range[0].endDate, "MM/dd/yyyy")}`}
        readOnly
        className="inputBox"
        onClick={ () => setOpen(open => !open) }
      />

      <div ref={refOne} style={{position:'absolute' ,zIndex:'1000' ,right:'2%'}}>
        {open && 
          <DateRangePicker
            onChange={item => handleDateRangePicker(item)}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
            inputRanges={[]}
            staticRanges={[
              ...defaultStaticRanges,
              {
                label: "Last year",
                range: () => ({
                  startDate: startOfYear(addYears(new Date(), -1)),
                  endDate: endOfYear(addYears(new Date(), -1))
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                }
              },
              {
                label: "This year",
                range: () => ({
                  startDate: startOfYear(new Date()),
                  endDate: endOfDay(new Date())
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                }
              },
              {
                label: "All",
                range: () => ({
                  startDate: startOfYear(addYears(new Date(), -2)),
                  endDate: new Date()
                }),
                isSelected(range) {
                  const definedRange = this.range();
                  return (
                    isSameDay(range.startDate, definedRange.startDate) &&
                    isSameDay(range.endDate, definedRange.endDate)
                  );
                }
              },
            ]}
          />
        }
      </div>

    </div>
  )
}

export default DateRangePickerComp