
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    textField: {
        marginTop: '16px !important',
        "& input::placeholder": {
            fontSize: "14px"
        },
        "& .MuiInputBase-root.Mui-disabled": {
            background: '#ccc',
            opacity: .3
        },
        "& .MuiFormLabel-root.Mui-focused": {
            color: '#2a2c2e',
        }
    },
    inputField: {
        fontSize: 14,
        padding: '12px !important',
    },
    base: {
        '&$focused $notchedOutline': {
            borderColor: '#2a2c2e',
            border: '1px solid #2a2c2e',
        },
    },
    focused: {
    },
    notchedOutline: {
        border: "1px solid #2a2c2e !important",
    },
    menuItem: {
        fontSize: 14,
        padding: '6px 12px 6px 12px '
    },
    selectLabel: {
        fontSize: 16,
        color: '#2a2c2e',
        fontWeight: 500,
        minWidth: 122,
        margin: 'auto 0',
        marginRight: 12,
        whiteSpace: 'inherit',
        textAlign: 'right',
    },
    formControl: {
        marginTop: 16,
        " & .MuiFormLabel-root.Mui-focused": {
            color: '#2a2c2e',
        }
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    moveToRight: {
        transform: 'rotate(-90deg)',
        fontSize: 36,
        color: '#bd0912',
        background: '#e1e2e3',
        borderRadius: '4px',
        cursor: 'pointer'
    },
    moveToLeft: {
        transform: 'rotate(90deg)',
        fontSize: 36,
        color: '#bd0912',
        background: '#e1e2e3',
        borderRadius: '4px',
        marginTop: '8px',
        cursor: 'pointer'
    },
    header: {
        // background: "#e1e2e3",
        color: '#2a2c2e',
        fontSize: '16px',
        textAlign: 'center',
        fontWeight: '500'
    },
    searchContainer: {
        marginTop: 8,
        marginBottom: 4,
        " & .search-container": {
            width: '100%',
            position: 'relative',
            paddingLeft: '0px',
            " & .searchbar": {
                width: '97%',
            },
            " & .search-icon": {
                position: 'absolute',
                bottom: 10,
                right: 10,
            }
        }
    },
    listContainer: {
        maxHeight: '418px',
        height: 'auto',
        overflow: 'auto',
        marginRight: '10px',
        " & .MuiListItem-root": {
            padding: 0,
            " & .MuiListItemText-root": {
                " & .MuiTypography-root": {
                    fontSize: 14,
                    paddingLeft: 6,
                }
            }
        },
        " & .MuiButtonBase-root": {
            " & .MuiListItemText-multiline": {
                margin: 0,
            }
        },

        " & .MuiIconButton-root": {
            height: 24,
            width: 24,
            marginRight: 8,
        },
        " & .Mui-selected": {
            background: '#bd0912',
            borderRadius: 4,
            marginBottom: 4,
            color: '#fff',
            " & .MuiSvgIcon-root": {
                color: '#fff',
            },
            " & .MuiTypography-root": {
                color: '#fff'
            }
        },
        " & .Mui-selected:hover": {
            background: '#bd0912',
            borderRadius: 4,
            marginBottom: 4,
            color: '#fff',
            " & .MuiSvgIcon-root": {
                color: '#fff',
            }
        },
        " & .MuiList-root": {
            padding: 0,
        }
    },
    arrowContainer: {
        margingLeft: 8,
        marginRight: 8,
    },
    leftRightArrow: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'left',
        position: 'relative',
        top: '50%',
        left: '-2%',
        justifyContent: 'left',
    }
}));