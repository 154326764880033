import React, { forwardRef } from 'react';
import './style.scss';
import { HvInput } from '@hitachivantara/uikit-react-core';
import { css } from '@emotion/css';

function SearchBar({ inputRef, name, changeHandler }) {
   
    const cssClasses = {
        inputSearch : css({
            '& .HvBaseInput-inputRoot': {
                width: '302px',
                margin: '0',
                borderRadius: '4px',
                border: '1px solid'
        },
         '& .HvBaseInput-inputBorderContainer':{
            display : 'none'

         }
        })
    
    }
    return (
        <>
            <div className='search-container'>
                <HvInput
                 type="search"
                 aria-label="Search"
                 placeholder={name ?  "Search User" : "Search lab"}
                 name={name || 'searchBox'}
                 onChange={changeHandler}
                 
                 className={cssClasses.inputSearch}
                 ref={inputRef}
                 autoComplete='off'
                 aria-hidden="true"
                 ></HvInput>
                {/* <input
                    ref={inputRef}
                    className='searchbar'
                    type='search'
                    placeholder='Search...'
                    name={name || 'searchBox'}
                    onKeyDown={(changeHandler) ? changeHandler : null}
                    autoComplete='off'
                    aria-hidden="true"
                    onClick={(changeHandler) ? changeHandler : null}
                />

                <span> <i className="search-icon fa fa-search" aria-hidden="true"  onClick={()=>{console.log("Closed button clicked")}} ></i></span>  */}


            </div>
        </>
    )
}

export default forwardRef(SearchBar)