import { 
  USER_INFO,
  TOKEN_EXPIRE_TIME
} from "constants/login";

const initialState = {
  userInfo:{},
  expiryTime:""
};
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case TOKEN_EXPIRE_TIME:
      return {
        ...state,
        expiryTime: action.payload,
      };
    default:
      return state;
  }
};
export default loginReducer;
