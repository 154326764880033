import { HvBanner } from "@hitachivantara/uikit-react-core";
import zIndex from "@mui/material/styles/zIndex";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const { makeStyles } = require("@mui/styles");

export const useStyles = makeStyles(() => ({
    root: {
      '& .HvBannerContent-outContainer': {
        width : '40%',
        zIndex: 1
      }
    },
  }))


export default function Banner() {
    const classes = useStyles()
    const bannerInfo = useSelector(state => state.bannerReducer.showBanner);
    let [ open, setOpen ] = useState(true)
    const handleClose = () => {
      setOpen(false)
    }

    const thresholdTime = () =>{
        setOpen(false)
    }
    useEffect( () => {
     const intervalId = setInterval(thresholdTime, 10000);
      setOpen(bannerInfo?.payload?.showBanner ? true : false);
      return () => clearInterval(intervalId);
    },[bannerInfo])

    return bannerInfo?.payload?.showBanner ? (
        <HvBanner
          open={open}
          className={classes.root}
          onClose={handleClose}
          offset={83}
          variant={bannerInfo?.payload?.variant}
          showIcon
          label={bannerInfo?.payload?.label.message}
        />
    ) : null;
}