import {
  ADMIN_SETTINGS,
  SERVER_ERRORS
} from "constants/common";
import { encodeBase64 } from "utils/common";
import CommonService from "services/CommonService";
const commonServiceObj = new CommonService();

export const getAdminSettings = () => (dispatch) => {

  commonServiceObj.getAdminSettings().then((response) => {
    dispatch({
      type: ADMIN_SETTINGS,
      payload: response,
    });
  }, (err) => {
    console.log(err)
  });
};


export const saveAdminSettings = (formData) => async (dispatch) => {

  dispatch({
    type: SERVER_ERRORS,
    payload: [],
  });

  try {
    //found and encrypt urls in the form data
    let encryptedData = JSON.parse(JSON.stringify(formData));//deep copy
    encryptedData = encryptedData.map(group => {
      group.adminsettings.map(field => {
        field.value = encodeBase64(field.value);
        return field;
      })
      return group;
    })
    //found and encrypt urls in the form data
    const response = await commonServiceObj.saveAdminSettings(encryptedData);
    if (response) {
      dispatch({
        type: ADMIN_SETTINGS,
        payload: response,
      });
    }

    return true;
  } catch (errors) {

    dispatch({
      type: SERVER_ERRORS,
      payload: errors,
    });

    return false;
  }
};