import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { LANGUAGE_BAD_SSO_URL, LANGUAGE_GENERAL_SSO_ERROR } from 'constants/login';
import { baseUrl, encodeBase64 } from "utils/common";
import LoginService from "services/LoginService";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { showLoader } from "redux/actions/commonActions";
const loginServiceObj = new LoginService();
function SsoLogin({ location, history }) {
  const { from } = location.state || { from: { pathname: "/" } };
  const payload = { returnurl: encodeBase64(baseUrl() + from.pathname) };
  const dispatch = useDispatch();

  useEffect(() => {
    loginServiceObj.getSsoRedirectUrl(payload).then(response => {
      if (response.redirect_url !== '' || response.redirect_url !== null) {
        dispatch(showLoader(true));//show loader untill fully redirect to sso page
        // window.location.href = response.redirect_url;
      } else {
        toast.error(LANGUAGE_BAD_SSO_URL);
      }
    }).catch(e => {
      toast.error(LANGUAGE_GENERAL_SSO_ERROR);
      history.push('/');
    });
  });


  return <div style={{ height: '95vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
      Redirecting to SSO Login Page ... 
  </div>
}

export default withRouter(SsoLogin);