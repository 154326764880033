import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import MultiSelect from "components/MultiSelect";
import { toast } from "react-toastify";
import CssBaseline from '@material-ui/core/CssBaseline';
import { TextField, Box, Typography, Container, Grid, Select, MenuItem, FormControl } from '@material-ui/core/';
import Radio from 'components/radioButton';
import { useForm } from 'hooks/useForm';
import { useStyles } from 'utils/theme';
import { withRouter } from "react-router-dom";
import SingleFileUpload from 'components/fileUpload/SingleFileUpload';
import CoCreationService from 'services/CoCreationService';
import Clear from '@material-ui/icons/Clear';
import { SUCCESS_MESSAGE_COCREATION_FORM } from 'constants/common';
import uuid from 'react-uuid';
import { BootstrapInput } from 'containers/videos/style';

const coCreationObj = new CoCreationService();

const createRadioGroups = (data) => {
    let groupItems = [];
    data.forEach(element => {
        groupItems.push({ name: element.id.toString(), label: element.name })
    });
    return groupItems;
}


function CoCreationForm({ history }) {
    const [coCreationTestingOptions, setCoCreationTestingOptions] = useState([]);
    const [coCreationDeliverableOptions, setCoCreationDeliverableOptions] = useState([]);
    const classes = useStyles();
    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        handleChangeCustom,
        handleMultipleChange,
        data, // access to the form data
        errors, // includes the errors to show
    } = useForm({ // the hook we are going to create
        initialValues: { // used to initialize the data
            name: '',
            company: '',
            email: '',
            alliance_manager: '',
            opportunity_id: '',
            deal_size: 0,
            vendor: '',
            contact: '',
            application_to_be_tested: '',
            expected_deliverable: '1',
            other_expected_deliverable: '',
            for_testing_with: [],
            business_case: '',
            url: null,
            thumbnailUrl: '',
            is_current_partner: '',
            partner_number:null
        },
        validations: { // all our validation rules go here
            company: {
                required: {
                    value: true,
                    message: 'Company is required',
                },
            },
            email: {
                required: {
                    value: true,
                    message: 'Email is required',
                },
                pattern: {
                    value: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,4}$',
                    message: 'Email is not valid',
                },
            },
            name: {
                required: {
                    value: true,
                    message: 'Name is required',
                },
            },
            is_current_partner: {
                custom: {
                    isValid: (value) => value !== '',
                    message: 'Please select partner',
                },
            },
            partner_number: {
                pattern: {
                        value: '^[ A-Za-z0-9]*$',
                        message: 'Partner Number is not valid',
                    },
            }
        },
        onSubmit: () => {
            submitForm(data)
        }
    });

    const removeSelectedFile = () => {
        const data = [{
            key: 'url', targetValue: null
        }, {
            key: 'thumbnailUrl', targetValue: ''
        }]
        handleMultipleChange(data);
    }

    const submitForm = async (data) => {
        if(data.is_current_partner === 'no'){
            data.partner_number=null;
        }
        let response;
        response = await coCreationObj.submitCoCreationDetails(data);
        if (response) {
            if(!data?.thumbnailUrl || !data.url){
                toast.success(SUCCESS_MESSAGE_COCREATION_FORM);
                history.push(`/`);
            }else{
            const formData = new FormData();
            const { url } = data;
            const { name } = url;
            formData.append('url', url);
            formData.append('name', name);
            formData.append('co_creation_id', response.id)
            try {
               await coCreationObj.coCreationFileUpload(formData);

            }
            catch (error) {
            }
            toast.success(SUCCESS_MESSAGE_COCREATION_FORM);
            history.push(`/`);
            }
        }
    }

    useEffect(() => {
        coCreationObj.getCoCreationTestingOptions().then(response => {
            if (response) {
                setCoCreationTestingOptions(response);
            }
        })
            .catch(err => {
                console.log(err);
            })
        coCreationObj.getCoCreationDeliverableOptions().then(response => {
            if (response) {
                setCoCreationDeliverableOptions(response);
            }
        })
            .catch(err => {
                console.log(err);
            })
    }, []);

    return (
        <>
            <Container component="main" maxWidth="xl">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h6" className={classes.heading}>
                        ISV Request Form
                    </Typography>
                    <form onSubmit={handleSubmit} className={classes.form} noValidate>
                        <Typography component="h1" variant="h6" className={classes.infoMessage}>
                            For ISVs looking to explore Co-Create lab services, please complete the information below. We will contact you within two business days.
                        </Typography>
                        <Grid item xs={12} className={classes.gridContainer}>

                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Your Name *</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    autoFocus
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    autoComplete="name"
                                    value={data.name || ''}
                                    onChange={handleChange('name')}
                                    error={errors?.name ? true : false}
                                    helperText={errors.name}
                                />
                            </Box>

                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Company *</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    autoComplete="company"
                                    value={data.company || ''}
                                    onChange={handleChange('company')}
                                    error={errors?.company ? true : false}
                                    helperText={errors.company}
                                />
                            </Box>

                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Email *</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    autoComplete="email"
                                    value={data.email || ''}
                                    onChange={handleChange('email')}
                                    error={errors?.email ? true : false}
                                    helperText={errors.email}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} className={classes.gridContainer}>

                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Alliance Manager</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    value={data.alliance_manager || ''}
                                    onChange={handleChange('alliance_manager')}
                                    error={errors?.alliance_manager ? true : false}
                                    helperText={errors.alliance_manager}
                                />
                            </Box>

                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Opportunity ID</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    value={data.opportunity_id || ''}
                                    onChange={handleChange('opportunity_id')}
                                    error={errors?.opportunity_id ? true : false}
                                    helperText={errors.opportunity_id}
                                />
                            </Box>

                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Deal Size in USD</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    type='number'
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    value={data.deal_size || ''}
                                    onChange={handleChange('deal_size')}
                                    error={errors?.deal_size ? true : false}
                                    helperText={errors.deal_size}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} className={classes.gridContainer}>

                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Vendor</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    value={data.vendor || ''}
                                    onChange={handleChange('vendor')}
                                    error={errors?.vendor ? true : false}
                                    helperText={errors.vendor}
                                />
                            </Box>

                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Contact</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    value={data.contact || ''}
                                    onChange={handleChange('contact')}
                                    error={errors?.contact ? true : false}
                                    helperText={errors.contact}
                                />
                            </Box>

                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Application to be tested</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    value={data.application_to_be_tested || ''}
                                    onChange={handleChange('application_to_be_tested')}
                                    error={errors?.application_to_be_tested ? true : false}
                                    helperText={errors.application_to_be_tested}
                                />
                            </Box>
                        </Grid>
                        
                        <Box className={classes.fieldContainer}>
                                <FormControl>
                                    <Typography className={classes.inputLabel}>Current Hitachi Vantara Partner? *</Typography>
                                    <Select
                                        labelId="demo-customized-select-label"
                                        className={classes.inputField}
                                        value={data.is_current_partner || ''}
                                        label="Current Hitachi Vantara Partner?"
                                        onChange={handleChange('is_current_partner')}
                                        input={<BootstrapInput />}
                                        >
                                        <MenuItem key={uuid()} value={'yes'}>Yes</MenuItem>
                                        <MenuItem key={uuid()} value={'no'}>No</MenuItem>
                                    </Select>
                                    {(errors['is_current_partner'] && errors['is_current_partner'] !== undefined) ? <p className={classes.errorLabel}>{errors['is_current_partner']}</p> : null}
                                </FormControl>
                            </Box>

                            {data.is_current_partner === 'yes' && <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Partner Number</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    value={data.partner_number || ''}
                                    onChange={handleChange('partner_number')}
                                    error={errors?.partner_number ? true : false}
                                    helperText={errors.partner_number}
                                />
                            </Box>}
                        


                        <Grid item xs={12}>

                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Expected Deliverable </Typography>
                                <Box marginBottom={2}>
                                    <Radio
                                        groupItems={createRadioGroups(coCreationDeliverableOptions)}
                                        checkedDefault={data.expected_deliverable}
                                        onChange={(selectedValue) => {
                                            handleChangeCustom('expected_deliverable', selectedValue)
                                        }}
                                    />
                                </Box>
                                {data.expected_deliverable === '3' &&
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        className={classes.inputField}
                                        style={{ marginBottom: 18 }}
                                        InputProps={{
                                            classes: {
                                                root: classes.root,
                                                focused: classes.focused,
                                                notchedOutline: classes.notchedOutline
                                            }
                                        }}
                                        value={data.other_expected_deliverable || ''}
                                        onChange={handleChange('other_expected_deliverable')}
                                        error={errors?.other_expected_deliverable ? true : false}
                                        helperText={errors.other_expected_deliverable}
                                    />
                                }
                            </Box>


                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>For Testing With</Typography>
                                <Box marginBottom={2} marginTop={1}>
                                    <MultiSelect
                                        name={'for_testing_with'}
                                        selected={data?.for_testing_with}
                                        onChange={handleChangeCustom}
                                        title={'labCategories'}
                                        data={coCreationTestingOptions}
                                        error={errors['for_testing_with']}
                                        isSearchHidden={true}
                                        handleBlur={() => { }}
                                    />
                                </Box>
                            </Box>


                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.inputLabel}>Business Case</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    className={classes.inputField}
                                    InputProps={{
                                        classes: {
                                            root: classes.root,
                                            focused: classes.focused,
                                            notchedOutline: classes.notchedOutline
                                        }
                                    }}
                                    value={data.business_case || ''}
                                    onChange={handleChange('business_case')}
                                    error={errors?.business_case ? true : false}
                                    helperText={errors.business_case}
                                />
                            </Box>

                            <Box className={classes.fieldContainer}>
                                <SingleFileUpload
                                    fieldValue={data?.url || ''}
                                    fieldError={errors?.url || ''}
                                    fieldName="url"
                                    fieldLabel="url"
                                    supportedFileTypes={['pdf', 'doc', 'docx', 'xlsx', 'xls', 'png', 'jpg', 'jpeg']}
                                    supportedFileMaxSize={10}
                                    moduleName='coCreation'
                                    onSuccessUpload={(file, fileName) => {
                                        const data = [{
                                            key: 'url', targetValue: file
                                        }, {
                                            key: 'thumbnailUrl', targetValue: fileName
                                        }]
                                        handleMultipleChange(data);

                                    }}
                                />
                                <Box display='flex'>
                                    <Typography className={classes.thumbnailUrl}>{data.thumbnailUrl}</Typography>
                                    {data.thumbnailUrl && <Clear className={classes.clearIcon} onClick={removeSelectedFile} />}
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} >
                            <Box className={classes.masterBtn} display="flex" justifyContent='right' marginTop={'8px'} marginBottom={'16px'}>
                                <Button className='back-btn' onClick={() => history.goBack()} >
                                    Back
                                </Button>
                                <Button className='add-btn' type="submit" >
                                    Submit
                                </Button>
                            </Box>
                        </Grid>

                    </form>
                </div>
            </Container>
        </>
    )
}

export default withRouter(CoCreationForm);