import { Method_Get_Videos_List,Method_Get_Videos_Category } from "constants/videos";
import NetworkService from "./NetworkService";

class VideoService {
    getVideosList = (urlParams) => {
        return NetworkService.get(`${Method_Get_Videos_List}?${urlParams}`).then((response) => response.data);
    }
    getVideosCategoryList = (urlParams) => {
        return NetworkService
            .get(`${Method_Get_Videos_Category}?${urlParams}`)
            .then((response) => response.data);
    }
}
export default VideoService;