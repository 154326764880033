import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import commonReducer from './commonReducer';
import adminSettingsReducer from './adminSettingsReducer';
import labReducer from './labReducer';
import themeReducer from './themeReducer';
import bannerReducer from './bannerReducer';

const rootReducer = combineReducers({
    loginReducer,
    commonReducer,
    adminSettingsReducer,
    labReducer,
    themeReducer,
    bannerReducer
    
});
export default rootReducer;