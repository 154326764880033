import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

function configureAppStore(preloadedState = {}) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => 
      process.env.NODE_ENV !== 'production'
        ? getDefaultMiddleware().concat(thunk, logger)
        : getDefaultMiddleware().concat(thunk),
    preloadedState,
  });

  return store;
}

const store = configureAppStore();
export default store;
