import { css } from '@emotion/css';
import { theme } from '@hitachivantara/uikit-react-core';


export const classes = {

    layoutContainer: css({
        display: 'block',
        height: '100%',
        position: 'absolute',
        width: '100%',
        background: '#F4F5F5 0% 0% no-repeat padding-box',
        " & .MuiDrawer-root": {
            " & .MuiPaper-root": {
                background: '#1D1D1D',
                " & .MuiList-padding": {
                    paddingTop: 24,
                }
            }
        },
    }),
    appBar: {
        // zIndex: theme.zIndices?.drawer + 1,
    },
    content: {
        // flexGrow: 1,
        // padding: 0,
        // transition: theme.transitions?.create('margin', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // })
    },
    contentShift: {
        // padding: 0,
        // transition: theme.transitions?.create('margin', {
        //     easing: theme.transitions.easing.easeOut,
        //     duration: theme.duration.enteringScreen,
        // }),
        // marginLeft: drawerWidth,
    },
    mainContainer: css({
        width: '100%',
        height: '100%',
        display: 'flex',
        opacity: '1',
        zIndex: '0',
        overflow: 'scroll',
        position: 'absolute',
        background: '#F4F5F5 0% 0% no-repeat padding-box',
        paddingTop: '70px',
        flexDirection: 'column'

    }),
    loginContainer: css({
        width: '100%',
        height: '100%',
        display: 'flex',
        opacity: '1',
        zIndex: '0',
        overflow: 'scroll',
        position: 'absolute',
        background: '#F4F5F5 0% 0% no-repeat padding-box',
        paddingTop: '70px',
        flexDirection: 'column',
        "&.HvContainer-root": {
            paddingLeft: 0,
            paddingRight: 0
        }

    }),
    scPortalContainer: css({
        padding: "0px !important"
    })
};
