import { METHOD_TESTING_WITH_OPTIONS, METHOD_EXPECTED_DELIVERABLE_OPTIONS, METHOD_CO_CREATION, METHOD_CO_CREATION_FILE_UPLOAD } from "constants/coCreation";
import NetworkService from "./NetworkService";

class CoCreationService {
    getCoCreationTestingOptions = () => {
        return NetworkService.get(`${METHOD_TESTING_WITH_OPTIONS}`).then((response) => response.data);
    }
    getCoCreationDeliverableOptions = () => {
        return NetworkService.get(`${METHOD_EXPECTED_DELIVERABLE_OPTIONS}`).then((response) => response.data);
    }
    submitCoCreationDetails = (payload) => {
        return NetworkService.post(METHOD_CO_CREATION, payload).then((response) => response.data);
    }
    coCreationFileUpload = async (payload) => {
        return NetworkService.post(METHOD_CO_CREATION_FILE_UPLOAD, payload).then((response) => response.data);
    }

}
export default CoCreationService;