import React from 'react';
import ROLES from 'constants/roles';
import { AdminSettings } from 'containers/admin';
import PrivateRoute from 'components/PrivateRoute';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from 'components/PageNotFound';
import MasterRoutes from './MasterRoutes';
const AdminRoutes = ({ match }) => (
    <Switch>
        <Route path={`${match.url}`} exact component={() => <PageNotFound title="Error : Invalid Admin Route" description="Please select a valid admin module" />} />
        <Route path={`${match.url}/masters`} component={MasterRoutes} />

        <PrivateRoute exact path={`${match.url}/settings`} requiredRoles={[ROLES.System_User]} component={AdminSettings} />

        <Route component={() => <PageNotFound title="Error : Invalid Admin Route" description="Please select a valid admin module" />} />
    </Switch>
);
export default AdminRoutes;