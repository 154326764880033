import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import CommonService from "services/CommonService";
import { MIN_TEXT_LENGTH } from "constants/common";
import { Box, Button, Dialog, DialogActions, Icon, DialogTitle, DialogContent, TextField } from "@material-ui/core";
import { useForm } from "hooks/useForm";
import { useStyles } from "utils/theme";
const commonServiceObj = new CommonService();
const EncryptionTool = (props) => {
    const classes = useStyles();
    const [showModal, setModalVisibility] = useState(false);
    const [encryptResult, setEncryptResult] = useState('');
    const {
        handleSubmit, // handles form submission
        handleChange, // handles input changes
        data, // access to the form data
        errors, // includes the errors to show
        resetForm
    } = useForm({ // the hook we are going to create
        initialValues: { // used to initialize the data
            plainText: '',
        },
        validations: { // all our validation rules go here
            plainText: {
                required: {
                    value: true,
                    message: 'Plain Text is required',
                },
                custom: {
                    isValid: (value) => value.length >= Number(MIN_TEXT_LENGTH),
                    message: `Plain Text should be atleast ${MIN_TEXT_LENGTH} characters.`,
                }
            }
        },
        onSubmit: () => {
            submitHandler(data);
        }
    });
    const textAreaRef = useRef(null);

    const handleModalClose = (e, reason) => { 
        if (reason !== 'backdropClick') {
            setModalVisibility(false);
            setEncryptResult('');
            resetForm();
        } 
    };
    const handleModalOpen = (e) => {
        setModalVisibility(true);
    };
    const submitHandler = async (data) => {
        commonServiceObj.encryptPlainText({ plain_text: data.plainText }).then((response) => {
            setEncryptResult(response.result);
        }).catch(e => {
            console.log(e)
        });

    }
    const copyHandler = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        toast.success('Encrypted Text copied to clipboard.');
        handleModalClose();
    }



    return (
        <Box className={classes.masterBtn} display="flex" flexDirection="row-reverse" >
            <Button
                onClick={handleModalOpen}
                startIcon={<Icon>key</Icon>}
            > Encryption Tool
            </Button>
            <Dialog maxWidth="md" fullWidth open={showModal} onClose={handleModalClose} aria-labelledby="form-dialog-title">

                <DialogTitle id="form-dialog-title">Encryption Tool</DialogTitle>
                <DialogContent>
                    {(encryptResult === '') ? (
                        <TextField
                            autoFocus
                            variant="outlined"
                            placehoder={"Plain Text here"}
                            InputProps={{
                                classes: {
                                    input: classes.inputField,
                                    root: classes.base,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: { color: '#2a2c2e' },
                            }}

                            name="plainText"
                            value={data.plainText || ''}
                            onChange={handleChange('plainText')}
                            autoComplete="false"
                            label="Plain Text"
                            fullWidth
                            error={(errors.plainText) ? true : false}
                            helperText={(errors.plainText) ? errors.plainText : null}
                        />) : (
                        <textarea readOnly style={{ width: '100%' }} rows="6" ref={textAreaRef}
                            defaultValue={encryptResult} />
                    )}
                </DialogContent>
                <DialogActions>
                    <Box className={classes.masterBtn}>
                    <Button className='back-btn' onClick={handleModalClose}>
                        Cancel
                    </Button>
                    {(encryptResult === '') ? (
                        <Button
                            onClick={handleSubmit}
                            className='add-btn'>
                            Encrypt this Text
                        </Button>) : (
                        <Button

                            onClick={copyHandler} color="primary">
                            Copy to Clipboard
                        </Button>
                    )}
                </Box>
            </DialogActions>
        </Dialog>
        </Box>
    );
}
export default EncryptionTool;