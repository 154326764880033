import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

const useStyles = makeStyles({
    iconBlock: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        background: '#bd0912',
    },
    icon: {
        width: '72px',
        height: '100%',
        color: '#fff'
    },
    content: {
        padding: '32px 24px 32px 24px',
        color: '#2a2c2e',
        fontWeight: 600,
    }
});

export default function Unauthorized() {
    const classes = useStyles();

    return (
        <Box component="div" display='inline' className={classes.container}>
            <div className={classes.iconBlock}>
                <Typography variant="overline" display="inline">
                    <SentimentVeryDissatisfiedIcon className={classes.icon} />
                </Typography>
            </div>
            <Typography className={classes.content} variant="body1" display="block">
                Your screen resolution is too low. Please adjust the resolution to a minimum of 768 pixels.
            </Typography>
        </Box>

    );
}
