import React from "react";

const DataGridSubTable = (props) => {
  return props.data && props.columns ? (
    <div className="table-responsive">
      <table className="table table-sub">
        <thead>
          <tr>
          <th style={{ width: "3%" }}>&nbsp;</th>
            {props.columns.map((col, index) => (
              <th key={index} style={{ width: col.width }}>{col.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((rowData, rowIndex) => (
            <tr key={rowIndex}>
              <td>&nbsp;</td>
              {props.columns.map((col, colIndex) => (
                <td key={colIndex}>{ (col.render)? col.render(rowData): rowData[col.field] }</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
};
export default DataGridSubTable;
