import React, { useState } from "react";
import DataGrid from "components/datagrid/DataGrid";
import _ from "lodash";
import ActiveSessionService from "services/admin/masters/ActiveSessionService";
import Breadcrumb from "components/Breadcrumb";
import { Box, Grid } from "@material-ui/core";
import { buildQueryParams } from "utils/common";
import RefreshIcon from '@material-ui/icons/Refresh';
import Moment from "react-moment";
import { useStyles } from "./style";
import { MSG_DELETE_SUCCESS, MSG_WARNING_DELETE } from "constants/admin/masters/activeSession";
import { toast } from "react-toastify";


const activeSessionServiceObj = new ActiveSessionService();
const List = (props) => {
    const classes = useStyles();
    const [refreshToken, setRefreshToken] = useState(Date.now());
    const [pageSize, setPageSize] = useState(20);
    const metaInfo = {
        filtering: true,
        columns: [
            { title: "Lab Name", field: "name" },
            { title: "User Email", field: "user_email" },
            { title: "User Name", field: "user_name" },
            {
                title: "Started On", field: "created_on", filtering: false, render: row => (
                    <Moment format="DD/MM/YYYY HH:mm A">
                        {row.created_on}
                    </Moment>)
            },
            { title: "Template Id", field: "template_id" },
            { title: "Environment Id", field: "enviroment_id" },
        ],
        actions: [(row) => decideAction(row, 'delete')],
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "Active Session" },
        ]
    };
    const handleRefresh = (e) => {
        setRefreshToken(Date.now());
    };

    const decideAction = (rowData, actionType) => {
        if (actionType === 'delete') {
            const params = { admin: true }
            const payload = { labId: rowData.id }
            return ({
                icon: 'delete',
                tooltip: "Delete Record",
                iconProps: { style: { fontSize: "20px", color: "#2a2c2e", } },
                onClick: (event, rowData) => { 
                    if (window.confirm(MSG_WARNING_DELETE.replace("ITEM_NAME", rowData.name))) {
                        activeSessionServiceObj.terminateLab(payload,buildQueryParams(params)).then((response) => {
                            toast.success(response.message || MSG_DELETE_SUCCESS);
                            handleRefresh();
                        });
                    }
                },
            });
        }
    }

    const handlePagingData = (query) => {
        return new Promise((resolve, reject) => {
            let params = { ordering: "-id" }; //default sorting - is used for decending
            if (!_.isEmpty(query.orderBy)) {
                params.ordering = (query.orderDirection === 'desc' ? '-' : '') + query.orderBy.field;
            }
            setPageSize(query.pageSize);
            if (!_.isEmpty(query.filters)) {
                query.filters.map(filter => params[filter.column.field] = filter.value);
            }
            params['limit'] = query.pageSize;
            params['offset'] = query.pageSize * query.page;
            params['admin'] = true
            activeSessionServiceObj.getAll(buildQueryParams(params)).then((response) => {
                resolve({
                    data: response.results,
                    page: query.page,
                    totalCount: response.count,
                });
            }).catch(error => {
                console.log(error)
            });
        });
    }
    return (
        <>
            <Grid container spacing={1} className={classes.container}>
                <Grid item xs={12}>
                    <Box display='flex'>
                        <Breadcrumb data={metaInfo.breadcrumData} />
                        <RefreshIcon className={classes.icon} onClick={handleRefresh} />
                    </Box>

                </Grid>
                <Box width='100%' marginTop='16px'>
                    <Box display='flex'>
                    </Box>
                    <Grid item xs={12}>
                        <div className={classes.contentGrid}>
                            <DataGrid
                                actions={metaInfo.actions}
                                columns={metaInfo.columns}
                                dataHandler={handlePagingData}
                                filtering={metaInfo.filtering}
                                pageSize={pageSize}
                                refreshToken={refreshToken}
                            ></DataGrid>
                        </div>
                    </Grid>
                </Box>
            </Grid>
        </>
    );
}
export default List;