import React, { Fragment, useState } from "react";
import { AppBar, Box, Grid, Tab, Tabs } from "@material-ui/core";
import { useStyles } from "./style.js";
import uuid from "react-uuid";
import Unauthorized from "components/Unauthorized";
import ReportService from "services/ReportService.js";
import { a11yProps } from "components/TabPanel.js";
import { reportsTabsName } from "constants/tabs.js";
import DataGrid from "components/datagrid/DataGrid.js";
import { buildQueryParams } from "utils/common.js";
import DateRangePickerComp from "components/dateRangePicker/index.js";
import moment from 'moment';
import Pagination from "components/pagination";
import { useEffect } from "react";

const reportsServiceObj = new ReportService();

export default function Report() {
  const classes = useStyles();
  const screenSize = window.screen.width;
  const [refreshToken, setRefreshToken] = useState(Date.now());
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [sessionLabsData, setSessionLabsData] = useState([]);
  const [usageLabsData, setUsageLabsData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [value, setValue] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [date, setDate] = useState({});
  
  const metaInfo = {
    filtering: false,
    labsBySessionColumns: [
      { title: "Name", field: "name", sorting: false, cellStyle: { maxWidth:'30%',width:'30%' } },
      { title: "Count", field: "sessions", sorting: false, cellStyle: { maxWidth:'20%',width:'20%' } },
      {
        title: "Description",
        field: "description",
        render: (row) =>
          row.description && row.description.length > 350 ? (
            renderHTML(row)
          ) : (
            <div
              className="description"
              // dangerouslySetInnerHTML={{ __html: row.description || "" }}
            />
          ),
        sorting: false,
        cellStyle: { maxWidth:'60%',width:'60%', wordBreak:'break-all' }
      },
    ],
    labsByUsageColumns: [
      { title: "Name", field: "name", sorting: false,cellStyle: { maxWidth:'30%',width:'30%' } },
      { title: "Usage(in mins)", field: "total_sessions_time", sorting: false,cellStyle: { maxWidth:'20%',width:'20%' } },
      {
        title: "Description",
        field: "description",
        render: (row) =>
          row.description && row.description.length > 350 ? (
            renderHTML(row)
          ) : (
            <div
              className="description"
              // dangerouslySetInnerHTML={{ __html: row.description || "" }}
            />
          ),
        sorting: false,
        cellStyle: { maxWidth:'60%',width:'60%', wordBreak:'break-all' }
      },
    ],
  };

  useEffect(() => {
    selectedTabIndex === 0 ? handleSessionReportPagingData() : handleUsageReportPagingData();
    // eslint-disable-next-line
  }, [pageSize, page, date, selectedTabIndex]);

  const handlePageSize = (pageSizeValue) => setPageSize(pageSizeValue);

  const handlePage = (page) => setPage(page);

  const ReadMore = ({ children }) => {
    // Keep this constant and function in this button in order for the button to work properly
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    const text = children;
    const outputString = isReadMore ? text.slice(0, 350) : text;
    return (
      <div className="text">
        <span
          className="description"
          // dangerouslySetInnerHTML={{ __html: outputString || "" }}
        />
        <span onClick={toggleReadMore} className={classes.readOrHide}>
          {isReadMore ? "...Read more" : " Read less"}
        </span>
      </div>
    );
  };

  const renderHTML = (row) => {
    const text = row.description;
    return (
      <p>
        {text.length > 50 ? (
          <ReadMore>{text}</ReadMore>
        ) : (
          <div
            className="description"
            // dangerouslySetInnerHTML={{ __html: row.description || "" }}
          />
        )}
      </p>
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSelectedTabIndex(newValue);
    setPage(1)
    setRefreshToken(Date.now());
  };

  const handleDateRangeChange = (data) => {
    setDate({
      from_date:data.startDate,
      to_date:data.endDate
    })
    setPage(1)
  };

  const handleSessionReportPagingData = () => {
    let params = {};
    params['limit'] = pageSize;
    params['offset'] = pageSize * (page - 1);
    if(date?.from_date){
      params['from_date'] = moment(date.from_date).format("YYYY-MM-DD");
      params['to_date']= moment(date.to_date).format("YYYY-MM-DD");
    }
    return new Promise((resolve, reject) => {
      reportsServiceObj
        .getLabsReportBySessionList(buildQueryParams(params))
        .then((response) => {
          if (response) {
            setSessionLabsData(response.results);
            setTotalCount(response.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const handleUsageReportPagingData = () => {
    let params = {};
    params['limit'] = pageSize;
    params['offset'] = pageSize * (page - 1);
    if(date?.from_date){
      params['from_date'] = moment(date.from_date).format("YYYY-MM-DD");
      params['to_date']= moment(date.to_date).format("YYYY-MM-DD");
    }
    return new Promise((resolve, reject) => {
      reportsServiceObj
        .getLabsReportByUsageList(buildQueryParams(params))
        .then((response) => {
          if (response) {
            setUsageLabsData(response.results);
            setTotalCount(response.count);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const renderReportsData = () => {
    if (selectedTabIndex === 0) {
      return (
        <Grid item xs={12}>
          <div className={classes.contentGrid}>
            <DataGrid
              columns={metaInfo.labsBySessionColumns}
              filtering={metaInfo.filtering}
              pageSize={pageSize}
              refreshToken={refreshToken}
              paging={false}
              data={sessionLabsData}
            ></DataGrid>
          </div>
        </Grid>
      );
    } else if (selectedTabIndex === 1) {
      return (
        <Grid item xs={12}>
          <div className={classes.contentGrid}>
            <DataGrid
              columns={metaInfo.labsByUsageColumns}
              // dataHandler={handleUsageReportPagingData}
              filtering={metaInfo.filtering}
              pageSize={pageSize}
              refreshToken={refreshToken}
              paging={false}
              data={usageLabsData}
            ></DataGrid>
          </div>
        </Grid>
      );
    }
  };

  return (
    <>
      {screenSize >= 768 ? (
        <div className="main-header">
          <Box component="div" className={classes.headerContainer}>
            <span className="main-label">REPORTS</span>
            <DateRangePickerComp
            handleDateRangeChange={handleDateRangeChange}/>
          </Box>
          <div className={classes.root}>
            <AppBar position="static" style={{zIndex:900}}>
              <Tabs
                value={value}
                onChange={handleChange}
                className={classes.tabs}
              >
                {reportsTabsName &&
                  reportsTabsName.length > 0 &&
                  reportsTabsName.map((tab) => {
                    return (
                      <Tab
                        key={uuid()}
                        label={tab.name}
                        {...a11yProps(tab.index)}
                      />
                    );
                  })}
              </Tabs>
            </AppBar>
            {renderReportsData()}

            <Box width="100%">
              <Pagination
                handlePageSize={handlePageSize}
                totalCount={totalCount}
                pageSize={pageSize}
                handlePageVal={handlePage}
                pageNumber={page}
              />
            </Box>
          </div>
        </div>
      ) : (
        <Unauthorized />
      )}
    </>
  );
}
