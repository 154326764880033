import qs from 'qs';
import _ from 'lodash';
import { DATE_FORMAT, GA_TAG_ID, LOGGED_IN_USER_INFO } from 'constants/common';
import moment from "moment";
import ROLES from "constants/roles";
export const encodeUri = (uri) => encodeURI(uri);
export const decodeUri = (uri) => decodeURI(uri);
export const buildQueryParams = (obj) => qs.stringify(obj, { encode: false });
export const parseQueryParams = (q) => qs.parse(q.slice(1));
export const jsonStringify = (obj) => JSON.stringify(obj);
export const parseJson = (json) => JSON.parse(decodeUri(json));
export const slugify = (keyword) => {
	//allow A-Z,a-z,0-9 only then Replace multi spaces with a single space then Replace space with a '-' symbol
	return keyword.replace(/[^A-Za-z0-9 ]/g, '').replace(/\s{2,}/g, ' ').replace(/\s/g, "-");
}
export const deSlugify = (keyword) => (!_.isEmpty(keyword)) ? keyword.replace(/-/g, " ") : "";


export const transformMenuDropdownData = (data = []) => {
	if (!_.isEmpty(data)) {
		const newData = [];
		data.map(row =>
			newData.push({
				title: row.menu_name,
				route: row.menu_url,
				hasSubmenu: (row?.nodes?.length > 0) ? true : false,
				childrens: transformMenuDropdownData(row?.nodes)
			})
		)
		return newData;
	} else {
		return data;
	}

}
export const baseUrl = () => {
	if (!window.location.origin) {
		return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
	} else {
		return window.location.origin;
	}
}

export const captureGAPageView = async (data = {}) => {
	window.gtag('event', 'page_view', {
		page_title: (data?.page_title || window.document.title),
		page_location: (data?.page_location || window.location.href),
		page_path: (data?.page_path || window.location.pathname)
	})
}
export const captureGADimension = async userData => {
	window.gtag('config', GA_TAG_ID, {
		'custom_map': { 'dimension1': 'flow' }
	});
	window.gtag('event', 'user_flow_dimension', { 'flow': (userData?.id > 0) ? 'Session' : 'Public' });
}

export const encodeBase64 = string => window.btoa(string);
export const decodeBase64 = string => window.atob(string);
export const isValidURL = string => {
	try {
		new URL(string);
	} catch (_) {
		return false;
	}
	return true;
}
export const formatDate = (dateString) => {
	if (dateString) {
		const stillUtc = moment.utc(dateString).toDate();
		return moment(stillUtc).local().format(DATE_FORMAT);
	} else {
		return dateString;
	}
};
export const convertToFormat = (dateString, format) => {
	if (dateString) {
		return moment(dateString).local().format(format);
	} else {
		return dateString;
	}
};
export const paginateArray = (array, page_size, page_number) => {
	//page number should be start from 0
	return array.slice(page_number * page_size, (page_number + 1) * page_size);
}


export const transformRoles = (data = {}) => {

	if (!_.isEmpty(data)) {
		const newData = [];
		Object.keys(data).map(row =>
			newData.push({
				id: data[row],
				name: row.replaceAll('_', ' ')
			})
		)
		return newData;
	} else {
		return data;
	}

}

export const checkSupportedFormat = (fileName, allSupporttedFormat) => {
	const array = fileName.split('.');
	const fileTye = array.length > 0 && array[array.length - 1].toLowerCase();
	return allSupporttedFormat.includes(fileTye);
};

export const checkUploadedFileSize = (fileName, requiredFileSize) => {
	if (fileName.size > (Number(requiredFileSize) * 1024 * 1024)) {
		return false
	}
	return true;
}

export const transformDataCustomSelect = data => {
	return data.map(row => ({
		value: row.id,
		label: row.name,
		isDisabled: row?.disabled || false,
	}));
}

export const getSelectedIdFromArray = (array, value) => {
	for (var index = 0; index < array.length; index++) {
		if (array[index].value === value) {
			return value;
		}
	}
}

export const debounce = (func, delay) => {
	let debounceTimer;
	return function() {
	  const context = this;
	  const args = arguments;
	  clearTimeout(debounceTimer);
	  debounceTimer = setTimeout(() => func.apply(context, args), delay);
	};
  };

  export const checkAdminPermissions = () =>{
	const requiredRoles = [ROLES.System_Admin, ROLES.Lab_Admin]
	let userInfo=sessionStorage.getItem(LOGGED_IN_USER_INFO)
	const userData = userInfo?JSON.parse(userInfo):{}
	const userRoles = userData?.roles?.map(role => role.id);
	if(userData?.id > 0){
		if (!_.isEmpty(_.intersection(userRoles, requiredRoles))) 
			return true
	}
	return false;
  }

  export const getAndSetTokenExpiry = (token)=>{
    const tokenExpirationTime = token.exp; // Convert to millisecond
	localStorage.setItem("tokenExpirationTime", tokenExpirationTime);
	return tokenExpirationTime;
  }

  export const getMidnightUTCString = () =>{
	 // Create a new Date object for the current date
	 let date = new Date();
    
	 // Set the time to 12:00 AM (UTC)
	 date.setUTCHours(0, 0, 0, 0);
 
	 // Get the components of the date
	 let year = date.getUTCFullYear();
	 let month = String(date.getUTCMonth() + 1).padStart(2, '0');
	 let day = String(date.getUTCDate()).padStart(2, '0');
	 let hours = String(date.getUTCHours()).padStart(2, '0');
	 let minutes = String(date.getUTCMinutes()).padStart(2, '0');
	 let seconds = String(date.getUTCSeconds()).padStart(2, '0');
 
	 // Format the date string
	 let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
 
	 return formattedDate;
  }