import React, { useEffect, useState } from 'react';
import { HvTypography, HvListItem, HvDialog, HvDialogTitle, HvDialogContent, HvButton, HvListContainer, HvDialogActions, HvBox } from '@hitachivantara/uikit-react-core';
import { Box } from '@mui/material';
import { withRouter } from 'react-router';
import { LOGGED_IN_USER_INFO } from 'constants/common';

const ISVRequestCardLayout = ({ history }) => {
    const [openInternalDialog, setOpenInternalDialog] = useState(false);
    const [openPartenerDialog, setOpenPartnerDialog] = useState(false)
    const internalLink = 'https://hdscorp.lightning.force.com/lightning/page/home'
    const partnerLink = 'https://sso.hitachivantara.com/content/login-page.html/'



    useEffect(() => {
        let data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
        let token = data ? JSON.parse(data) : {}
        const hasPartner = token?.groups?.map(item => item.name === "Partners");
        const isPartner = hasPartner?.includes(true);
        isPartner ? setOpenPartnerDialog(true) : setOpenPartnerDialog(false);

        const hasEmployees = token?.groups?.map(item => item.name === "Employees");
        const isEmployee = hasEmployees?.includes(true);
        isEmployee ? setOpenInternalDialog(true) : setOpenInternalDialog(false);
    }, [])

    return (
        <>
            {
                openInternalDialog ? <HvDialog
                    disableBackdropClick
                    fullscreen={true}
                    open={openInternalDialog}
                    onClose={() => {
                        setOpenInternalDialog(false);
                        history.push("/labs")
                    }}
                    aria-labelledby="hv-dialog-title"
                    aria-describedby="hv-dialog-description"
                    id="test"

                >
                    <HvDialogTitle id="hv-dialog-title" variant="default">
                        For Internal User
                    </HvDialogTitle>
                    <HvDialogContent indentContent>
                        <HvTypography variant="normalText" component="p">
                            <HvListContainer>
                                <HvListItem >•	For PreSales support, start from within an Opportunity or Quote and click the "Request GPSE Service" button.</HvListItem>
                                <HvListItem >•	For post sales support, start from a Customer, Partner, or Prospect Account and click the "Request GPSE Service" button.</HvListItem>
                                <HvListItem> •	You may need to click on the drop-down arrow first to see the option. </HvListItem>
                                <img src='./assets/images/Salesforce-GPSE1.png' style={{ width: "100%" }} alt="Internal"></img>
                                <img src='./assets/images/Salesforce-GPSE2.png' style={{ width: "100%" }} alt="Internal"></img>
                                <HvListItem> •	Select “Technology Partner Co-Creation Lab” and complete the required fields. </HvListItem>
                                <HvListItem> •	We will contact you within 48 hours with next steps. </HvListItem>
                                <Box display='flex' alignSelf='center' gap={1}>
                                    <HvListItem style={{ alignSelf: 'center' }}> •	For questions or assistance contact: </HvListItem>
                                    <HvTypography style={{ cursor: 'pointer', alignSelf: 'auto', paddingTop: '5px' }} variant='link'>gpse.isv@hitachivantara.com</HvTypography>
                                </Box>
                            </HvListContainer>
                        </HvTypography>
                    </HvDialogContent>
                    <HvDialogActions>
                        <HvButton onClick={() => { window.open(internalLink) }}>Request For Internal</HvButton>
                    </HvDialogActions>
                </HvDialog> : <></>
            }
            {
                openPartenerDialog ? <HvDialog
                    disableBackdropClick
                    fullscreen={true}
                    open={openPartenerDialog}
                    onClose={() => {
                        setOpenPartnerDialog(false)
                        history.push("/labs")
                    }}
                    aria-labelledby="hv-dialog-title"
                    aria-describedby="hv-dialog-description"
                    id="test"
                >
                    <HvDialogTitle id="hv-dialog-title" variant="default">
                        For Partner
                    </HvDialogTitle>
                    <HvDialogContent indentContent>
                            <HvListContainer>
                               <HvBox component='div' style={{display:"flex", justifyContent:"flex-start", alignItems:"baseline"}}> <HvListItem >•	Start at SSO </HvListItem> <HvTypography  onClick={()=>{window.open("https://sso.hitachivantara.com/content/login-page.html")}} variant='link'>https://sso.hitachivantara.com/content/login-page.html</HvTypography></HvBox>  
                                <HvListItem> •	Log in if you have an account.</HvListItem>
                                <HvListItem> •	If you do not have an account yet, click “register here” and complete the form for registration.</HvListItem>
                                <HvBox><img src='./assets/images/Partner_SSO.png'width='auto' alt="Partner"></img></HvBox>
                                <HvListItem> •	Log in with your new username and password on sso. It will automatically go to the partner portal.. </HvListItem>
                                <HvListItem> •	Navigate to the “Technical” tab and select “Request GPSE Service”.</HvListItem>
                                <HvBox><img src='./assets/images/Partner_GPSE.png' width='auto'  alt="Partner"></img></HvBox>
                            </HvListContainer>
                    </HvDialogContent>
                    <HvDialogActions style={{ backgroundColor:"white"}}>
                        <HvButton onClick={() => { window.open(partnerLink) }}>Request For Partner</HvButton>
                    </HvDialogActions>
                </HvDialog> : <></>
            }
        </>



    );
};



export default withRouter(ISVRequestCardLayout);