import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from 'react-redux';
import LabService from "services/LabService";
import { showLoader } from "redux/actions/commonActions";
import './style.scss';
import './styles.css'
import { SplitView } from "./SplitView";
import PropTypes from 'prop-types';
import { showBanner } from "redux/actions/bannerActions";
import { useHistory } from "react-router";
import _ from "lodash";
import { buildQueryParams } from "utils/common";
import { LAB_AS_A_SERVICE, ON_DEMAND_LAB, PREP_LAB_ONDEMAND } from "constants/labs";
import { labInfo } from "redux/actions/labActions";
import { useSelector } from "react-redux";
import uuid from "react-uuid";
const labServiceObj = new LabService();

const ScPortal = (props) => {
  const [showTopStrip, settopStrip] = useState(false);
  const { location } = props.history;
  const state = location && location?.state;
  const [showInstructionComp, setInstructionComp] = useState(false);
  let { url, name, id, instruction, video_instruction } = !_.isNull(props.data) && !_.isUndefined(props?.data) ? props.data : !_.isNull(state) ? state?.labDetail : "";
  const [labDetail] = useState(!_.isNull(props.data) && !_.isUndefined(props?.data) ? props.data : !_.isNull(state) ? state?.labDetail : "")
  const [extendedLabTime, setExtendedLabTime] = useState(0)
  const [labUrl, setLabUrl] = useState(url);
  const [scPortalKey, setScPortalKey] = useState(uuid());
  const [allowHardWareExtenstion, setAllowHardWareExtension] = useState(true);
  const [showHarWareBanner, setShowHardWareBanner] = useState(false);
  let labInfoObj = useSelector(state => state.labReducer.labInfo);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLoader(true));
  }, [dispatch]);



  const hideSideCatalog = () => {
    settopStrip(true);
    setInstructionComp(false)
  }

  const handleInstructions = () => {
    setInstructionComp(!showInstructionComp)
  }

  const terminateLab = () => {
    labServiceObj.terminateLab(labDetail).then((response) => {
      if (_.isEmpty(response)) {
        history.push("/labs");
        localStorage.clear("stopAllowedExtension")
      }
    }
    ).catch((error) => {
      if (error) {
        history.push("/labs")
        dispatch(showBanner({
          payload: {
            showBanner: true,
            label: { message: "Error Occured while terminating Lab." },
            variant: "error"
          }
        }))
      }

    });
  }
  const disconnectLab = () => {
    labServiceObj.disconnectLab(labDetail, "runstate=pause").then((response) => {
      if (!_.isEmpty(response)) {
        history.push("/labs")
      }
    })
      .catch((error) => {
        if (error) {
          if (error?.detail === 'Not found.') {
            history.push("/labs")
          }
          else {
            history.push("/labs")
            dispatch(showBanner({
              payload: {
                showBanner: true,
                label: { message: "Error Occured while disconnecting Lab." },
                variant: "error"
              }
            }))
          }
        }

      });
  }

  const extendLabTime = (allowExtend) => {
    let params = {}
    params["session_id"] = labDetail?.id;
    if (labDetail?.lab_type === LAB_AS_A_SERVICE) {
      params["lab"] = labDetail?.labId;
      params["timezone"] = encodeURIComponent(Intl.DateTimeFormat().resolvedOptions().timeZone);
      params["pre_extend"] = true
    }
    if (labDetail?.lab_type === LAB_AS_A_SERVICE) {
      if (allowExtend) {
        delete params.pre_extend;
        labServiceObj.extendHardwareLab(buildQueryParams(params)).then((response) => {
          if (!_.isEmpty(response)) {
            setScPortalKey(uuid());
            setLabUrl(response?.data?.url);
            setAllowHardWareExtension(false);
            setShowHardWareBanner(false);
            localStorage.setItem("stopAllowedExtension", true)
            dispatch(showBanner({
              payload: {
                showBanner: true,
                label: { message: response?.data?.Message },
                variant: "success"
              }
            }))
          }
        }).catch((error) => {
          setShowHardWareBanner(false)
          setAllowHardWareExtension(false)
          if (error) {
            console.log(error);
            dispatch(showBanner({
              payload: {
                showBanner: false,
                label: { message: error?.response?.data?.message },
                variant: "error",
              },
            }))
          }
        });
      } else {
        labServiceObj.extendHardwareLab(buildQueryParams(params)).then((response) => {
          if (!_.isEmpty(response) && response?.data?.pre_extended_status === true) {
            setShowHardWareBanner(true);
            setScPortalKey(uuid());
            setAllowHardWareExtension(false);
          } else {
            setShowHardWareBanner(false)
            setAllowHardWareExtension(false)
          }
        }).catch((error) => {
          setShowHardWareBanner(false)
          setAllowHardWareExtension(false);
          dispatch(showBanner({
            payload: {
              showBanner: false,
              label: { message: error?.response?.data?.message },
              variant: "error",
            },
          }))
        });
      }

    } else {
      if (labDetail?.lab_type == ON_DEMAND_LAB || labDetail?.lab_type == PREP_LAB_ONDEMAND) {
        labServiceObj.extendLab(buildQueryParams(params)).then((response) => {
          if (!_.isEmpty(response)) {
            url = response?.data?.url;
            setLabUrl(url);
            setExtendedLabTime(response?.data?.extended_time);
            setScPortalKey(uuid());
            dispatch(labInfo({ ...labInfoObj, max_time: response?.data?.extended_time, extended_count: response?.data?.extended_count, url_end_time: response?.data?.url_end_time, session_end_time :  response?.data?.session_end_time}));
            dispatch(showBanner({
              payload: {
                showBanner: true,
                label: { message: response?.data?.Message },
                variant: "success"
              }
            }))
          }
        }
        ).catch((error) => {
          if (error) {
            dispatch(showBanner({
              payload: {
                showBanner: true,
                label: { message: error?.message },
                variant: "error"
              }
            }))
          }

        });
      }
    }

  }

  const splitViewScreen = useMemo(()=> <SplitView
  showTopStrip={showTopStrip}
  handleInstructions={handleInstructions}
  hideSideCatalog={hideSideCatalog}
  showInstructionComp={showInstructionComp}
  disconnectLab={disconnectLab}
  terminateLab={terminateLab}
  labName={name ? name : labDetail?.labName}
  instruction={instruction}
  url={labUrl?.replace("/v2/vms/", "/vms/")}
  videoInstruction={video_instruction}
  labId={labDetail?.labId}
  extendLabTime={extendLabTime}
  extendedLabTime={extendedLabTime}
  scPortalKey={scPortalKey}
  allowHardWareExtenstion={allowHardWareExtenstion}
  showHarWareBanner={showHarWareBanner}
  session_id={labDetail?.id}
/>,[scPortalKey])

  return (
    <div>
      {splitViewScreen}
    </div>
  )
}

ScPortal.propTypes = {
  data: PropTypes.any,
  history: PropTypes.any
}
export default ScPortal