import React, { Fragment, useState, useEffect } from 'react';
import LinkComp from 'components/link';
import Pagination from 'components/pagination';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { CardBlock, PlayIcon } from 'components/card/style';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles, BootstrapInput } from './style.js';
import uuid from 'react-uuid';
import Unauthorized from 'components/Unauthorized';
import { buildQueryParams, transformDataCustomSelect } from 'utils/common.js';
import VideoService from '../../services/VideoService.js'
import { MAX_CATEGORY_LIMIT } from 'constants/common.js';

const videoServiceObj = new VideoService();

export default function Videos() {
    // const classes = useStyles();
    // const screenSize = window.screen.width;
    // const [videosData, setVideosData] = useState([]);
    // const [videosCategory, setVideosCategory] = useState([]);
    // const [eVideoType, setEVideoType] = useState(0);
    // const [totalVideosCount, setTotalVideosCount] = useState();
    // const [pageSize, setPagesize] = useState(10);
    // const [page, setPage] = useState(1);

    // useEffect(() => {
    //     let urlParams = {};
    //     urlParams['limit'] = pageSize;
    //     urlParams['offset'] = pageSize * (page - 1);
    //     urlParams['is_deleted'] = false;
    //     if (eVideoType !== 0) {
    //         urlParams['categories'] = videosCategory.filter(el => el.value === eVideoType)[0].label;
    //     }
    //     videoServiceObj.getVideosList(buildQueryParams(urlParams)).then(response => {
    //         if (response) {
    //             setVideosData(response.results);
    //             setTotalVideosCount(response.count);
    //         }
    //     })
    //         .catch(error => {
    //             console.log(error);
    //         })
    //         // eslint-disable-next-line
    // }, [eVideoType, pageSize, page]);

    // useEffect(() => {
    //     let urlParams = {
    //         is_deleted: false,
    //         limit: MAX_CATEGORY_LIMIT
    //     };
    //     let videosCategory = [{ label: 'All Videos', value: 0 }];
    //     videoServiceObj.getVideosCategoryList(buildQueryParams(urlParams)).then(response => {
    //         if (response) {
    //             setVideosCategory([...videosCategory, ...transformDataCustomSelect(response.results)]);
    //         }
    //     })
    //         .catch(error => {
    //             console.log(error);
    //         })
    //         // eslint-disable-next-line
    // }, []);

    // const playVideoInNewTab = (url) => window.open(url, url).focus();

    // const handleDropdown = (event) => {
    //     setEVideoType(event.target.value);
    // }

    // const handlePageSize = (pageSizeValue) => setPagesize(pageSizeValue)

    // const handlePage = (page) => setPage(page)

    // const ReadMore = ({ children }) => {
    //     const text = children;
    //     const [isReadMore, setIsReadMore] = useState(true);
    //     const toggleReadMore = () => {
    //         setIsReadMore(!isReadMore);
    //     };
    //     const outputString = isReadMore ? text.slice(0, 350) : text
    //     return (
    //         <div className="text">
    //             <span className='description' dangerouslySetInnerHTML={{ __html: outputString || '' }} />
    //             <span onClick={toggleReadMore} className={classes.readOrHide}>
    //                 {isReadMore ? "...Read more" : "Read less"}
    //             </span>
    //         </div>
    //     );
    // };

    // const getVideoCategories = (data) => {
    //     const { categories } = data;
    //     let videoCategories = [];
    //     categories && categories.length > 0 && categories.forEach(cat => {
    //         videoCategories.push(cat.name)
    //     })
    //     return videoCategories;
    // }
    // return (
    //     <>
    //         {screenSize >= 768 ? <div className='main-header'>
    //             <Box component='div' className={classes.headerContainer}>
    //                 <span className='main-label'>VIDEOS </span>
    //                 <FormControl className={classes.margin}>
    //                     <Select
    //                         labelId="demo-customized-select-label"
    //                         value={eVideoType}
    //                         className={classes.select}
    //                         onChange={handleDropdown}
    //                         input={<BootstrapInput />}
    //                     >
    //                         {videosCategory && videosCategory.length > 0 && videosCategory.map((option, index) => {
    //                             return (
    //                                 <MenuItem key={uuid()} className={classes.menuItem} value={option.value}>{option.label}</MenuItem>
    //                             );
    //                         })}
    //                     </Select>
    //                 </FormControl>
    //             </Box>
    //             <div className={classes.root}>
    //                 {videosData && videosData.length > 0 && videosData.map(data => {
    //                     return (
    //                         <Fragment key={uuid()}>
    //                             <Box component='div' display-='block' className={classes.container}>
    //                                 <CardBlock
    //                                     sourceImage={data.thumbnail && data.thumbnail.thumbnail ? data.thumbnail.thumbnail : './assets/images/no_image.png'}
    //                                     width='20%'
    //                                     backgroundSize={data.thumbnail && data.thumbnail.thumbnail ? 'cover' : 'contain'}>
    //                                     <PlayIcon>
    //                                         <img src='./assets/images/play-icon.png' alt='play-icon' onClick={() => playVideoInNewTab(data.video_url)} />
    //                                     </PlayIcon>
    //                                 </CardBlock>

    //                                 <Box component='div' className={classes.descriptionBlock}>
    //                                     <Typography variant="h6" className={classes.custom}>
    //                                         {data.name}
    //                                     </Typography>

    //                                     {data.description && data.description.length > 350 ? <ReadMore>
    //                                         {data.description}
    //                                     </ReadMore> :
    //                                         <div className='description' dangerouslySetInnerHTML={{ __html: data.description || '' }} />
    //                                     }

    //                                 </Box>
    //                                 <Box component="div" display='flex' justifyContent='center' flexDirection='column'>
    //                                     <Box component="div" display='inline' className={classes.linkcontainer}>
    //                                         <LinkComp data={[`${data.duration} minutes`]} icon='./assets/images/duration.png' />
    //                                         <LinkComp data={getVideoCategories(data)} icon='./assets/images/categories.png' />
    //                                     </Box>
    //                                 </Box>
    //                             </Box>
    //                         </Fragment>
    //                     )
    //                 })}
    //                 <Pagination
    //                     handlePageSize={handlePageSize}
    //                     totalCount={totalVideosCount}
    //                     pageSize={pageSize}
    //                     handlePageVal={handlePage}
    //                     pageNumber={page}
    //                 />
    //             </div>
    //         </div>
    //             :
    //             <Unauthorized />
    //         }
    //     </>
    // )
}