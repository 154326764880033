import React from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import _ from "lodash";
import './style.scss';
/*
 * Custom toolbar component including showRaw button
 */
const CustomToolbar = ({ onClickSource }) => (
    <div className="toolbar">
        <button className="source-btn" onClick={onClickSource}>Source</button>
    </div>
)

/*
 * Editor component with custom toolbar and content containers
 */
class RichTextBox extends React.Component {
    constructor(props) {
        super(props)
        this.state = { editorHtml: this.props.content, showRaw: false, handleChangeTriggred: false, autoFocus:this.props.autoFocus }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeSource = this.handleChangeSource.bind(this)
        this.handleClickShowSource = this.handleClickShowSource.bind(this)
        this.editorRef = React.createRef();
    }

    componentDidMount(){
        if (this.editorRef.current && this.state.autoFocus) {
           this.editorRef.current.focus();
          }
    }
    static getDerivedStateFromProps(props, state) {
        if (props.content !== state.editorHtml && !state.handleChangeTriggred) {
            return {
                editorHtml: props.content,
            }
        }
    }
    handleEditorBlur = () => {
        // Check if the RichTextBox is focused before blurring
        if (this.editorRef.current ) {
            this.setState({
                autoFocus:false
            })
        }
      };


    handleChange(html) {
        const { property } = this.props;
        this.setState({ editorHtml: html, handleChangeTriggred: true, rawHtml: html })
        this.props.onChange(property, html);
   
    }
    
      

    handleEditorFocus = () => {
        if (this.editorRef.current) {
          const quillEditor = this.editorRef.current.getEditor();
          const length = quillEditor.getLength();
          quillEditor.setSelection(length, length);
        }
      };

    handleChangeSource(html) {
        const { property } = this.props;
        this.setState({ rawHtml: html, editorHtml: html, handleChangeTriggred: true })
        this.props.onChange(property, html);
     
    }

    handleClickShowSource(event) {
        event.preventDefault();
        const isEditingRaw = this.state.showRaw;
        this.setState({ showRaw: !isEditingRaw, })
        this.syncViews(isEditingRaw)
    }

    syncViews(fromRaw) {
        if (fromRaw) this.setState({ editorHtml: this.state.rawHtml })
        else this.setState({ rawHtml: this.state.editorHtml })
    }

    componentDidUpdate(prevProps) {
        // Check if content has changed and focus the editor
        if (this.props.content !== prevProps.content && this.editorRef.current) {
         this.setState({ rawHtml: this.props.content, editorHtml: this.props.content, handleChangeTriggred: true })  
          this.editorRef.current.focus();
          this.setState({
            autoFocus:true
        })
        }
      }
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.rawHtml !== this.state.rawHtml) {
    //       this.setState({editorHtml: this.state.rawHtml})
    //     }
    //   }

    render() {
        const { showRaw } = this.state;
        return (
            <div className={showRaw ? "showRaw" : ""}>
                <div className={"text-editor"}>
                    <ReactQuill
                        ref={this.editorRef}
                        onChange={this.handleChange}
                        modules={RichTextBox.modules}
                        formats={RichTextBox.formats}
                        value={this.state.editorHtml}
                        theme={"snow"}// pass false to use minimal theme
                        onFocus={this.handleEditorFocus}
                        onBlur={this.handleEditorBlur}
                        preserveWhitespace={true}
                    >
                    </ReactQuill>
                    <CustomToolbar onClickSource={(event) => this.handleClickShowSource(event)} />

                    {showRaw &&
                        <textarea
                            className={"raw-editor"}
                            onChange={(e) => this.handleChangeSource(e.target.value)}
                            value={this.state.rawHtml}
                        />
                    }
                </div>
            </div>
        )
    }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RichTextBox.modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", 'video']
    ],
    clipboard: {
        matchVisual: false
    }
}

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
RichTextBox.formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'video',
]

export default RichTextBox;