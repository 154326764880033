import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DataGrid from "components/datagrid/DataGrid";
import _ from "lodash";
import VideoCatalogService from "services/admin/masters/VideoCatalogService";
import { MSG_WARNING_DELETE, MSG_DELETE_SUCCESS, MSG_WARNING_UNDO_DELETE, MSG_UNDO_RECORD } from "constants/admin/masters/videoCatalog";
import Breadcrumb from "components/Breadcrumb";
import { Box, Button, Grid } from "@material-ui/core";
import { useStyles } from "utils/theme";
import Toggle from "components/Toggle";
import { buildQueryParams } from "utils/common";
import Moment from "react-moment";

const videoCatalogServiceObj = new VideoCatalogService();
const List = (props) => {
    const classes = useStyles();
    const { url: masterUrl } = props.match;
    const [refreshToken, setRefreshToken] = useState(Date.now());
    const [pageSize, setPageSize] = useState(20);
    const [customFilter, setFilters] = useState({ "is_deleted": false });
    const metaInfo = {
        filtering: true,
        columns: [
            { title: "Name", field: "name" },
            { title: "Categories", field: "categories", render: row => row?.categories?.map(c => c.name).join(', ') },
            {
                title: "Published On", field: "created_on", filtering:false, render: row => (
                    <Moment format="DD/MM/YYYY HH:mm A">
                        {row.created_date}
                    </Moment>)
            }
        ],
        actions: [(row) => decideAction(row, 'undo'), (row) => decideAction(row, 'edit'), (row) => decideAction(row, 'delete')],
        breadcrumData: [
            { title: "Home", link: "/" },
            { title: "Video Catalog List" },
        ]
    };
    const handleRefresh = (e) => {
        setRefreshToken(Date.now());
    };

    const decideAction = (rowData, actionType) => {

        if (actionType === 'undo') {
            return (rowData.is_deleted === true) ? (
                {
                    icon: 'undo',
                    iconProps: { style: { fontSize: "20px", color: "#2a2c2e" } },
                    tooltip: "Undo Deleted Record",
                    onClick: (event, rowData) => {
                        if (window.confirm(MSG_WARNING_UNDO_DELETE.replace("ITEM_NAME", rowData.name))) {
                            videoCatalogServiceObj.undoDelete(rowData.id).then((response) => {
                                toast.success(response.message || MSG_UNDO_RECORD);
                                handleRefresh();
                            });
                        }

                    },
                }

            ) : null;
        }
        else if (actionType === 'edit') {
            return (rowData.is_deleted === false) ? (
                {
                    icon: 'edit',
                    tooltip: "Edit Record",
                    iconProps: { style: { fontSize: "20px", color: "#2a2c2e" } },
                    onClick: (event, rowData) => {
                        props.history.push(`${masterUrl}/edit/${rowData.id}`);
                    },
                }
            ) : null;
        }
        else if (actionType === 'delete') {
            return (rowData.is_deleted === false) ? ({
                icon: 'delete',
                tooltip: "Delete Record",
                iconProps: { style: { fontSize: "20px", color: "#2a2c2e", } },
                onClick: (event, rowData) => {
                    if (window.confirm(MSG_WARNING_DELETE.replace("ITEM_NAME", rowData.name))) {
                        videoCatalogServiceObj.delete(rowData.id).then((response) => {
                            toast.success(response.message || MSG_DELETE_SUCCESS);
                            handleRefresh();
                        });
                    }
                },
            }) : null;
        }
    }

    const toggleChangehandler = (e) => {
        e === '' ? setFilters(null) : setFilters({ "is_deleted": e })
        setRefreshToken(Date.now());
    }

    const handlePagingData = (query) => {
        return new Promise((resolve, reject) => {
            let params = { ordering: "-id" }; //default sorting - is used for decending
            if (!_.isEmpty(query.orderBy)) {
                params.ordering = (query.orderDirection === 'desc' ? '-' : '') + query.orderBy.field;
            }
            setPageSize(query.pageSize);
            if (!_.isEmpty(query.filters)) {
                query.filters.map(filter => params[filter.column.field] = filter.value);
            }
            if (customFilter) {
                params = { ...params, ...customFilter };
            }
            params['limit'] = query.pageSize;
            params['offset'] = query.pageSize * query.page;
            videoCatalogServiceObj.getAll(buildQueryParams(params)).then((response) => {
                resolve({
                    data: response.results,
                    page: query.page,
                    totalCount: response.count,
                });
            }).catch(error => {
                console.log(error)
            });
        });
    }

    return (
        <>
            <Grid container spacing={1} className={classes.outerContainer}>
                <Grid item xs={10}>
                    <Breadcrumb data={metaInfo.breadcrumData} />
                </Grid>

                <Grid style={{ padding: 0 }} item xs={2}>
                    <Box className={classes.masterBtn} display="flex" flexDirection="row-reverse" >
                        <Button component={Link} to={`${masterUrl}/add`}>
                            Add Video Catalog
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Toggle triggerHandler={toggleChangehandler} default={'false'} options={[{ name: 'Active', value: 'false' }, { name: 'Deleted', value: 'true' }, { name: 'All', value: '' }]} />
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.contentGrid}>
                        <DataGrid
                            actions={metaInfo.actions}
                            columns={metaInfo.columns}
                            dataHandler={handlePagingData}
                            filtering={metaInfo.filtering}
                            pageSize={pageSize}
                            refreshToken={refreshToken}
                        ></DataGrid>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
export default List;