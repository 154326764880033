import React from "react";
import { useSelector } from "react-redux";
import "./spinner.css"
import { HvLoading } from "@hitachivantara/uikit-react-core";
export default function Spinner({ children }) {
    const show = useSelector(state => state.commonReducer.showLoader);
    return show ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }} className="spinner"> 
            <HvLoading label="Loading" ></HvLoading>
        </div>
    ) : null;
}