export const METHOD_LIST = "faq-category/";
export const METHOD_FAQ = "faq/";

export const MSG_WARNING_DELETE_CATEGORY =
  "Are you sure to delete this FAQ category?";
export const MSG_WARNING_DELETE_QUESTION = "Are you sure to delete this FAQ?";
export const MSG_DELETE_CATEGORY_SUCCESS = "FAQ category deleted successfully";
export const MSG_DELETE_QUESTION_SUCCESS = "FAQ question deleted successfully";

export const MSG_ADD_SUCCESS = "FAQ question added successfully";
export const MSG_UPDATE_SUCCESS = "FAQ question updated successfully";

export const MSG_ADD_CATEGORY_SUCCESS = "FAQ category added successfully";
export const MSG_UPDATE_CATEGORY_SUCCESS = "FAQ category updated successfully";
