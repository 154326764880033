import _ from 'lodash';

export const getMasterBaseUrl = (url, params, backStep = 1) => {
  const items = url.split("/");
  const reduceFromEnd = backStep + Object.keys(params).length;
  return items.slice(0, items.length - reduceFromEnd).join("/");
}
export const checkPermissions = (item, userRoles, userGroups) => {
  if (_.isEmpty(item?.requiredRoles) && _.isEmpty(item?.requiredGroups)) {
    return true; //show the menu if no group and role defined for that
  } else {
    let enableFlag = 0;
    if (!_.isEmpty(item?.requiredRoles) && !_.isEmpty(_.intersection(userRoles, item.requiredRoles))) {
      enableFlag++;
    }

    if (!_.isEmpty(item?.requiredGroups) && !_.isEmpty(_.intersection(userGroups, item.requiredGroups))) {
      enableFlag++;
    }

    if (enableFlag > 0) {
      return true;//show the menu if user is having either required role or group
    } else {
      return false;
    }
  }
}