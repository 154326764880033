import history from 'router-history';
import StorageService from './StorageService';
const storageServiceObj = new StorageService();

class HistoryService {

  redirect = (path) => {
    history.push(path);
  }
  ssoReLogin = (currentPath) => {
    storageServiceObj.clearAllData();
    history.push({
      pathname: '/',  // email or sso 
      state: { from: { pathname: currentPath } }
    })
  }

  clearAllSearchData = () =>{
    history.push({
      pathname: '/labs',
      state: {
        searchedString: "",
      }
    })
  }
}
export default HistoryService;