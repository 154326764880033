import { MAX_TEXT_LENGTH, MIN_TEXT_LENGTH } from "constants/common";

export const METHOD_LIST = 'user/';
export const METHOD_CREATE = 'users/add/';
export const METHOD_UPDATE = 'users/{USER_ID}/';
export const METHOD_DELETE = 'users/{USER_ID}/';
export const METHOD_DETAIL = 'users/{USER_ID}/';
export const METHOD_ALL_GROUPS = 'group/drop/';
export const METHOD_ALL_REPORTS = 'report/drop/';


//text box length
export const TEXT_MIN_LENGTH = MIN_TEXT_LENGTH;
export const TEXT_MAX_LENGTH = MAX_TEXT_LENGTH;
export const EMAIL_MIN_LENGTH = 6;

//language constants
export const MSG_WARNING_DELETE = 'Are you sure to delete this user?';
export const MSG_DELETE_SUCCESS = 'Deleted Successfully';
export const MSG_ADD_SUCCESS = 'Created Successfully';
export const MSG_UPDATE_SUCCESS = 'Updated Successfully';