//API Method Constants
//for backdoor login
export const METHOD_BACKDOOR_LOGIN = "backenduser/";
export const METHOD_lOGIN = "login/";
export const METHOD_USERINFO = "users/me/";
export const METHOD_REGISTER = "user/";
export const METHOD_FORGOT_PASSWORD = "user/forgot-password/";
export const METHOD_RESET_PASSWORD = "user/reset-password/";
export const METHOD_EMAIL_AUTHENTICATE = "user/verify/";
export const LANGUAGE_LOGIN_ERROR_LAB = "Login to Run LAB";

//reducer and action keys
export const USER_INFO = "USER_INFO";
export const LAB_INFO = "LAB_INFO";
export const TOKEN_EXPIRE_TIME = "TOKEN_EXPIRE_TIME";

//for sso login
export const METHOD_SSO_REDIRECT_URL = "signin/";
export const METHOD_SSO_VERIFY_TOKEN = "authenticate/";
export const METHOD_SSO_LOGOUT = "signout/";
export const METHOD_SAASLAB_VALIDATE = "https://clearsight.hitachivantara-dev.com/ap/is_session_valid/"
//language for sso
export const LANGUAGE_BAD_SSO_URL = "Invalid Sso url.";
export const LANGUAGE_GENERAL_SSO_ERROR = "Some error occured.";

//lab category names and descriptions for new login page
export const Method_Get_Lab_Categories_Info = "/home-category/";

// get total labs run till date
// for login page (landing page)
export const Method_Get_Total_Labs_Run_Till_Date = "/yoy-session/";

//contact us form for login page
//POST CALL: with payload
export const Method_Contact_Us_Form = "/contact-us/";
