import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
    root: {
        width: 250,
      },
      label: { paddingBottom: 6 },
      titlePadding: { marginTop: 0 },
      dropdownPlacement: {
        marginLeft: 10,
      },
      controllerGroup: {
        display: "flex",
        alignItems: "flex-end",
      },
      wrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
      },
      borderedDiv: {
        border: '1px solid black', // You can customize the border style and color
        padding: '10px',
        height:"100%",
        width:"100%" 
      },
      filterUserGroup:{
        "& .HvBaseDropdown-panelOpenedUp":{
          top:"70px"
        }
      },
      datePickerGeo:{
        "& .HvBaseDropdown-container":{
          inset : "0px auto 0px 0px" 
        }
      }  
}));

